import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {toast} from "react-toastify";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import {
	AccountNumberRequestDto,
	AccountNumberResponseDto, AccountPlanApi,
	buildEmptyAccountNumberRequest
} from "../api/account-plan.api";
import {UserCompany} from "../api/login.api";
import {Language} from "../type";
import { AccountPlanTitle } from "../const";

export interface AccountNumberStore {
	isFetching: boolean;
	isProcessing: boolean;
	showModal: boolean;

	accountNumbers: AccountNumberResponseDto[];
	activeAccountNumber: AccountNumberRequestDto;

	setNumberValue: (name: keyof AccountNumberRequestDto, value: any, lang?: Language) => void;

	fetchAccountNumbers: (companyId: string) => Promise<void>;
	createAccountNumber: (companyId: string) => Promise<void>;
	updateAccountNumber: (companyId: string) => Promise<void>;
	export2csv: (company: UserCompany) => Promise<void>;
}

export const useAccountNumberStore = create<AccountNumberStore>()(immer((set) => ({
	isFetching: false,
	isProcessing: false,
	showModal: false,

	accountNumbers: [],
	activeAccountNumber: buildEmptyAccountNumberRequest(),

	fetchAccountNumbers: async (companyId: string) => {
		set({ isFetching: true });
		const accountNumbers = await AccountPlanApi.getInstance().getAccountNumbers(companyId);
		set({	isFetching: false, accountNumbers });
	},

	setNumberValue: (name: keyof AccountNumberRequestDto, value: any, lang?: Language) => {	set (state => {
		if (name === 'title' && lang) {
			state.activeAccountNumber.title[lang] = value;
			return;
		}
		state.activeAccountNumber[name] = value;
	})},

	createAccountNumber: async (companyId: string) => {
		set({ isProcessing: true });
		const createdAccount = await AccountPlanApi.getInstance()
			.createAccountNumber(companyId, useAccountNumberStore.getState().activeAccountNumber);
		set({ isProcessing: false });

		if (createdAccount) {
			set(state => {
				state.accountNumbers.push(createdAccount);
				state.showModal = false;
				toast.success('Account number successfully created');
			});
		}
	},

	updateAccountNumber: async (companyId: string) => {
		set({ isProcessing: true });
		const updatedAccount = await AccountPlanApi.getInstance()
			.updateAccountNumber(companyId, useAccountNumberStore.getState().activeAccountNumber);
		set({ isProcessing: false });

		if (updatedAccount) {
			set(state => {
				const index = state.accountNumbers.findIndex(f => f.id === updatedAccount.id);
				state.accountNumbers[index] = updatedAccount;
				state.showModal = false;
				toast.success('Account number successfully updated');
			});
		}
	},

	export2csv: async (company: UserCompany) => {
		toast.info('Exporting account numbers');
		let accountNumbers = useAccountNumberStore.getState().accountNumbers;
		if (accountNumbers.length === 0) {
			accountNumbers = await AccountPlanApi.getInstance().getAccountNumbers(company.companyId);
		}

		const data: Record<any, any>[] = [];

  accountNumbers.forEach(item => {
    const section = item.accountGroup.accountSection;
    const group = item.accountGroup;
    const account = item;

    // Add section row if not already added
    if (!data.some(row => row[AccountPlanTitle.SECTION] === `${section.sectionNumber}` && row[AccountPlanTitle.GROUP] === '')) {
      data.push({
        [AccountPlanTitle.SECTION]: `${section.sectionNumber}`,
        [AccountPlanTitle.GROUP]: '',
        [AccountPlanTitle.ACCOUNT_NUMBER]: '',
				[AccountPlanTitle.ACCOUNT_CLASS]: '',
        [AccountPlanTitle.TITLE_EN]: section.title.en,
        [AccountPlanTitle.TITLE_DE]: section.title.de,
        [AccountPlanTitle.TITLE_IT]: section.title.it,
        [AccountPlanTitle.TITLE_FR]: section.title.fr,
        [AccountPlanTitle.TITLE_RU]: section.title.ru,
        [AccountPlanTitle.TITLE_UA]: section.title.ua,
        [AccountPlanTitle.CURRENCY]: '',
        [AccountPlanTitle.IS_VAT]: ''
      });
    }

    // Add group row if not already added
    if (!data.some(row => row[AccountPlanTitle.SECTION] === `${section.sectionNumber}` && row[AccountPlanTitle.GROUP] === `${group.groupNumber}` && row[AccountPlanTitle.ACCOUNT_NUMBER] === '' && row[AccountPlanTitle.ACCOUNT_CLASS] === '')) {
      data.push({
        [AccountPlanTitle.SECTION]: `${section.sectionNumber}`,
        [AccountPlanTitle.GROUP]: `${group.groupNumber}`,
        [AccountPlanTitle.ACCOUNT_NUMBER]: '',
				[AccountPlanTitle.ACCOUNT_CLASS]: '',
        [AccountPlanTitle.TITLE_EN]: group.title.en,
        [AccountPlanTitle.TITLE_DE]: group.title.de,
        [AccountPlanTitle.TITLE_IT]: group.title.it,
        [AccountPlanTitle.TITLE_FR]: group.title.fr,
        [AccountPlanTitle.TITLE_RU]: group.title.ru,
        [AccountPlanTitle.TITLE_UA]: group.title.ua,
        [AccountPlanTitle.CURRENCY]: '',
        [AccountPlanTitle.IS_VAT]: ''
      });
    }

    // Add account row
    data.push({
      [AccountPlanTitle.SECTION]: `${section.sectionNumber}`,
      [AccountPlanTitle.GROUP]: `${group.groupNumber}`,
      [AccountPlanTitle.ACCOUNT_NUMBER]: `${account.accountNumber}`,
			[AccountPlanTitle.ACCOUNT_CLASS]: `${account.accountClass}`,
      [AccountPlanTitle.TITLE_EN]: account.title.en,
      [AccountPlanTitle.TITLE_DE]: account.title.de,
      [AccountPlanTitle.TITLE_IT]: account.title.it,
      [AccountPlanTitle.TITLE_FR]: account.title.fr,
      [AccountPlanTitle.TITLE_RU]: account.title.ru,
      [AccountPlanTitle.TITLE_UA]: account.title.ua,
      [AccountPlanTitle.CURRENCY]: account.currency,
      [AccountPlanTitle.IS_VAT]: account.isVat
    });
  });

		const csv = Papa.unparse(data);
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		saveAs(blob, `${company.companyName}-account-numbers.csv`);
	}

})));



