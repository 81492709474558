import {FC} from "react";
import {Spinner} from "react-bootstrap";

interface LoaderProps {
	showLoader: boolean
}

export const AppLoader: FC<LoaderProps> = ({ showLoader }) => {
	return (
		<>
			{showLoader && (
				<div className='spinner-backdrop'>
					<div className='spinner'>
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</div>
				</div>
			)}
		</>
	);
}
