import {Table} from "react-bootstrap";
import React, {FC} from "react";
import {BalanceReportResponseDto, BalanceTotalResponseDto} from "../../../app/api/balance-report.api";
import {displayAmount, displayYear, subtractOneYear} from "../../../app/utils";
import {UserCompany} from "../../../app/api/login.api";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface BalanceReportTableProps {
	activeCompany: UserCompany,
	startDate: Date
	endDate: Date
	reportData: BalanceReportResponseDto[]
}

const BalanceReportTable: FC<BalanceReportTableProps> = ({ activeCompany, startDate, reportData }) => {

	const lang = useUserStore(state => state.userLanguage);
	const displayTotals = (totals: BalanceTotalResponseDto[], item: 'dr' | 'cr' | 'variance') => {
		return totals.map((total: BalanceTotalResponseDto) => (
			<>
				{total.currency === activeCompany.companyCurrency ? (
					<>
						{item === "dr" ?
							displayAmount(total.drTotal) :
								(item === "cr" ? displayAmount(total.crTotal) :
									displayAmount(total.variance))}
					</>
				) : (
					<span style={{ fontSize: 14 }}>
						{item === "dr" ?
							displayAmount(total.drTotal, total.currency) :
								(item === "cr" ? displayAmount(total.crTotal, total.currency) :
									displayAmount(total.variance, total.currency))}
					</span>
				)}
				<br/>
			</>
		));
	}

	return (
		<>
			<Table striped bordered hover className={"mt-3"}>
				{reportData.map((reportClass, index) => (
					<>
						<thead key={`${reportClass.accountClass}` + index}>
						<tr>
							<th colSpan={8} style={{fontSize: '1.2rem'}}>
								{reportClass.accountClass}
							</th>
						</tr>
						<tr>
							<th colSpan={2}></th>
							<th colSpan={2} style={{ textAlign: 'right' }}>
								{dict.assetLiabilityReportPage.table.currentPeriod[lang]} {displayYear(startDate)}
							</th>
							<th colSpan={2} style={{ textAlign: 'right' }}>
								{dict.assetLiabilityReportPage.table.previousPeriod[lang]} {displayYear(subtractOneYear(startDate))}
							</th>
							<th colSpan={2}></th>
						</tr>
						<tr>
							<th>{dict.common.accountNumber[lang]}</th>
							<th>{dict.assetLiabilityReportPage.table.title[lang]}</th>
							<th style={{ textAlign: 'right' }}>{dict.common.dr[lang]} ({displayYear(startDate)})</th>
							<th style={{ textAlign: 'right' }}>{dict.common.cr[lang]} ({displayYear(startDate)})</th>
							<th style={{ textAlign: 'right' }}>{dict.common.dr[lang]} ({displayYear(subtractOneYear(startDate))})</th>
							<th style={{ textAlign: 'right' }}>{dict.common.cr[lang]} ({displayYear(subtractOneYear(startDate))})</th>
							<th style={{ textAlign: 'right' }}>{dict.assetLiabilityReportPage.table.variance[lang]} ({displayYear(startDate)})</th>
							<th style={{ textAlign: 'right' }}>{dict.assetLiabilityReportPage.table.variance[lang]} ({displayYear(subtractOneYear(startDate))})</th>
						</tr>
						</thead>
						<tbody>
						{reportClass.sections.map((section, index) => (
							<>
								<tr key={`${section.sectionTitle}` + index}>
									<td>{section.sectionNumber}</td>
									<td colSpan={8}>{section.sectionTitle}</td>
								</tr>

								{section.groups.map((group, index) => (
									<>
										<tr key={`${group.groupTitle}` + index}>
											<td>{group.groupNumber}</td>
											<td colSpan={8}>{group.groupTitle}</td>
										</tr>

										{group.accounts.map((account, index) => (
											<>
												<tr key={`${account.accountTitle}` + index}>
													<td>{account.accountNumber}</td>
													<td>{account.accountTitle}</td>
													<td style={{ textAlign: 'right' }}>{displayTotals(account.currentPeriodTotals, 'dr')}</td>
													<td style={{ textAlign: 'right' }}>{displayTotals(account.currentPeriodTotals, 'cr')}</td>
													<td style={{ textAlign: 'right' }}>{displayTotals(account.previousPeriodTotals, 'dr')}</td>
													<td style={{ textAlign: 'right' }}>{displayTotals(account.previousPeriodTotals, 'cr')}</td>

													<td style={{ textAlign: 'right' }}>{displayTotals(account.currentPeriodTotals, 'variance')}</td>
													<td style={{ textAlign: 'right' }}>{displayTotals(account.previousPeriodTotals, 'variance')}</td>
												</tr>
											</>
										))}
										<tr className={"last-row"}>
											<td colSpan={2}><b>{dict.common.total[lang]}: {group.groupTitle}</b></td>
											<td style={{ textAlign: 'right' }}>{displayTotals(group.currentPeriodTotals, 'dr')}</td>
											<td style={{ textAlign: 'right' }}>{displayTotals(group.currentPeriodTotals, 'cr')}</td>
											<td style={{ textAlign: 'right' }}>{displayTotals(group.previousPeriodTotals, 'dr')}</td>
											<td style={{ textAlign: 'right' }}>{displayTotals(group.previousPeriodTotals, 'cr')}</td>

											<td style={{ textAlign: 'right' }}>{displayTotals(group.currentPeriodTotals, 'variance')}</td>
											<td style={{ textAlign: 'right' }}>{displayTotals(group.previousPeriodTotals, 'variance')}</td>
										</tr>
									</>
								))}
								<tr className={"last-row"}>
									<td colSpan={2}><b>{dict.common.total[lang]}: {section.sectionTitle}</b></td>
									<td style={{ textAlign: 'right' }}>{displayTotals(section.currentPeriodTotals, 'dr')}</td>
									<td style={{ textAlign: 'right' }}>{displayTotals(section.currentPeriodTotals, 'cr')}</td>
									<td style={{ textAlign: 'right' }}>{displayTotals(section.previousPeriodTotals, 'dr')}</td>
									<td style={{ textAlign: 'right' }}>{displayTotals(section.previousPeriodTotals, 'cr')}</td>

									<td style={{ textAlign: 'right' }}>{displayTotals(section.currentPeriodTotals, 'variance')}</td>
									<td style={{ textAlign: 'right' }}>{displayTotals(section.previousPeriodTotals, 'variance')}</td>
								</tr>
							</>
						))}
						<tr className={"last-row"}>
							<td colSpan={2}><b>{dict.common.total[lang]}: {reportClass.accountClass}</b></td>
							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.currentPeriodTotals, 'dr')}</td>
							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.currentPeriodTotals, 'cr')}</td>
							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.previousPeriodTotals, 'dr')}</td>
							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.previousPeriodTotals, 'cr')}</td>

							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.currentPeriodTotals, 'variance')}</td>
							<td style={{ textAlign: 'right' }}>{displayTotals(reportClass.previousPeriodTotals, 'variance')}</td>
						</tr>
						</tbody>
					</>
				))}
			</Table>
		</>
	)
}

export default BalanceReportTable;
