import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {AccountMovementResponseDto, AccountMovementsApi} from "../api/account-movements.api";
import {JournalEntryApi, JournalEntryResponseDto} from "../api/journal-entry.api";
import {useAccountNumberStore} from "./account-number.store";


export interface AccountMovementsStore {
	isFetching: boolean;
	fromAccountNumberId: string | null;
	toAccountNumberId: string | null;
	filteredAccountsIds: string[];
	setFilterFromToAccountNumbers: (fromAccountNumberId: string | null, toAccountNumberId: string | null) => void;

	accountMovements: AccountMovementResponseDto[]
	fetchAccountMovements: (companyId: string, startDate: Date, endDate: Date) => void;

	// Journal entry details
	selectedJournalEntryId: string | null;
	selectedJournalEntry: JournalEntryResponseDto | null;
	fetchJournalEntry: (journalEntryId: string) => void;
}

export const useAccountMovementsStore = create<AccountMovementsStore>()(immer((set) => ({
	isFetching: false,
	fromAccountNumberId: null,
	toAccountNumberId: null,
	filteredAccountsIds: [],

	accountMovements: [],
	selectedJournalEntryId: null,
	selectedJournalEntry: null,

	setFilterFromToAccountNumbers: (fromAccountNumberId: string | null, toAccountNumberId: string | null) => {
		set({ fromAccountNumberId, toAccountNumberId });

		if (!fromAccountNumberId) {
			set({ filteredAccountsIds: [], fromAccountNumberId: null, toAccountNumberId: null});
			return;
		}

		const accountNumbers = useAccountNumberStore.getState().accountNumbers;
		const fromAccount = accountNumbers.find(account => account.id === fromAccountNumberId);
		const toAccount = accountNumbers.find(account => account.id === toAccountNumberId);

		if (fromAccountNumberId && !toAccountNumberId) {
			set({
				filteredAccountsIds: accountNumbers
					.filter(account => account.accountNumber >= fromAccount!.accountNumber)
					.map(account => account.id)
			});

			return;
		}

		set({
			filteredAccountsIds: accountNumbers
				.filter(account => account.accountNumber >= fromAccount!.accountNumber && account.accountNumber <= toAccount!.accountNumber)
				.map(account => account.id)
		});

		return;
	},

	/**
	 * Fetch account movements
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 */
	fetchAccountMovements: async (companyId: string, startDate: Date, endDate: Date) => {
		set({ isFetching: true, accountMovements: []});
		const accountMovements = await AccountMovementsApi.getInstance().getList(companyId, startDate, endDate);

		set((state) => {
			state.accountMovements = accountMovements;
			state.isFetching = false;
		});
	},

	fetchJournalEntry: async (journalEntryId: string) => {
		set({ isFetching: true })
		const journalEntry = await JournalEntryApi.getInstance().findJournalEntryById(journalEntryId);
		set((state) => {
			state.selectedJournalEntry = journalEntry;
			state.isFetching = false;
		});
	}

})));
