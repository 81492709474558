import {Accordion, Card, Container, Form, FormControl, Table} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import {AddButton} from "../../components/AddButton";
import React, {useEffect, useState} from "react";
import {useUserStore} from "../../app/store/user.store";
import {AppLoader} from "../../components/AppLoader";
import {AccountGroupModal} from "./widget/AccountGroupModal";
import {AccountGroupResponseDto} from "../../app/api/account-plan.api";
import {AiOutlineFilter} from "react-icons/ai";
import {useAccountSectionStore} from "../../app/store/account-section.store";
import {useAccountGroupStore} from "../../app/store/account-group.store";
import {dict} from "../../app/dictionary";

const AccountGroupPage = () => {
	const [sectionNumberFilter, setSectionNumberFilter] = useState<string>('');
	const [groupNumberFilter, setGroupNumberFilter] = useState<string>('');

	const activeCompany = useUserStore(state => state.activeCompany);
	const accountSections = useAccountSectionStore(state => state.accountSections);

	const isLoading = useAccountGroupStore(state => state.isLoading);
	const showModal = useAccountGroupStore(state => state.showModal);
	const accountGroups = useAccountGroupStore(state => state.accountGroups);

	const lang = useUserStore(state => state.userLanguage);

	const getFilteredGroups = () => {
		let filteredGroups = accountGroups;

		if (sectionNumberFilter) {
			filteredGroups = filteredGroups.filter(f => f.accountSection.id.toString().includes(sectionNumberFilter));
		}

		if (groupNumberFilter) {
			filteredGroups = filteredGroups.filter(f => f.groupNumber.toString().includes(groupNumberFilter));
		}
		return filteredGroups;
	}

	useEffect(() => {
		if (activeCompany.companyId) {
			useAccountGroupStore.getState().fetchAccountGroups(activeCompany.companyId);
			useAccountSectionStore.getState().fetchAccountsSections(activeCompany.companyId);
		}
	}, [activeCompany.companyId]);

	const handleAddNewGroup = () => {
		useAccountGroupStore.setState({
			showModal: true,
			activeAccountGroup: {
				id: '',
				sectionId: '',
				groupNumber: 0,
				title: { en: '', de: '', it: '', fr: '', ru: '', ua: '', lt: '', lv: '', ee: '', pl: '', md: ''}
			}
		});
	}

	const handleActiveGroup = (selectedGroup: AccountGroupResponseDto) => {
		useAccountGroupStore.setState({
			showModal: true,
			activeAccountGroup: {
				id: selectedGroup.id,
				sectionId: selectedGroup.accountSection.id,
				groupNumber: selectedGroup.groupNumber,
				title: selectedGroup.title
			}
		});
	}

	return (
		<>
			<Container>
				<AppLoader showLoader={isLoading} />
				<AccountGroupModal showModal={showModal}
													 activeCompany={activeCompany}
													 accountSections={accountSections}
				/>

				<section className={"mt-3"}>
					<PageTitle title={dict.accountGroupsPage.pageTitle[lang]} />
				</section>

				<section>
					<Accordion>
						<Accordion.Item eventKey={'0'}>
							<Accordion.Header style={{color: '#ECECEC'}}>
								<AiOutlineFilter style={{marginRight: '5px'}}/>{dict.common.filter[lang]}
							</Accordion.Header>
							<Accordion.Body>
								<Card.Body>
									<Form>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.sectionNumber[lang]}</Form.Label>
											<Form.Select value={sectionNumberFilter}
																	 onChange={(e) => setSectionNumberFilter(e.target.value)}>
												<option value={''}>All</option>
												{accountSections.map((section) => (
													<option key={section.id} value={section.id}>
														{section.sectionNumber} - {section.title[lang]}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mt-2">
											<Form.Label>{dict.common.groupNumber[lang]}</Form.Label>
											<FormControl type="number"
																	 placeholder={dict.common.typeSectionNumberPlaceholder[lang]}
																	 value={groupNumberFilter}
																	 onChange={(e) => setGroupNumberFilter(e.target.value)}
											/>
										</Form.Group>
									</Form>
								</Card.Body>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className={"mt-3"}>
					<AddButton title={dict.accountGroupsPage.addNewButton[lang]}
										 handleClick={handleAddNewGroup}
					/>
					<Table className={"mt-2"} striped bordered hover>
						<thead>
						<tr>
							<th>{dict.common.sectionNumber[lang]}</th>
							<th>{dict.common.groupNumber[lang]}</th>
							<th>{dict.common.name[lang]}</th>
						</tr>
						</thead>
						<tbody style={{ cursor: 'pointer' }}>
						{getFilteredGroups().map((group) => (
							<tr key={group.id} onClick={() => handleActiveGroup(group)} >
								<td>{group.accountSection.sectionNumber}</td>
								<td>{group.groupNumber}</td>
								<td>{group.title[lang]}</td>
							</tr>
						))}
						</tbody>
					</Table>
				</section>
			</Container>
		</>
	);
}

export default AccountGroupPage;
