import React from "react";
import {Table} from "react-bootstrap";
import {JournalEntryResponseDto} from "../../../app/api/journal-entry.api";
import {UserCompany} from "../../../app/api/login.api";
import {displayAmount, displayDate, getFixedAmount} from "../../../app/utils";
import {Link} from "react-router-dom";
import {AppRoutes} from "../../../App";
import {VatTotalsType} from "./VatTableTotals";
import {dict} from "../../../app/dictionary";
import { Language } from "../../../app/type";

export interface VatTableProps {
	company: UserCompany,
	startDate: Date,
	endDate: Date,
	journalEntries: JournalEntryResponseDto[];
	lang: Language
}

const VatTable: React.FC<VatTableProps> = (
	{ company, startDate, endDate, journalEntries, lang }) => {

	const getVatTotals = () => {
		const vatTotals: VatTotalsType[] = [];
		journalEntries.forEach(je => {
			const vatCode = je.vat?.vatCode;
			const vatAccount = je.vatAccount;
			const companyAmount = je.companyAmount;
			const vatCompanyAmount = je.vatCompanyAmount;

			const vatTotal = vatTotals.find(vt => vt.vatCode === vatCode);
			if (vatTotal) {
				vatTotal.companyAmountTotal = getFixedAmount(vatTotal.companyAmountTotal + companyAmount);
				vatTotal.vatCompanyAmountTotal = getFixedAmount(vatTotal.vatCompanyAmountTotal + vatCompanyAmount);
			} else {
				vatTotals.push({
					vatCode,
					vatAccount,
					companyAmountTotal: companyAmount,
					vatCompanyAmountTotal: vatCompanyAmount
				});
			}
		});

		return vatTotals;
	}

	return (
		<>
			<Table striped bordered hover className={"mt-3"}>
				<thead>
					<tr>
						<th colSpan={6} style={{ fontSize: '1.2rem' }}>
							{dict.vatReport.vatPayedPage.table.headerLabel[lang]} {company.companyName} {displayDate(startDate)} - {displayDate(endDate)}
						</th>
					</tr>
					<tr>
						<th>{dict.common.date[lang]}</th>
						<th>{dict.vatReport.vatPayedPage.table.paymentsForVat[lang]}</th>
						<th>{dict.common.description[lang]}</th>
						<th style={{ textAlign: 'right' }}>{dict.common.vatCode[lang]}</th>
						<th style={{ textAlign: 'right' }}>{dict.vatReport.vatPayedPage.table.vatAmount[lang]}</th>
						<th style={{ textAlign: 'right' }}>{dict.vatReport.vatPayedPage.table.netAmount[lang]}</th>
					</tr>
				</thead>
				<tbody>
					{journalEntries.map(je => {
						return (
							<tr>
								<td>{displayDate(je.entryDate)}</td>
								<td> #{je.debit?.accountNumber} - {je.debit?.title[lang]}</td>
								<td>
									<Link to={AppRoutes.getSingleJournalEntryRoute(company.companyId, je.id)}>
										{je.invoiceNumber + '' + je.entryDescription}
									</Link>
								</td>
								<td style={{ textAlign: 'right' }}>{je.vat?.vatCode}</td>
								<td style={{ textAlign: 'right' }}>{displayAmount(je.vatCompanyAmount)} {company.companyCurrency}</td>
								<td style={{ textAlign: 'right' }}>{displayAmount(je.companyAmount)} {company.companyCurrency}</td>
							</tr>
						);
					})}

					{getVatTotals().map(({ vatCode, companyAmountTotal, vatCompanyAmountTotal }) => {
						return (
							<tr className={'last-row'}>
								<td colSpan={4}><b>{dict.vatReport.vatPayedPage.table.totalFor[lang]} {vatCode}</b></td>
								<td style={{ textAlign: 'right' }}>
									<b>{displayAmount(vatCompanyAmountTotal)} {company.companyCurrency}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<b>{displayAmount(companyAmountTotal)} {company.companyCurrency}</b>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
}

export default VatTable;
