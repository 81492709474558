import axios, {AxiosInstance} from "axios";
import {LocalStorageKey} from "./const";
import {AppRoutes} from "../App";

export class BaseRestApi {
	protected axiosInstance: AxiosInstance;

	constructor() {
		this.axiosInstance = axios.create();
		this.axiosInstance.interceptors.request.use(
			(config) => {
				const token = localStorage.getItem(LocalStorageKey.AUTH_TOKEN_KEY);
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.axiosInstance.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && error.response.status === 401) {
					localStorage.removeItem(LocalStorageKey.AUTH_TOKEN_KEY);
					window.location.href = AppRoutes.LOGIN_ROUTE;
				} else {
					throw error;
				}
			}
		);
	}
}
