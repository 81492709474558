import React, {createRef, RefObject} from "react";
import {Button, Stack} from "react-bootstrap";
import {FaLongArrowAltLeft, FaPrint} from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../app/store/user.store";
import {dict} from "../app/dictionary";
import {AiOutlineDownload, AiOutlineUpload} from "react-icons/ai";

interface PageTitleProps {
	title: string;
	showBackButton?: boolean;
	showPrintButton?: boolean;
	showCogButton?: boolean;
	showExportButton?: boolean;
	showUploadButton?: boolean;

	handleExport?: () => void;
	handleUpload?: (file: File) => void;
}

const PageTitle: React.FC<PageTitleProps> = (
	{ title, showBackButton, showPrintButton,
		showCogButton, showExportButton, showUploadButton,
		handleExport, handleUpload }
) => {

	const navigate = useNavigate();
	const inputOpenFileRef : RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
	const lang = useUserStore(state => state.userLanguage);

	const showFileUploader = (e: React.MouseEvent<any>) => {
		inputOpenFileRef.current?.click();
	}

	const handleBack = () => {
		navigate(-1);
	}

	return (
		<Stack className={"mt-3"} direction="horizontal" gap={3}>

			<input ref={inputOpenFileRef}
						 type="file"
						 accept=".csv"
						 style={{display: 'none'}}
						 onChange={(e) => {
							 if (e.target.files) {
								 handleUpload?.(e.target.files[0]);
							 }
						 }}
			/>

			{showBackButton &&
        <div>
          <Button variant="none" onClick={handleBack}>
            <FaLongArrowAltLeft size={19} color={'black'}/>
          </Button>
        </div>
			}

			<div className="p-0">
				<h1 style={{fontSize: '28px'}}>{title}</h1>
			</div>
			{showPrintButton &&
        <div className="p-2 ms-auto">
          <Button variant="none" onClick={() => window.print()}>
            <FaPrint size={19} color={'#0d6efd'}/>
          </Button>
        </div>
			}

			{showCogButton &&
        <div className="p-2 ms-auto">
          <Dropdown>
            <Dropdown.Toggle variant="none">
              <FaCog size={19} color={'#0d6efd'}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
							{showUploadButton &&
                <Dropdown.Item onClick={showFileUploader}>
                  <AiOutlineUpload/>
									{dict.PageTitle.uploadCsvFile[lang]}
                </Dropdown.Item>
							}
							{showExportButton &&
                <Dropdown.Item onClick={handleExport}>
                  <AiOutlineDownload/>
									{dict.PageTitle.export[lang]}
                </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
			}
		</Stack>
	);
}

export default PageTitle;
