import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {AiOutlineCheck} from "react-icons/ai";
import React, {FC, useEffect} from "react";
import {useCounterpartyStore} from "../../../app/store/counterparty.store";
import { useUserStore } from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";
import {useNavigate} from "react-router-dom";
import {Typeahead} from "react-bootstrap-typeahead";
import {mapCounterpartyToOption} from "../../../app/utils";
import {OperationEntrySide} from "../../../app/type";

interface CounterpartyModalProps {
	operationEntrySide: OperationEntrySide
}

const CounterpartyModal: FC<CounterpartyModalProps> = ({ operationEntrySide }) => {
	const navigate = useNavigate()
	const showModal = useCounterpartyStore(state => state.showModal);
	const counterparties = useCounterpartyStore(state => state.counterparties);
	const fetchCounterparties = useCounterpartyStore(state => state.fetchCounterparties);
	const isProcessing = useCounterpartyStore(state => state.isProcessing);
	const setCounterpartyValue = useCounterpartyStore(state => state.setCounterpartyValue);
	const newCounterparty = useCounterpartyStore(state => state.newCounterparty);
	const addCounterparty = useCounterpartyStore(state => state.addCounterparty);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		setCounterpartyValue('name', '');
		setCounterpartyValue('description', '');

		if (showModal) {
			fetchCounterparties(useUserStore.getState().activeCompany.companyId);
		}
	}, [showModal]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setCounterpartyValue(name as keyof typeof newCounterparty, value);
	}

	const handleSelect = (selected: any) => {
		setCounterpartyValue('name', selected[0]?.label);
	}

	return (
		<>
			<Modal show={showModal} onHide={() => useCounterpartyStore.setState({ showModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{dict.counterpartyPage.modal.title[lang]}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="counterparty" className="mt-3">
							<Form.Label>{dict.counterpartyPage.name[lang]}</Form.Label>
							<Typeahead
								id="counterpartyName"
								clearButton={true}
								allowNew={true}
								options={mapCounterpartyToOption(counterparties)}
								onChange={handleSelect}
								isInvalid={!newCounterparty.name}
							/>

						</Form.Group>

						<Form.Group controlId="exchangeRate" className="mb-1">
							<Form.Label>{dict.counterpartyPage.modal.description[lang]}</Form.Label>
							<Form.Control as="textarea" rows={1}
														name={'description'}
														value={newCounterparty.description}
														onChange={handleChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className={"d-flex justify-content-between"}>

					<Button variant="success"
									disabled={!newCounterparty.name}
									onClick={() => addCounterparty(navigate, operationEntrySide)}>
						<AiOutlineCheck /> {isProcessing ? <Spinner animation="border" size="sm" /> : dict.counterpartyPage.modal.saveButton[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CounterpartyModal;
