import {Col, Form, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import React, {FC} from "react";
import {useAccountMovementsStore} from "../../../app/store/account-movements.store";
import {AccountNumberResponseDto} from "../../../app/api/account-plan.api";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface AccountMovementsFilterProps {
	accountNumbers: AccountNumberResponseDto[]
}

const AccountMovementsFilter: FC<AccountMovementsFilterProps> = ({ accountNumbers }) => {
	const fromAccountId = useAccountMovementsStore(state => state.fromAccountNumberId);
	const toAccountId = useAccountMovementsStore(state => state.toAccountNumberId);
	const setFilterFromToAccountNumbers = useAccountMovementsStore(state => state.setFilterFromToAccountNumbers);
	const lang = useUserStore(state => state.userLanguage);

	const accountNumberOptions = accountNumbers?.map(account => {
		return {
			id: account.id,
			label: account.accountNumber.toString() + ' - ' + account.title.en
		}
	});

	const getSelectAccountOption = (accountId: string) => {
		const account = accountNumbers?.find(account => account.id === accountId);

		return {
			id: accountId,
			label: account?.accountNumber.toString() + ' -' + account?.title.en
		}
	}

	const handleSelectorChange = (name: string, selected: any[]) => {
		if (name === 'account-from') {
			setFilterFromToAccountNumbers(selected ? selected[0]?.id : null, toAccountId);
		}

		if (name === 'account-to') {
			setFilterFromToAccountNumbers(fromAccountId, selected ? selected[0]?.id : null);
		}
	}

	return (
		<>
			<Form>
				<Row>
					<Col>
						<Form.Group controlId="exampleForm.ControlInput1">
							<Form.Label><b>{dict.accountMovementsPage.filter.AccountNumberFrom[lang]}</b></Form.Label>
							<Typeahead
								id="account-from"
								clearButton={true}
								options={accountNumberOptions}
								selected={fromAccountId ? [getSelectAccountOption(fromAccountId)] : []}
								onChange={(selected) => handleSelectorChange('account-from', selected)}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="exampleForm.ControlInput1">
							<Form.Label><b>{dict.accountMovementsPage.filter.AccountNumberTo[lang]}</b></Form.Label>
							<Typeahead
								id="account-to"
								clearButton={true}
								disabled={fromAccountId === null}
								options={accountNumberOptions}
								selected={toAccountId ? [getSelectAccountOption(toAccountId)] : []}
								onChange={(selected) => handleSelectorChange('account-to', selected)}
							/>
						</Form.Group>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default AccountMovementsFilter;
