import React, {ChangeEvent, useEffect} from "react";
import {
	Button,
	Form,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	Modal, Spinner,
	Tab, Tabs
} from "react-bootstrap";
import {AccountClass, Currency, Language} from "../../../app/type";
import {useAccountNumberStore} from "../../../app/store/account-number.store";
import {AccountGroupResponseDto, AccountSectionResponseDto} from "../../../app/api/account-plan.api";
import {UserCompany} from "../../../app/api/login.api";
import {useUserStore} from "../../../app/store/user.store";
import { dict } from "../../../app/dictionary";

export interface AccountEditorModalProps {
	showModal: boolean;
	activeCompany: UserCompany,
	accountSections: AccountSectionResponseDto[],
	accountGroups: AccountGroupResponseDto[],
}

export const AccountNumberModal: React.FC<AccountEditorModalProps> = (
	{
		showModal,
		activeCompany,
		accountSections,
		accountGroups
	}) => {
	const [relatedSection, setRelatedSection] = React.useState<AccountSectionResponseDto>();

	const activeAccountNumber = useAccountNumberStore(state => state.activeAccountNumber);
	const isProcessing = useAccountNumberStore(state => state.isProcessing);
	const setNumberValue = useAccountNumberStore(state => state.setNumberValue);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		const relatedGroup = accountGroups.find(group => group.id === activeAccountNumber.groupId);
		setRelatedSection(relatedGroup?.accountSection);
	}, [activeAccountNumber.groupId]);

	const handleClose = () => {
		useAccountNumberStore.setState({ showModal: false });
	}

	const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setNumberValue(name, value);
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'accountNumber' && Number(value) > 0) {
			setNumberValue(name, Number(value));
			return;
		}

		if (name === 'isVat') {
			setNumberValue(name, e.target.checked);
			return;
		}

		setNumberValue(name, value);
	}

	const handleTitleChange = (e: ChangeEvent<any>, lang: Language) => {
		setNumberValue('title', e.target.value, lang);
	}

	const saveAccountNumber = () => {
		!activeAccountNumber.id ?
			useAccountNumberStore.getState().createAccountNumber(activeCompany.companyId) :
			useAccountNumberStore.getState().updateAccountNumber(activeCompany.companyId);
	}

	return (
		<div>
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{dict.AccountNumberPage.modal.title[lang]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormGroup controlId="sectionSelect" className="mt-3">
							<FormLabel>{dict.common.sectionNumber[lang]}</FormLabel>
							<Form.Select disabled={true} value={relatedSection?.id}>
								<option value={''}>Undefined section</option>
								{accountSections.map((section) => (
									<option key={section.id} value={section.id}>
										{section.sectionNumber} - {section.title.en}
									</option>
								))}
							</Form.Select>
						</FormGroup>

						<FormGroup controlId="accountGroup" className="mt-3">
							<FormLabel>{dict.common.groupNumber[lang]}</FormLabel>
							<Form.Select name='groupId'
													 value={activeAccountNumber.groupId}
													 onChange={handleSelectChange}
							>
								<option value={''}>Select account group</option>
								{accountGroups.map((group) => (
									<option key={group.id} value={group.id}>
										{group.groupNumber} - {group.title.en}
									</option>
								))}
							</Form.Select>
						</FormGroup>

						<Form.Group>
							<Form.Label>{dict.common.accountClass[lang]}</Form.Label>
							<Form.Select name={'accountClass'}
													 value={activeAccountNumber.accountClass}
													 onChange={handleSelectChange}>
								{Object.values(AccountClass).map((accountClass) => (
									<option key={accountClass} value={accountClass}>
										{accountClass}
									</option>
								))}
							</Form.Select>
						</Form.Group>

						<FormGroup controlId="accountNumber" className="mt-3">
							<FormLabel>{dict.common.accountNumber[lang]}</FormLabel>
							<Form.Control name={'accountNumber'}
														disabled={!activeAccountNumber.groupId}
														type={'number'}
														value={activeAccountNumber.accountNumber}
														onChange={handleInputChange}
							/>
						</FormGroup>

						<FormGroup controlId="accountDescription" className="mt-3">
							<FormLabel>{dict.common.accountName[lang]}</FormLabel>
							<Tabs defaultActiveKey={Language.en}>
								{Object.values(Language).map((lang) => (
									<Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
										<FormControl type="text"
																 disabled={!activeAccountNumber.groupId}
																 placeholder={`${dict.AccountNumberPage.modal.accountDescriptionIn[lang]} ${lang}`}
																 value={activeAccountNumber.title[lang]}
																 onChange={(e) => handleTitleChange(e, lang)}
										/>
									</Tab>
								))}
							</Tabs>
						</FormGroup>

						<FormGroup className="mt-3">
							<FormLabel>{dict.common.currency[lang]}</FormLabel>
							<Form.Select name='currency'
													 value={activeAccountNumber.currency}
													 disabled={!activeAccountNumber.groupId}
													 onChange={handleSelectChange}
							>
								{Object.values(Currency).map((currency) => (
									<option key={currency} value={currency}>
										{currency}
									</option>
								))}
							</Form.Select>
						</FormGroup>

						<FormGroup controlId="vatCheck" className="mt-3">
							<FormCheck type="checkbox"
												 name="isVat"
												 disabled={!activeAccountNumber.groupId}
												 checked={activeAccountNumber.isVat}
												 onChange={handleInputChange}
												 label="VAT?"
							/>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button variant="primary"
									onClick={saveAccountNumber}>
						{isProcessing ? <Spinner animation="border" size="sm" /> : dict.common.saveChanges[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
