import React, {FC} from "react";
import {Button, Dropdown, Table} from "react-bootstrap";
import {displayAmount, displayDate} from "../../../app/utils";
import {AiOutlineDelete, AiOutlineEdit, AiOutlinePlus} from "react-icons/ai";
import {Currency, JournalEntryType, OperationEntrySide} from "../../../app/type";
import {DropdownSubmenu} from "react-bootstrap-submenu";
import {OperationResponseDto} from "../../../app/api/operation.api";
import {JournalEntryResponseDto} from "../../../app/api/journal-entry.api";
import {useOperationsStore} from "../../../app/store/operations.store";
import {useUserStore} from "../../../app/store/user.store";
import FileButton from "../../../components/FileButton";
import LabelTooltip from "../../../components/LabelTooltip";
import {UserCompany} from "../../../app/api/login.api";
import {dict} from "../../../app/dictionary";

interface CollapsibleOperationEntryRowProps {
	company: UserCompany;
	operation: OperationResponseDto;
}

export const CollapsibleOperationEntryTable: FC<CollapsibleOperationEntryRowProps> = ({ company, operation }) => {
	const getJournalEntryAmount = (journalEntry: JournalEntryResponseDto) => {
		return `${displayAmount(journalEntry.companyAmount)} ${company.companyCurrency} (1 ${company.companyCurrency} = ${journalEntry.conversionRate} ${journalEntry.currency})`;
	}

	const editJournalEntry = (journalEntry: JournalEntryResponseDto) => {
		useOperationsStore.setState({
			showOperationEntryModal: true,
			operationEntryRequest: {
				id: journalEntry.id,
				entryDate: new Date(journalEntry.entryDate),
				invoiceNumber: journalEntry.invoiceNumber,
				entryDescription: journalEntry.entryDescription,
				debitAccountId: journalEntry.debit?.id,
				creditAccountId: journalEntry.credit?.id,
				vatAccountId: journalEntry.vatAccount?.id ?? null,
				entryAmount: journalEntry.entryAmount,
				vatEntryAmount: journalEntry.vatEntryAmount,
				currency: journalEntry.currency,
				conversionRate: journalEntry.conversionRate,
				version: journalEntry.version,
				attachedFileUrl: null,
				journalEntryType: journalEntry.entryType,
				vatCode: journalEntry.vat?.vatCode ?? null,
			}
		});
	}

	const handleAddPaymentDocument = (entryType: JournalEntryType, parentJournalEntry?: JournalEntryResponseDto) => {
		useOperationsStore.setState({
			showOperationEntryModal: true,
			operationEntryRequest: {
				id: '',
				entryDate: parentJournalEntry?.entryDate ? new Date(parentJournalEntry?.entryDate) : new Date(),
				invoiceNumber: parentJournalEntry?.invoiceNumber ?? '',
				entryDescription: parentJournalEntry?.entryDescription ?? '',
				debitAccountId: parentJournalEntry?.credit?.id ?? null,
				creditAccountId: null,
				vatAccountId: null,
				entryAmount: parentJournalEntry?.entryAmount || 0,
				vatEntryAmount: 0,
				currency: parentJournalEntry?.currency || Currency.CHF,
				conversionRate: parentJournalEntry?.conversionRate || 1,
				version: 0,
				attachedFileUrl: null,
				journalEntryType: entryType,
				parentEntryId: [JournalEntryType.PI, JournalEntryType.RI].includes(entryType) ? null : parentJournalEntry?.id,
				vatCode: null,
			}
		});
	}

	const handleRemoveOperationEntry = (operationId: string, journalEntryId: string) => {
		useOperationsStore.getState().removeOperationEntry(operationId, journalEntryId);
	}

	const lang = useUserStore(state => state.userLanguage);

	return (
		<tr>
			<td colSpan={7}>
				{operation?.entries?.filter(f => f.invoiceEntry).map((entry) => (
					<div key={entry.invoiceEntry.id} style={{padding: '10px'}}>
						<Table striped hover>
							<thead>
							<tr>
								<th>{dict.common.date[lang]}</th>
								<th>{dict.operationsPage.entryTable.entryType[lang]}</th>
								<th>{dict.common.invoiceNumber[lang]}</th>
								<th>{dict.common.description[lang]}</th>
								<th>{dict.common.debitNumber[lang]}</th>
								<th>{dict.common.creditNumber[lang]}</th>
								<th>{dict.common.amount[lang]}</th>
								<th>{dict.common.currency[lang]}</th>
								<th>{dict.common.vat[lang]}</th>
								<th>{dict.common.document[lang]}</th>
								<th></th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>{displayDate(entry.invoiceEntry.entryDate)}</td>
								<td>{entry.invoiceEntry.entryType}</td>
								<td>{entry.invoiceEntry.invoiceNumber}</td>
								<td>{entry.invoiceEntry.entryDescription}</td>
								<td>
									<LabelTooltip id={entry.invoiceEntry.id} title={entry.invoiceEntry.debit?.title[lang]}>
										#{entry.invoiceEntry.debit?.accountNumber}
									</LabelTooltip>
								</td>
								<td>
									<LabelTooltip id={entry.invoiceEntry.id} title={entry.invoiceEntry.credit?.title[lang]}>
										#{entry.invoiceEntry.credit?.accountNumber}
									</LabelTooltip>
								</td>
								<td>
									<LabelTooltip id={entry.invoiceEntry.id} title={getJournalEntryAmount(entry.invoiceEntry)}>
										{displayAmount(entry.invoiceEntry.entryAmount)}
									</LabelTooltip>
								</td>
								<td>{entry.invoiceEntry.currency}</td>
								<td>
									{ entry.invoiceEntry.vat?.vatCode && <div style={{display: 'flex', justifyContent: 'space-between'}}>
										<LabelTooltip id={entry.invoiceEntry.id} title={entry.invoiceEntry.vat?.title[lang]}>
											{entry.invoiceEntry.vat?.vatCode}
										</LabelTooltip>

										<LabelTooltip id={entry.invoiceEntry.id} title={displayAmount(entry.invoiceEntry.vatCompanyAmount) + ' ' + company.companyCurrency}>
											{displayAmount(entry.invoiceEntry.vatEntryAmount)}
										</LabelTooltip>

										<LabelTooltip id={entry.invoiceEntry.id} title={entry.invoiceEntry.vatAccount?.title[lang]}>
											#{entry.invoiceEntry.vatAccount?.accountNumber}
										</LabelTooltip>
									</div>}
								</td>
								<td>
									<FileButton fileUrl={entry.invoiceEntry.attachedFileUrl}/>
								</td>
								<td style={{display: 'flex', justifyContent: 'space-between'}}>
									<div>
										<Button variant={'link'} onClick={() => editJournalEntry(entry.invoiceEntry)}>
											<AiOutlineEdit/>
										</Button>
									</div>
									<div>
										<Button variant={'link'}
														onClick={() => handleRemoveOperationEntry(operation.id, entry.invoiceEntry.id)}>
											<AiOutlineDelete color={'red'}/>
										</Button>
									</div>
								</td>
							</tr>
							{entry.relatedEntries?.map((journalEntry) => (
								<tr key={journalEntry.id}>
									<td>{displayDate(journalEntry.entryDate)}</td>
									<td>{journalEntry.entryType}</td>
									<td>{journalEntry.invoiceNumber}</td>
									<td>{journalEntry.entryDescription}</td>
									<td>
										<LabelTooltip id={journalEntry.id} title={journalEntry.debit?.title[lang]}>
											#{journalEntry.debit?.accountNumber}
										</LabelTooltip>
									</td>
									<td>
										<LabelTooltip id={journalEntry.id} title={journalEntry.credit?.title[lang]}>
											#{journalEntry.credit?.accountNumber}
										</LabelTooltip>
									</td>
									<td>
										<LabelTooltip id={entry.invoiceEntry.id} title={getJournalEntryAmount(journalEntry)}>
											{displayAmount(journalEntry.entryAmount)}
										</LabelTooltip>
									</td>
									<td>{journalEntry.currency}</td>
									<td>
										<div style={{display: 'flex', justifyContent: 'space-between'}}>
											<LabelTooltip id={journalEntry.id} title={journalEntry.vat?.title[lang]}>
												{journalEntry.vat?.vatCode}
											</LabelTooltip>

											<LabelTooltip id={journalEntry.id}
																		title={displayAmount(journalEntry.vatCompanyAmount) + ' ' + company.companyCurrency}>
												{displayAmount(journalEntry.vatEntryAmount)}
											</LabelTooltip>

											<LabelTooltip id={journalEntry.id} title={journalEntry.vatAccount?.title[lang]}>
												{journalEntry.vatAccount?.accountNumber}
											</LabelTooltip>
										</div>
									</td>
									<td>
										<FileButton fileUrl={journalEntry.attachedFileUrl}/>
									</td>
									<td style={{display: 'flex', justifyContent: 'space-between'}}>
										<div>
											<Button variant={'link'} onClick={() => editJournalEntry(journalEntry)}>
												<AiOutlineEdit/>
											</Button>
										</div>
										<div>
											<Button variant={'link'}
															onClick={() => handleRemoveOperationEntry(operation.id, journalEntry.id)}>
												<AiOutlineDelete color={'red'}/>
											</Button>
										</div>
									</td>
								</tr>
							))}
							</tbody>
						</Table>
						<div className={"d-flex justify-content-center"}>
							<Dropdown>
								<Dropdown.Toggle variant="primary" size={'sm'} id="dropdown-basic">
									<AiOutlinePlus/> {dict.operationsPage.entryTable.addPaymentDocument.title[lang]}
								</Dropdown.Toggle>

								{operation.operationEntrySide === OperationEntrySide.CREDITOR &&
									<Dropdown.Menu style={{cursor: 'pointer'}}>
										<Dropdown.Item onClick={() => handleAddPaymentDocument(JournalEntryType.PI)}>
											{dict.operationsPage.entryTable.addPaymentDocument.payableInvoice[lang]}
										</Dropdown.Item>
										<Dropdown.Divider/>
										<DropdownSubmenu title={dict.operationsPage.entryTable.addPaymentDocument.paymentOfPayableInvoice.title[lang]}>
											<Dropdown.Item key={entry.invoiceEntry.id} onClick={() => handleAddPaymentDocument(JournalEntryType.PPI, entry.invoiceEntry)}>
																		{dict.operationsPage.entryTable.addPaymentDocument.paymentOfPayableInvoice.ppiForLabel[lang]
																		+ ' ' + entry.invoiceEntry.invoiceNumber + ` (${entry.invoiceEntry.entryDescription})`}
											</Dropdown.Item>
										</DropdownSubmenu>
										<DropdownSubmenu title={dict.operationsPage.entryTable.addPaymentDocument.bankChargesOnPayableInvoice.title[lang]}>
											<Dropdown.Item key={entry.invoiceEntry.id} onClick={() => handleAddPaymentDocument(JournalEntryType.PIBC, entry.invoiceEntry)}>
																		{dict.operationsPage.entryTable.addPaymentDocument.bankChargesOnPayableInvoice.pibsForLabel[lang]
																		+ ' ' + entry.invoiceEntry.invoiceNumber + ` (${entry.invoiceEntry.entryDescription})`}
											</Dropdown.Item>
										</DropdownSubmenu>
									</Dropdown.Menu>
								}

								{operation.operationEntrySide === OperationEntrySide.DEBTOR &&
								<Dropdown.Menu style={{cursor: 'pointer'}}>
									<Dropdown.Item onClick={() => handleAddPaymentDocument(JournalEntryType.RI)}>
										{dict.operationsPage.entryTable.addPaymentDocument.receivableInvoice[lang]}
									</Dropdown.Item>
									<Dropdown.Divider/>
									<DropdownSubmenu title={dict.operationsPage.entryTable.addPaymentDocument.paymentOfReceivableInvoice.title[lang]}>
										<Dropdown.Item key={entry.invoiceEntry.id} onClick={() => handleAddPaymentDocument(JournalEntryType.RPI, entry.invoiceEntry)}>
																	{dict.operationsPage.entryTable.addPaymentDocument.paymentOfReceivableInvoice.ppiForLabel[lang] 
																	+ ': ' + entry.invoiceEntry.invoiceNumber + ` (${entry.invoiceEntry.entryDescription})`}
										</Dropdown.Item>
									</DropdownSubmenu>
									<DropdownSubmenu title={dict.operationsPage.entryTable.addPaymentDocument.bankChargesOnReceivableInvoice.title[lang]}>
										<Dropdown.Item key={entry.invoiceEntry.id} onClick={() => handleAddPaymentDocument(JournalEntryType.RIBC, entry.invoiceEntry)}>
													{dict.operationsPage.entryTable.addPaymentDocument.bankChargesOnReceivableInvoice.pibsForLabel[lang] 
													+ ': ' + entry.invoiceEntry.invoiceNumber + ` (${entry.invoiceEntry.entryDescription})`}
										</Dropdown.Item>
									</DropdownSubmenu>
								</Dropdown.Menu>}
							</Dropdown>
						</div>
					</div>
				))}

				{operation?.entries?.length === 0 &&
          <div className={"d-flex justify-content-center p-3"}>
						<Button variant={'primary'} size={'sm'}
										onClick={() => handleAddPaymentDocument(operation.operationEntrySide === OperationEntrySide.CREDITOR ? JournalEntryType.PI : JournalEntryType.RI)}>
							<AiOutlinePlus/> Add payment document
						</Button>
					</div>
				}
			</td>
		</tr>
	);
}
