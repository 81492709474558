import React from "react";
import {Table} from "react-bootstrap";
import {JournalEntryResponseDto} from "../../../app/api/journal-entry.api";
import {UserCompany} from "../../../app/api/login.api";
import {displayAmount, displayDate, getFixedAmount} from "../../../app/utils";
import {AccountNumberResponseDto} from "../../../app/api/account-plan.api";
import { dict } from "../../../app/dictionary";
import { Language } from "../../../app/type";

export interface VatTableTotalsProps {
	company: UserCompany,
	startDate: Date,
	endDate: Date,
	journalEntries: JournalEntryResponseDto[];
	lang: Language;
}

export type VatTotalsType = {
	vatCode: string | undefined;
	vatAccount: AccountNumberResponseDto
	companyAmountTotal: number;
	vatCompanyAmountTotal: number;
}

const VatTableTotals: React.FC<VatTableTotalsProps> = ({ company, startDate, endDate, journalEntries, lang }) => {

	const getVatTotalsByAccount = () => {
		const vatTotals: VatTotalsType[] = [];
		journalEntries.forEach(je => {
			const vatCode = je.vat?.vatCode;
			const vatAccount = je.vatAccount;
			const companyAmount = je.companyAmount;
			const vatCompanyAmount = je.vatCompanyAmount;

			const vatTotal = vatTotals.find(vt => vt.vatCode === vatCode && vt.vatAccount.accountNumber === vatAccount.accountNumber);
			if (vatTotal) {
				vatTotal.companyAmountTotal = getFixedAmount(vatTotal.companyAmountTotal + companyAmount);
				vatTotal.vatCompanyAmountTotal = getFixedAmount(vatTotal.vatCompanyAmountTotal + vatCompanyAmount);
			} else {
				vatTotals.push({
					vatCode,
					vatAccount,
					companyAmountTotal: companyAmount,
					vatCompanyAmountTotal: vatCompanyAmount
				});
			}
		});

		return vatTotals;
	}

	return (
		<>
			<Table striped bordered hover className={"mt-5"}>
				<thead>
					<tr>
						<th colSpan={6} style={{ fontSize: '1.2rem' }}>
						{dict.vatReport.vatPayedPage.tableTotals.headerLabel[lang]} {company.companyName} {displayDate(startDate)} - {displayDate(endDate)}
						</th>
					</tr>
					<tr>
						<th>{dict.common.vatCode[lang]}</th>
						<th>{dict.common.vatAccount[lang]}</th>
						<th style={{ textAlign: 'right' }}>{dict.vatReport.vatPayedPage.tableTotals.vatTotal[lang]}</th>
						<th style={{ textAlign: 'right' }}>{dict.vatReport.vatPayedPage.tableTotals.entryTotal[lang]}</th>
					</tr>
				</thead>
				<tbody>
				{getVatTotalsByAccount().map(({ vatCode, vatAccount, companyAmountTotal, vatCompanyAmountTotal }) => {
					return (
						<tr>
							<td>{vatCode}</td>
							<td>#{vatAccount.accountNumber} - {vatAccount.title[lang]}</td>
							<td style={{ textAlign: 'right' }}>{displayAmount(vatCompanyAmountTotal)} {company.companyCurrency}</td>
							<td style={{ textAlign: 'right' }}>{displayAmount(companyAmountTotal)} {company.companyCurrency}</td>
						</tr>
					);
				})}
				</tbody>
			</Table>
		</>
	);
}

export default VatTableTotals;
