import React, {ChangeEvent, FC} from "react";
import {Button, Form, FormControl, FormGroup, FormLabel, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import {Language} from "../../../app/type";
import {useAccountGroupStore} from "../../../app/store/account-group.store";
import {AccountSectionResponseDto} from "../../../app/api/account-plan.api";
import {UserCompany} from "../../../app/api/login.api";
import {useUserStore} from "../../../app/store/user.store";
import { dict } from "../../../app/dictionary";

export interface AccountGroupModalProps {
	showModal: boolean;
	activeCompany: UserCompany;
	accountSections: AccountSectionResponseDto[]
}

export const AccountGroupModal: FC<AccountGroupModalProps> = (
	{ showModal,
		activeCompany,
		accountSections
	}) => {

	const activeAccountGroup = useAccountGroupStore(state => state.activeAccountGroup);
	const isSubmitting = useAccountGroupStore(state => state.isSubmitting);
	const setGroupValue = useAccountGroupStore(state => state.setGroupValue);
	const saveAccountGroup = useAccountGroupStore(state => state.createAccountGroup);
	const updateAccountGroup = useAccountGroupStore(state => state.updateAccountGroup);
	const lang = useUserStore(state => state.userLanguage);

	const isFormNotValid = () => {
		return activeAccountGroup.groupNumber === 0 || !activeAccountGroup.sectionId;

	}

	const handleCloseModal = () => {
		useAccountGroupStore.setState({ showModal: false })
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'groupNumber' && Number(value) < 0) {
			return;
		}
		setGroupValue(name, Number(value));
	}

	const handleTitleChange = (e: ChangeEvent<any>, lang: Language) => {
		setGroupValue('title', e.target.value, lang);
	}

	const handleSectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		setGroupValue('sectionId', value);
	}

	const handleSaveChanges = () => {
		!activeAccountGroup.id ?
			saveAccountGroup(activeCompany.companyId) :
			updateAccountGroup(activeCompany.companyId);
	}

	return (
		<>
			<div>
				<Modal show={showModal} onHide={handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>
							{ !activeAccountGroup.id ? dict.accountGroupsPage.modal.title.addNew[lang] : dict.accountGroupsPage.modal.title.edit[lang]}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group>
								<Form.Label>{dict.common.sectionNumber[lang]}</Form.Label>
								<Form.Select value={activeAccountGroup.sectionId}
														 onChange={handleSectionChange}>
									<option value={''}>{dict.accountGroupsPage.modal.selectAccountSectionText[lang]}</option>
									{accountSections.map((section) => (
										<option key={section.id} value={section.id}>
											{section.sectionNumber} - {section.title.en}
										</option>
									))}
								</Form.Select>
							</Form.Group>

							<FormGroup controlId="groupNumber" className="mt-3">
								<FormLabel>{dict.common.groupNumber[lang]}</FormLabel>
								<Form.Control name={'groupNumber'}
															type={'number'}
															value={activeAccountGroup.groupNumber}
															onChange={handleInputChange}
								/>
							</FormGroup>

							<FormGroup controlId="accountDescription" className="mt-3">
								<FormLabel>{dict.accountGroupsPage.modal.groupName[lang]}</FormLabel>
								<Tabs defaultActiveKey={Language.en}>
									{Object.values(Language).map((lang) => (
										<Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
											<FormControl type="text"
																	 placeholder={`${dict.accountSectionPage.modal.accountSectionDescriptionIn[lang]} ${lang}`}
																	 value={activeAccountGroup.title[lang]}
																	 onChange={(e) => handleTitleChange(e, lang)}
											/>
										</Tab>
									))}
								</Tabs>
							</FormGroup>
						</Form>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="primary"
										disabled={isFormNotValid() || isSubmitting}
										onClick={handleSaveChanges}
						>
							{isSubmitting ? <Spinner animation="border" size="sm" /> : dict.common.saveChanges[lang]}
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
}
