import {Accordion, Button, Card, Container, Form, FormControl, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AccountNumberModal} from "./widget/AccountNumberModal";
import PageTitle from "../../components/PageTitle";
import {AppLoader} from "../../components/AppLoader";
import {AiOutlineFilter, AiOutlinePlus} from "react-icons/ai";
import {AccountClass, Currency} from "../../app/type";
import {useAccountGroupStore} from "../../app/store/account-group.store";
import {useAccountNumberStore} from "../../app/store/account-number.store";
import {useUserStore} from "../../app/store/user.store";
import {useAccountSectionStore} from "../../app/store/account-section.store";
import {AppRoutes} from "../../App";
import {Link} from "react-router-dom";
import {AccountNumberResponseDto, buildEmptyAccountNumberRequest} from "../../app/api/account-plan.api";
import { dict } from "../../app/dictionary";

const AccountNumberPage = () => {

	const [accountClassFilter, setAccountClassFilter] = useState<AccountClass | undefined>();
	const [sectionNumberFilter, setSectionNumberFilter] = useState<string>('');
	const [groupNumberFilter, setGroupNumberFilter] = useState<string>('');
	const [accountNumberFilter, setAccountNumberFilter] = useState<string>('');
	const [accountNameFilter, setAccountNameFilter] = useState<string>('');
	const [currencyFilter, setCurrencyFilter] = useState<string>('');
	const [isVatFilter, setIsVatFilter] = useState<string>('');

	const activeCompany = useUserStore(state => state.activeCompany);
	const accountSections = useAccountSectionStore(state => state.accountSections);
	const accountGroups = useAccountGroupStore(state => state.accountGroups);

	const accountNumbers = useAccountNumberStore(state => state.accountNumbers);
	const isFetching = useAccountNumberStore(state => state.isFetching);
	const showModal = useAccountNumberStore(state => state.showModal);
	const lang = useUserStore(state => state.userLanguage);

	const getFilteredAccountNumbers = () => {
		let filteredAccountNumbers = accountNumbers;
		if (accountClassFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.accountClass === accountClassFilter);
		}

		if (sectionNumberFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.accountGroup.accountSection.id.toString().includes(sectionNumberFilter));
		}

		if (groupNumberFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.accountGroup.id.toString().includes(groupNumberFilter));
		}

		if (accountNumberFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.accountNumber.toString().includes(accountNumberFilter));
		}

		if (accountNameFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.title.en.toLowerCase().includes(accountNameFilter.toLowerCase()));
		}

		if (currencyFilter) {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.currency === currencyFilter);
		}

		if (isVatFilter !== '') {
			filteredAccountNumbers = filteredAccountNumbers.filter(f => f.isVat === (isVatFilter === 'true'));
		}

		return filteredAccountNumbers;
	}

	useEffect(() => {
		if (activeCompany.companyId) {
			useAccountSectionStore.getState().fetchAccountsSections(activeCompany.companyId);
			useAccountGroupStore.getState().fetchAccountGroups(activeCompany.companyId);
			useAccountNumberStore.getState().fetchAccountNumbers(activeCompany.companyId);
		}
	}, [activeCompany.companyId]);

	const handleAddNewAccountNumber = () => {
		useAccountNumberStore.setState({
			showModal: true,
			activeAccountNumber: buildEmptyAccountNumberRequest()
		});
	}

	const handleAccountNumberClick = (selected: AccountNumberResponseDto) => {
		useAccountNumberStore.setState({
			showModal: true,
			activeAccountNumber: {
				id: selected.id,
				groupId: selected.accountGroup.id,
				accountNumber: selected.accountNumber,
				title: selected.title,
				currency: selected.currency,
				isVat: selected.isVat,
				accountClass: selected.accountClass
			}
		});
	}

	return (
		<>
			<AppLoader showLoader={isFetching} />
			<AccountNumberModal showModal={showModal}
													activeCompany={activeCompany}
													accountSections={accountSections}
													accountGroups={accountGroups} />

			<Container>
				<section className={"mt-3"}>
					<PageTitle title={dict.AccountNumberPage.pageTitle[lang]} showPrintButton={false}/>
				</section>

				<section className={"mt-3"} id={'collapse-filter'}>
					<Accordion>
						<Accordion.Item eventKey={'0'}>
							<Accordion.Header style={{color: '#ECECEC'}}>
								<AiOutlineFilter style={{marginRight: '5px'}}/> {dict.common.filter[lang]}
							</Accordion.Header>
							<Accordion.Body>
								<Card.Body>
									<Form>
										<Form.Group>
											<Form.Label>{dict.common.accountClass[lang]}</Form.Label>
											<Form.Select value={accountClassFilter}
																	 onChange={(e) => setAccountClassFilter(e.target.value as AccountClass)}>
												<option value={''}>All</option>
												{ Object.values(AccountClass).map((accountClass) => (
													<option key={accountClass} value={accountClass}>
														{accountClass}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.sectionNumber[lang]}</Form.Label>
											<Form.Select value={sectionNumberFilter}
																	 onChange={(e) => setSectionNumberFilter(e.target.value)}>
												<option value={''}>All</option>
												{accountSections.map((section) => (
													<option key={section.id} value={section.id}>
														{section.sectionNumber} - {section.title[lang]}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.groupNumber[lang]}</Form.Label>
											<Form.Select value={groupNumberFilter}
																	 onChange={(e) => setGroupNumberFilter(e.target.value)}>
												<option value={''}>All</option>
												{accountGroups.map((group) => (
													<option key={group.id} value={group.id}>
														{group.groupNumber} - {group.title[lang]}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.accountNumber[lang]}</Form.Label>
											<FormControl type="number"
																	 placeholder={`Type section number`}
																	 value={accountNumberFilter}
																	 onChange={(e) => setAccountNumberFilter(e.target.value)}
											/>
										</Form.Group>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.accountName[lang]}</Form.Label>
											<FormControl placeholder={`Type account name`}
																	 value={accountNameFilter}
																	 onChange={(e) => setAccountNameFilter(e.target.value)}
											/>
										</Form.Group>
										<Form.Group className="mt-2">
											<Form.Label>{dict.common.currency[lang]}</Form.Label>
											<Form.Select value={currencyFilter}
																	 onChange={(e) => setCurrencyFilter(e.target.value as Currency)}>
												<option value={''}>All</option>
												{ Object.values(Currency).map((currency) => (
													<option key={currency} value={currency}>
														{currency}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										<Form.Group controlId="payments" className="mt-3">
											<Form.Label>{dict.common.vat[lang]}</Form.Label>
											<Form.Select value={isVatFilter}
																	 onChange={(e) => setIsVatFilter(e.target.value as any)}>
												<option value={''}>All</option>
												<option value={'true'}>Да</option>
												<option value={'false'}>Нет</option>
											</Form.Select>
										</Form.Group>
									</Form>
								</Card.Body>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className={"mt-3"} style={{ paddingBottom: '50px' }}>
					<Button variant="success" className="mb-1" onClick={handleAddNewAccountNumber}>
						<AiOutlinePlus/> {dict.AccountNumberPage.addNewButton[lang]}
					</Button>

					<Table striped bordered hover>
						<thead>
						<tr>
							<th>{dict.common.sectionNumber[lang]}</th>
							<th>{dict.common.groupNumber[lang]}</th>
							<th>{dict.common.accountNumber[lang]}</th>
							<th>{dict.common.accountClass[lang]}</th>
							<th>{dict.common.name[lang]}</th>
							<th>{dict.common.currency[lang]}</th>
							<th>{dict.common.vat[lang]}</th>
						</tr>
						</thead>
						<tbody style={{cursor: 'pointer'}}>
						{getFilteredAccountNumbers().map((accountNumber) => (
							<tr key={accountNumber.id} onClick={() => handleAccountNumberClick(accountNumber)}>
								<td>{accountNumber.accountGroup.accountSection.sectionNumber}</td>
								<td>{accountNumber.accountGroup.groupNumber}</td>
								<td>
									<Link to={AppRoutes.ACCOUNT_MOVEMENTS_PAGE}>{accountNumber.accountNumber}</Link>
								</td>
								<td>{accountNumber.accountClass}</td>
								<td>{accountNumber.title[lang]}</td>
								<td>{accountNumber.currency}</td>
								<td>{accountNumber.isVat ? 'Yes' : 'No'}</td>
							</tr>
						))}
						</tbody>
					</Table>
				</section>
			</Container>
		</>
	);
}

export default AccountNumberPage;
