import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {
	OperationResponseDto,
	OperationApi,
	OperationRequestDto,
	CounterpartyOperationsResponseDto
} from "../api/operation.api";
import {OperationEntrySide} from "../type";
import {useUserStore} from "./user.store";
import {toast} from "react-toastify";
import {FileUploadApi} from "../api/file-upload.api";
import {JournalEntryRequestDto} from "../api/journal-entry.api";

export interface OperationsStore {
	isFetching: boolean;
	isProcessing: boolean;
	showModal: boolean;

	counterpartyOperations: CounterpartyOperationsResponseDto | null;
	operationRequest: OperationRequestDto | null;

	selectedOperation: OperationResponseDto | null;

	setValue: (key: keyof OperationRequestDto, value: any) => void;
	fetchCounterpartyOperations: (companyId: string, counterpartyId: string, operationEntrySide: OperationEntrySide, startDate: Date, endDate: Date) => void;
	fetchCounterpartyOperationById: (companyId: string, counterpartyId: string, operationEntrySide: OperationEntrySide, operationId: string) => void;
	saveOperation: (counterpartyId: string, operationEntrySide: OperationEntrySide, counterpartyOperationRequest: OperationRequestDto) => void;
	removeOperation: (operationId: string) => void;

	// Operation entries
	attachedFile: File | null;
	showOperationEntryModal: boolean;
	operationEntryRequest: JournalEntryRequestDto | null;
	setOperationEntryValue: (key: keyof JournalEntryRequestDto, value: any) => void;
	removeOperationEntry: (operationId: string, journalEntryId: string) => void;
	updateOperationEntry: (journalEntryRequest: JournalEntryRequestDto) => void
	addOperationEntry: (operationEntryRequest: JournalEntryRequestDto) => void;
}

export const useOperationsStore = create<OperationsStore>()(immer((set) => ({
	isFetching: false,
	isProcessing: false,
	showModal: false,
	attachedFile: null,
	showOperationEntryModal: false,
	counterpartyOperations: null,
	operationRequest: null,

	selectedOperation: null,
	operationJournalEntries: [],
	operationEntryRequest: null,

	/**
	 * Set value to counterpartyOperationRequest
	 * @param key
	 * @param value
	 */
	setValue: (key: keyof OperationRequestDto, value: any) => {
		set(state => {
			state.operationRequest![key] = value;
		});
	},

	setOperationEntryValue: (key: keyof JournalEntryRequestDto, value: any) => {
		set(state => {
			state.operationEntryRequest![key] = value;
		});
	},

	/**
	 * Fetch counterparty operations
	 * @param companyId
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param startDate
	 * @param endDate
	 */
	fetchCounterpartyOperations: async (
		companyId: string,
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		startDate: Date,
		endDate: Date
	) => {
		set({ isFetching: true });
		const counterpartyOperations =
			await OperationApi.getInstance().getOperations(companyId, counterpartyId, operationEntrySide, startDate, endDate);
		set({ isFetching: false, counterpartyOperations });
	},

	/**
	 * Fetch counterparty operation
	 * @param companyId
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param operationId
	 */
	fetchCounterpartyOperationById: async (
		companyId: string,
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		operationId: string
	) => {
		set({ isFetching: true });
		const counterpartyOperations= await OperationApi.getInstance().getOperationById(companyId, counterpartyId, operationEntrySide, operationId);
		set({ isFetching: false, counterpartyOperations });

		if (counterpartyOperations && counterpartyOperations.items.length === 0) {
			toast.warning('Operation not found');
		}
	},

	/**
	 * Save counterparty operation
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param operationRequest
	 */
	saveOperation: async (
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		operationRequest: OperationRequestDto
	) => {
		set({ isProcessing: true });
		const companyId = useUserStore.getState().activeCompany?.companyId;
		const operations = useOperationsStore.getState().counterpartyOperations?.items;

		const operationResult = operationRequest.id ?
			await OperationApi.getInstance().updateOperation(companyId, operationRequest) :
			await OperationApi.getInstance().createOperation(companyId, counterpartyId, operationEntrySide, operationRequest);
		set({ isProcessing: false });

		if (operationResult) {
			set(state => {
				useUserStore.getState().updateAccountingPeriod(operationResult.operationDate);

				const index = operations?.findIndex(item => item.id === operationResult.id);
				if (index !== undefined && index !== -1) {
					state.counterpartyOperations!.items[index] = operationResult;
					toast.success('Operation updated successfully');
				} else {
					state.counterpartyOperations?.items.unshift(operationResult);
					toast.success('Operation added successfully');
				}
				state.showModal = false;
			})
		}
	},

	/**
	 * Remove counterparty operation
	 * @param operationId
	 */
	removeOperation: async (operationId: string) => {
		set({ isProcessing: true });
		const companyId = useUserStore.getState().activeCompany?.companyId;
		const operations = useOperationsStore.getState().counterpartyOperations?.items;

		const removed = await OperationApi.getInstance().removeOperation(companyId, operationId);
		set({ isProcessing: false });

		if (removed) {
			set(state => {
				const index = operations?.findIndex(item => item.id === operationId);
				if (index !== undefined && index !== -1) {
					state.counterpartyOperations!.items.splice(index, 1);
					toast.success('Operation removed successfully');
				}
				state.showModal = false;
			})
		}
	},

	/**
	 * Remove operation entry
	 * @param operationId
	 * @param journalEntryId
	 */
	removeOperationEntry: async (operationId, journalEntryId) => {
		set({ isProcessing: true });
		const updatedOperation = await OperationApi.getInstance().removeOperationEntry(operationId, journalEntryId);
		set({ isProcessing: false });

		if (updatedOperation) {
			set(state => {
				state.selectedOperation = updatedOperation;
				state.showOperationEntryModal = false;

				const operationIndex = state.counterpartyOperations?.items.findIndex(item => item.id === updatedOperation.id);
				if (operationIndex !== undefined && operationIndex !== -1) {
					state.counterpartyOperations!.items[operationIndex] = updatedOperation;
				}
				toast.success('Operation entry removed successfully');
			});
		}
	},

	/**
	 * Update journal entry
	 * @param operationEntryRequest
	 */
	updateOperationEntry: async (operationEntryRequest: JournalEntryRequestDto) => {
		set({ isProcessing: true });

		const companyId = useUserStore.getState().activeCompany?.companyId;
		const attachedFile = useOperationsStore.getState().attachedFile;

		if (attachedFile) {
			const uploadedFile = await FileUploadApi.uploadFile(companyId, attachedFile);
			if (uploadedFile) {
				operationEntryRequest = { ...operationEntryRequest, attachedFileUrl: uploadedFile.fileUrl }
			}
		}

		const updatedOperation = await OperationApi.getInstance().updateOperationEntry(operationEntryRequest);
		set({ isProcessing: false });

		if (updatedOperation) {
			set(state => {
				state.selectedOperation = updatedOperation;
				state.showOperationEntryModal = false;
				state.attachedFile = null;

				const operationIndex = state.counterpartyOperations?.items.findIndex(item => item.id === updatedOperation.id);
				if (operationIndex !== undefined && operationIndex !== -1) {
					state.counterpartyOperations!.items[operationIndex] = updatedOperation;
				}
				toast.success('Operation entry updated successfully');
			})
		}
	},

	/**
	 * Add operation entry
	 * @param operationEntryRequest
	 */
	addOperationEntry: async (operationEntryRequest: JournalEntryRequestDto) => {
		set({ isProcessing: true });
		const companyId = useUserStore.getState().activeCompany?.companyId;
		const operationId = useOperationsStore.getState().selectedOperation!.id
		const attachedFile = useOperationsStore.getState().attachedFile;

		if (attachedFile) {
			const uploadedFile = await FileUploadApi.uploadFile(companyId, attachedFile);
			if (uploadedFile) {
				operationEntryRequest = { ...operationEntryRequest, attachedFileUrl: uploadedFile.fileUrl }
			}
		}


		const updatedOperation = await OperationApi.getInstance().addOperationEntry(companyId, operationId, operationEntryRequest);
		set({ isProcessing: false });

		if (updatedOperation) {
			set(state => {
				state.selectedOperation = updatedOperation;
				state.showOperationEntryModal = false;
				state.attachedFile = null;

				const operationIndex = state.counterpartyOperations?.items.findIndex(item => item.id === updatedOperation.id);
				if (operationIndex !== undefined && operationIndex !== -1) {
					state.counterpartyOperations!.items[operationIndex] = updatedOperation;
				}
				toast.success('Operation entry added successfully');
			})
		}
	}

})));
