import {toast} from "react-toastify";
import axios, {AxiosError, AxiosResponse} from "axios";
import {AxiosInstance} from "axios";
import {AccountClass, Currency} from "../type";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {LocalStorageKey} from "../const";
import {AppRoutes} from "../../App";
import {RawAccountPlanRequestDto} from "../../page/accounts-plan/widget/AccountPlanCSVUploader";

export interface LangJSONB {
	en: string;
	de: string;
	it: string;
	fr: string;
	ru: string;
	ua: string;
	pl: string, // Polish
	md: string, // Moldavian
	lt: string, // Lithuanian
	lv: string, // Latvian
	ee: string, // Estonian
}

export interface VatResponseDto {
	vatCode: string;
	vatRegionId: string;
	amount: number;
	title: LangJSONB;
}

// Account section

export interface AccountSectionResponseDto {
	[key: string]: any;
	id: string;
	companyId: string;
	sectionNumber: number;
	title: LangJSONB;
}

export interface AccountSectionCreateRequestDto {
	sectionNumber: number;
	title: LangJSONB;
}

export interface AccountSectionUpdateRequestDto extends AccountSectionCreateRequestDto {
}

// Account group

export interface AccountGroupResponseDto {
	[key: string]: any;
	id: string;
	companyId: string;
	accountSection: AccountSectionResponseDto;
	groupNumber: number;
	title: LangJSONB;
}

export interface AccountGroupRequestDto {
	[key: string]: any;
	id: string;
	sectionId: string;
	groupNumber: number;
	title: LangJSONB;
}

export interface AccountNumberResponseDto {
	[key: string]: any;
	id: string;
	companyId: string;
	accountGroup: AccountGroupResponseDto;
	accountNumber: number;
	title: LangJSONB;
	currency: Currency;
	isVat: boolean;
	accountClass: AccountClass;
}

// Account number
export interface AccountNumberRequestDto {
	[key: string]: any;
	id: string;
	groupId: string;
	accountNumber: number;
	title: LangJSONB;
	currency: Currency;
	isVat: boolean;
	accountClass: AccountClass;
}

export class AccountPlanApi {
	private static instance: AccountPlanApi;
	private axiosInstance: AxiosInstance;

	public static getInstance(): AccountPlanApi {
		if (!AccountPlanApi.instance) {
			AccountPlanApi.instance = new AccountPlanApi();
		}
		return AccountPlanApi.instance;
	}

	constructor() {
		this.axiosInstance = axios.create();
		this.axiosInstance.interceptors.request.use(
			(config) => {
				const token = localStorage.getItem(LocalStorageKey.AUTH_TOKEN_KEY);
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.axiosInstance.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && error.response.status === 401) {
					localStorage.removeItem(LocalStorageKey.AUTH_TOKEN_KEY);
					window.location.href = AppRoutes.LOGIN_ROUTE;
				} else {
					throw error;
				}
			}
		);
	}

	/**
	 * Get account sections
	 * @param companyId
	 */
	public async getAccountSections(companyId: string): Promise<AccountSectionResponseDto[]> {
		try {
			if (!companyId) return [];

			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/sections/${companyId}`;
			const response: AxiosResponse<AccountSectionResponseDto[]> = await this.axiosInstance.get(url);
			return response.data
		} catch (e) {
				const error = e as AxiosError<BaseHttpServerResponse>;
				const errorResponse = error?.response?.data;
				toast.error(errorResponse!.operationResult);
				return [];
		}
	}

	/**
	 * Create a new account section
	 * @param companyId
	 * @param accountSectionRequest
	 */
	public async createAccountSection(
		companyId: string,
		accountSectionRequest: AccountSectionCreateRequestDto
	): Promise<AccountSectionResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/section/${companyId}`;
			const axiosResponse = await this.axiosInstance.post<AccountSectionResponseDto>(url, accountSectionRequest);
			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Update account section
	 * @param companyId
	 * @param sectionId
	 * @param accountSectionRequest
	 */
	public async updateAccountSection(
		companyId: string,
		sectionId: string,
		accountSectionRequest: AccountSectionUpdateRequestDto
	): Promise<AccountSectionResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/section/${companyId}/${sectionId}`;
			const axiosResponse = await this.axiosInstance.put<AccountSectionResponseDto>(url, accountSectionRequest);
			return axiosResponse.data
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Get account sections
	 * @param companyId
	 */
	public async getAccountGroups(companyId: string): Promise<AccountGroupResponseDto[]> {
		try {
			if (!companyId) return [];

			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/groups/${companyId}`;
			const response: AxiosResponse<AccountGroupResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	public async createAccountGroup(companyId: string, accountGroup: AccountGroupRequestDto): Promise<AccountGroupResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/group/${companyId}`;
			const axiosResponse = await this.axiosInstance.post<AccountGroupResponseDto>(url, accountGroup);
			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	public async updateAccountGroup(companyId: string, accountGroup: AccountGroupRequestDto): Promise<AccountGroupResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/group/${companyId}/${accountGroup.id}`;
			const axiosResponse = await this.axiosInstance.put<AccountGroupResponseDto>(url, accountGroup);
			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Get account numbers
	 * @param companyId
	 */
	public async getAccountNumbers(companyId: string): Promise<AccountNumberResponseDto[]> {
		try {
			if (!companyId) return [];

			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/numbers/${companyId}`;
			const response: AxiosResponse<AccountNumberResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	public async createAccountNumber(companyId: string, accountNumber: AccountNumberRequestDto): Promise<AccountNumberResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/number/${companyId}`;
			const axiosResponse = await this.axiosInstance.post<AccountNumberResponseDto>(url, accountNumber);
			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	public async updateAccountNumber(companyId: string, accountNumber: AccountNumberRequestDto): Promise<AccountNumberResponseDto | null> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/number/${companyId}/${accountNumber.id}`;
			const axiosResponse = await this.axiosInstance.put<AccountNumberResponseDto>(url, accountNumber);
			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	public async uploadCompanyAccountPlan(companyId: string, accountPlan: RawAccountPlanRequestDto): Promise<boolean> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/upload/${companyId}`;
			const axiosResponse = await this.axiosInstance.post<BaseHttpServerResponse>(url, accountPlan);
			toast.success(axiosResponse.data.operationResult);
			return true;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return false;
		}
	}

	/**
	 * Get account numbers
	 * @param companyId
	 */
	public async getVats(companyId: string): Promise<VatResponseDto[]> {
		try {
			if (!companyId) return [];

			const url: string = `${process.env.REACT_APP_API_HOST}/account-plan/v1/vats/${companyId}`;
			const response: AxiosResponse<VatResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}
}

export const buildEmptyAccountSection = (): AccountSectionResponseDto => {
	return {
		id: '',
		companyId: '',
		sectionNumber: 0,
		title: {en: '', de: '', it: '', fr: '', ru: '', ua: '', lt: '', lv: '', ee: '', pl: '', md: ''},
		accountClass: AccountClass.ASSET
	}
}

export const buildEmptyAccountGroup = (): AccountGroupResponseDto => {
	return {
		id: '',
		companyId: '',
		accountSection: buildEmptyAccountSection(),
		groupNumber: 0,
		title: {en: '', de: '', it: '', fr: '', ru: '', ua: '', lt: '', lv: '', ee: '', pl: '', md: ''}
	}
}

export const buildEmptyAccountNumberRequest = (): AccountNumberRequestDto => {
	return {
		id: '',
		groupId: '',
		accountNumber: 0,
		title: {en: '', de: '', it: '', fr: '', ru: '', ua: '', lt: '', lv: '', ee: '', pl: '', md: ''},
		currency: Currency.CHF,
		isVat: false,
		accountClass: AccountClass.ASSET
	}
}
