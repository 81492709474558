import {Button, Container, Table} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import {AiOutlinePlus} from "react-icons/ai";
import CounterpartyFilter from "./widget/CounterpartyFilter";
import {Link, useParams} from "react-router-dom";
import {AppRoutes} from "../../App";
import {OperationEntrySide} from "../../app/type";
import {useCounterpartyStore} from "../../app/store/counterparty.store";
import {AppLoader} from "../../components/AppLoader";
import React, {useEffect, useState} from "react";
import {useUserStore} from "../../app/store/user.store";
import CounterpartyModal from "./widget/CounterpartyModal";
import {dict} from "../../app/dictionary";
import {displayAmount, displayDate} from "../../app/utils";
import {toast} from "react-toastify";

const CounterpartyPage = () => {
	const { operationEntrySide } = useParams();
	const [counterpartyFilter, setCounterparty] = useState<string | undefined>();

	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const activeCompany = useUserStore(state => state.activeCompany);
	const isFetching = useCounterpartyStore(state => state.isFetching);
	const counterpartiesOperationTotals = useCounterpartyStore(state => state.counterpartiesOperationTotals);
	const counterpartiesTotals = useCounterpartyStore(state => state.counterpartiesTotals);
	const lang = useUserStore(state => state.userLanguage);

	const operationEntrySideValue = operationEntrySide?.toUpperCase() as OperationEntrySide;

	// Counterparties filter
	const filteredCounterparties = counterpartiesOperationTotals.filter(counterparty => {
		if (counterpartyFilter) {
			return counterparty.id === counterpartyFilter;
		}
		return true;
	});

	const displayedCounterparties = new Set<string>();

	/**
	 * Fetch counterparties
	 */
	useEffect(() => {
		if (!operationEntrySideValue) {
			toast.warning('Invalid url');
			return;
		}

		if (activeCompany.companyId) {
			useCounterpartyStore.getState().fetchCounterpartiesTotal(activeCompany.companyId, operationEntrySideValue, startDate, endDate);
			displayedCounterparties.clear();
		}
	}, [activeCompany.companyId, operationEntrySide, startDate, endDate]);

	/**
	 * Handle add new counterparty
	 */
	const handleAddNewCounterparty = () => {
		useCounterpartyStore.setState({
			showModal: true,
			newCounterparty: { name: '', description: '' }
		});
	}

	return (
		<>
			<AppLoader showLoader={isFetching} />
			<CounterpartyModal operationEntrySide={operationEntrySideValue} />

			<Container>
				<section className={"mt-3"}>
					<PageTitle title={`${operationEntrySideValue === OperationEntrySide.CREDITOR ? dict.counterpartyPage.creditorTitleLabel[lang] : dict.counterpartyPage.debtorTitleLabel[lang]} ${activeCompany.companyName} (${displayDate(startDate)} - ${displayDate(endDate)})`}
										 showPrintButton={false}
										 showCogButton={true}
					/>
				</section>

				<section className={"mt-3"} id={'collapse-filter'}>
					<CounterpartyFilter counterparties={counterpartiesOperationTotals}
															handleCounterpartyFilter={setCounterparty}
					/>
				</section>

				<section className={"mt-3"}>
					<Button variant="success"
									className="mb-1"
									onClick={handleAddNewCounterparty}>
						<AiOutlinePlus/> {dict.counterpartyPage.addNewButton[lang]}
					</Button>

					<Table id='journalTable' striped hover>
						<thead>
						<tr>
							<th>{dict.counterpartyPage.name[lang]}</th>
							<th style={{ textAlign: 'right' }}>{dict.counterpartyPage.totalInvoices[lang]}</th>
							<th style={{ textAlign: 'right' }}>{dict.counterpartyPage.totalInvoiceAmount[lang]}</th>
							<th style={{ textAlign: 'right' }}>{dict.common.balance[lang]}</th>
							<th style={{ textAlign: 'right' }}>{dict.common.currency[lang]}</th>
						</tr>
						</thead>
						<tbody>
						{filteredCounterparties.map(counterparty => (
							<>
								{counterparty.totals?.map((total) => {
									if (!displayedCounterparties.has(counterparty.name)) {
										displayedCounterparties.add(counterparty.name);
										return (
											<tr key={total.currency}>
												<td>
													<Link
														to={AppRoutes.getOperationsRoute(activeCompany.companyId, OperationEntrySide[operationEntrySide!.toUpperCase() as keyof typeof OperationEntrySide], counterparty.id)}>
														{counterparty.name}
													</Link>
												</td>
												<td style={{ textAlign: 'right' }}>{total.totalInvoices}</td>
												<td style={{ textAlign: 'right' }}>{displayAmount(total.totalInvoiceAmount)}</td>
												<td style={{ textAlign: 'right' }}>{displayAmount(total.totalBalance)}</td>
												<td style={{ textAlign: 'right' }}>{total.currency}</td>
											</tr>
										);
									} else {
										return (
											<tr key={total.currency}>
												<td></td>
												<td style={{ textAlign: 'right' }}>{total.totalInvoices}</td>
												<td style={{ textAlign: 'right' }}>{displayAmount(total.totalInvoiceAmount)}</td>
												<td style={{ textAlign: 'right' }}>{displayAmount(total.totalBalance)}</td>
												<td style={{ textAlign: 'right' }}>{total.currency}</td>
											</tr>
										);
									}
								})}
							</>
						))}

						{ counterpartiesTotals.map(total => (
							<tr className={'last-row'} key={total.currency}>
								<td>{dict.common.total[lang]}</td>
								<td style={{ textAlign: 'right' }}>{total.totalInvoices}</td>
								<td style={{ textAlign: 'right' }}>{displayAmount(total.totalInvoiceAmount)}</td>
								<td style={{ textAlign: 'right' }}>{displayAmount(total.totalBalance)}</td>
								<td style={{ textAlign: 'right' }}>{total.currency}</td>
							</tr>
						))}

						</tbody>
					</Table>
				</section>
			</Container>
		</>
	)
}

export default CounterpartyPage;
