import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {AiOutlineCheck, AiOutlineDelete} from "react-icons/ai";
import React, {FC, useEffect} from "react";
import {useJournalEntryStore} from "../../../app/store/journal-entry.store";
import {Currency} from "../../../app/type";
import {UserCompany} from "../../../app/api/login.api";
import { useUserStore } from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";
import {
	getFixedAmount,
	getSelectedAccountNumberOption,
	getSelectedVatOption,
	mapAccountNumberToOption,
	mapVatToOption
} from "../../../app/utils";

interface JournalEntryModalProps {
	company: UserCompany
}

export const JournalEntryModal: FC<JournalEntryModalProps> = ({ company }) => {
	const vatAccounts = company.accountsPlan?.filter(account => account.isVat) ?? [];

	const showModal = useJournalEntryStore(state => state.showModal);
	const isProcessing = useJournalEntryStore(state => state.isProcessing);
	const journalEntry = useJournalEntryStore(state => state.journalEntry);
	const setEntryValue = useJournalEntryStore(state => state.setEntryValue);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		useJournalEntryStore.setState({ file: null });
	}, [journalEntry.id]);

	const isFormNotValid = () => {
		if (journalEntry.vatCode && !journalEntry.vatAccountId) return true;

		return !journalEntry.entryDescription || !journalEntry.entryDate || !journalEntry.debitAccountId || !journalEntry.creditAccountId || !journalEntry.entryAmount;
	}

	/**
	 * Update journal entry value
	 * @param e
	 */
	const updateEntryValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'entryDate') {
			setEntryValue(name, new Date(value));
			return;
		}

		if (name === 'entryAmount' || name === 'conversionRate' || name === 'vatEntryAmount') {
			setEntryValue(name, parseFloat(value));
			return;
		}

		setEntryValue(name, value);
	}

	/**
	 * Update entry date
	 * @param e
	 */
	const updateEntryDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setEntryValue(name, new Date(value));
	}

	/**
	 * Handle selector change
	 * @param name
	 * @param selected
	 */
	const handleSelectorChange = (name: string, selected: any[]) => {
		if (!selected.length) {
			setEntryValue(name, null);
			if (name === 'vatCode') {
				setEntryValue('vatEntryAmount', 0);
			}
			return;
		}
		setEntryValue(name, selected[0]?.id);

		if (name === 'vatCode') {
			const vat = company.vats.find(vat => vat.vatCode === selected[0]?.id);
			if (vat) {
				setEntryValue('vatEntryAmount', getFixedAmount(vat.amount * journalEntry.entryAmount));
			}
		}
	}

	/**
	 * Submit journal entry
	 */
	const handleSubmitJournalEntry = () => {
		useJournalEntryStore.getState().submitJournalEntry(journalEntry);
	}

	/**
	 * Handle file change
	 * @param e
	 */
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files) return;
		useJournalEntryStore.setState({ file: files[0] });
	}

	return (
		<>
			<Modal show={showModal} onHide={() => useJournalEntryStore.setState({ showModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title>
						{journalEntry.id ? dict.journalEntriesPage.modal.title.edit[lang] : dict.journalEntriesPage.modal.title.addNew[lang] }
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="date">
							<Form.Label>{dict.common.entryDate[lang]} *</Form.Label>
							<Form.Control type="date"
														name={'entryDate'}
														value={journalEntry.entryDate?.toISOString()?.split('T')[0]}
														onChange={updateEntryDate}
														isInvalid={!journalEntry.entryDate}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mt-3">
							<Form.Label>{dict.common.invoiceNumber[lang]}</Form.Label>
							<Form.Control type="text"
														name={'invoiceNumber'}
														value={journalEntry.invoiceNumber}
														onChange={updateEntryValue}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mt-3">
							<Form.Label>{dict.common.invoiceDescription[lang]} *</Form.Label>
							<Form.Control type="text"
														name={'entryDescription'}
														value={journalEntry.entryDescription}
														onChange={updateEntryValue}
														isInvalid={!journalEntry.entryDescription}
							/>
						</Form.Group>

						<Row>
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group controlId="debit" className="mt-3">
									<Form.Label>{dict.common.dr[lang]} </Form.Label>
									<Typeahead id="debit"
														 clearButton={true}
														 options={mapAccountNumberToOption(company.accountsPlan ?? [], lang)}
														 selected={journalEntry.debitAccountId ? [getSelectedAccountNumberOption(company.accountsPlan, journalEntry.debitAccountId, lang)] : []}
														 onChange={(selected) => handleSelectorChange('debitAccountId', selected)}
														 isInvalid={!journalEntry.debitAccountId}

									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="credit" className="mt-3">
									<Form.Label>{dict.common.cr[lang]}</Form.Label>
									<Typeahead id="credit"
														 clearButton={true}
														 options={mapAccountNumberToOption(company.accountsPlan ?? [], lang)}
														 selected={journalEntry.creditAccountId ? [getSelectedAccountNumberOption(company.accountsPlan, journalEntry.creditAccountId, lang)] : []}
														 onChange={(selected) => handleSelectorChange('creditAccountId', selected)}
														 isInvalid={!journalEntry.creditAccountId}
									/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mt-3">
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group  className="mb-1">
									<Form.Label>{dict.common.amount[lang]}</Form.Label>
									<Form.Control type="number"
																name={'entryAmount'}
																value={journalEntry.entryAmount}
																onChange={updateEntryValue}
																isInvalid={!journalEntry.entryAmount}
									/>
								</Form.Group>
							</Col>
							<Col >
								<Form.Group controlId="currency" className="mb-1">
									<Form.Label>{dict.common.currency[lang]}</Form.Label>
									<Typeahead
										id="currency"
										clearButton={true}
										selected={journalEntry.currency ? [journalEntry.currency] : []}
										options={Object.values(Currency)}
										onChange={(selected) => setEntryValue('currency', selected[0])}
										isInvalid={!journalEntry.currency}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId="exchangeRate" className="mb-1">
							<Form.Label>{dict.common.conversionRate[lang]}</Form.Label>
							<Form.Control type="number"
														name={'conversionRate'}
														placeholder={`1 ${company.companyCurrency} = ? ${journalEntry.currency}`}
														value={journalEntry.conversionRate ?? ''}
														onChange={updateEntryValue}
														disabled={journalEntry.currency === company.companyCurrency}
							/>
						</Form.Group>
						<Row className="mt-3">
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group controlId="vat" className="mt-3">
									<Form.Label>{dict.common.vat[lang]}</Form.Label>
									<Typeahead id="vat"
														 clearButton={true}
														 disabled={vatAccounts?.length === 0}
														 selected={journalEntry.vatCode ? [getSelectedVatOption(company.vats, journalEntry.vatCode, lang)] : []}
														 onChange={(selected) => handleSelectorChange('vatCode', selected)}
														 options={mapVatToOption(company.vats ?? [], lang)}
									/>
								</Form.Group>
							</Col>

							<Col>
								<Form.Group controlId="vatAmount" className="mt-3">
									<Form.Label>VAT Amount</Form.Label>
									<Form.Control type="number"
																name={'vatEntryAmount'}
																disabled={!journalEntry.vatCode}
																value={journalEntry.vatEntryAmount}
																onChange={updateEntryValue}
									/>
								</Form.Group>
							</Col>
						</Row>

						<Form.Group controlId="vatAccountNumber" className="mt-3">
							<Form.Label>{dict.common.vatAccountNumber[lang]}</Form.Label>
							<Typeahead id="vat"
												 clearButton={true}
												 disabled={!journalEntry.vatCode}
												 selected={journalEntry.vatAccountId ? [getSelectedAccountNumberOption(vatAccounts, journalEntry.vatAccountId, lang)] : []}
												 onChange={(selected) => handleSelectorChange('vatAccountId', selected)}
												 options={mapAccountNumberToOption(vatAccounts ?? [], lang)}
							/>
						</Form.Group>
						<Form.Group controlId="file" className="mt-3">
							<Form.Label>{dict.common.attachDocument[lang]}</Form.Label>
							<Form.Control type="file" accept=".pdf, .png, .jpg, .jpg" onChange={handleFileChange}/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className={"d-flex justify-content-between"}>
	
					<Button variant="success"
									onClick={handleSubmitJournalEntry}
									disabled={isProcessing || isFormNotValid()}>
						<AiOutlineCheck /> {isProcessing ? <Spinner animation="border" size="sm" /> : dict.common.saveEntry[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
