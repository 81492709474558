import {BaseRestApi} from "../BaseRestApi";
import {AccountClass, Currency, Language} from "../type";
import {AxiosError} from "axios";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {toast} from "react-toastify";

export interface BalanceTotalResponseDto {
	currency: Currency;
	drTotal: number;
	crTotal: number;
	variance: number;
}

export interface AnnualGroupReportResponseDto {
	groupId: string;
	groupTitle: string;

	groupNumber: number;

	currentPeriodVariance: number;
	previousPeriodVariance: number;
}

export interface AnnualClassReportResponseDto {
	companyId: string;
	accountClassId: string;
	accountClass: AccountClass;

	currentPeriodVariance: number;
	previousPeriodVariance: number;

	groups: AnnualGroupReportResponseDto[];
}

export interface BalanceReportAccountResponseDto {
	accountId: string;
	accountTitle: string;

	accountNumber: number;
	previousPeriodTotals: BalanceTotalResponseDto[];
	currentPeriodTotals: BalanceTotalResponseDto[];
	accountCurrency: Currency;
}

export interface BalanceReportGroupResponseDto {
	groupId: string;
	groupTitle: string;

	groupNumber: number;
	previousPeriodTotals: BalanceTotalResponseDto[];
	currentPeriodTotals: BalanceTotalResponseDto[];
	accounts: BalanceReportAccountResponseDto[];
}

export interface BalanceReportSectionResponseDto {
	sectionId: string;
	sectionTitle: string;

	sectionNumber: number;

	previousPeriodTotals: BalanceTotalResponseDto[];
	currentPeriodTotals: BalanceTotalResponseDto[];
	groups: BalanceReportGroupResponseDto[];
}

export interface BalanceReportResponseDto {
	companyId: string;
	accountClass: AccountClass;

	previousPeriodTotals: BalanceTotalResponseDto[];
	currentPeriodTotals: BalanceTotalResponseDto[];
	sections: BalanceReportSectionResponseDto[];
}

export class BalanceReportApi extends BaseRestApi {

	private static instance: BalanceReportApi;

	public static getInstance(): BalanceReportApi {
		if (!BalanceReportApi.instance) {
			BalanceReportApi.instance = new BalanceReportApi();
		}
		return BalanceReportApi.instance;
	}

	/**
	 * Get asset-liability report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param lang
	 */
	public async getAssetLiabilityReport(
		companyId: string,
		startDate: Date,
		endDate: Date,
		lang: Language
	): Promise<BalanceReportResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/balance-report/v1/asset-liability/${companyId}?
				startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&lang=${lang}`;
			const response = await this.axiosInstance.get<BalanceReportResponseDto[]>(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * Get profit-lost report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 */
	public async getProfitLostReport(
		companyId: string,
		startDate: Date,
		endDate: Date,
		lang: Language
	): Promise<BalanceReportResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/balance-report/v1/profit-lost/${companyId}?
				startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&lang=${lang}`;
			const response = await this.axiosInstance.get<BalanceReportResponseDto[]>(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * Get annual report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param lang
	 */
	public async getAnnualReport(
		companyId: string,
		startDate: Date,
		endDate: Date,
		lang: Language
	): Promise<AnnualClassReportResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/balance-report/v1/annual/${companyId}?
				startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&lang=${lang}`;
			const response = await this.axiosInstance.get<AnnualClassReportResponseDto[]>(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

}
