
// @see arvut-be/src/api/rest/response/http-server-error-response
export enum OperationResult {
	OK = "OK",
	UNDEFINED = 'UNDEFINED',

	// Common error types
	BAD_REQUEST_EXCEPTION = "BAD_REQUEST_EXCEPTION",
	AUTHORIZATION_EXCEPTION = 'AUTHORIZATION_EXCEPTION',
	FORBIDDEN_EXCEPTION = 'FORBIDDEN_EXCEPTION',
	ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND",
	INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
	ENTITY_VERSION_NOT_MATCH = 'ENTITY_VERSION_NOT_MATCH',

	// User specific error types
	USER_NOT_ACTIVE = 'USER_NOT_ACTIVE',
	CURRENCY_RATE_NOT_FOUND = 'CURRENCY_RATE_NOT_FOUND',
}

export interface BaseHttpServerResponse {
	operationResult: OperationResult;
	message?: string | string[];
}
