
export const dict: Record<string, any> = {
	common: {
		filter: {
			en: 'Filter',
			de: 'Filter',
			it: 'Filtro',
			fr: 'Filtre',
			ru: 'Фильтр',
			ua: 'Фільтр',
			pl: 'Filtr',
			md: 'Filtru',
			lt: 'Filtras',
			lv: 'Filtrs',
			ee: 'Filter',
		
		},
		displayAll: {
			en: 'Display all',
			de: 'Alle anzeigen',
			it: 'Mostra tutto',
			fr: 'Afficher tout',
			ru: 'Показать все',
			ua: 'Показати все',
			pl: 'Pokaż wszystko',
			md: 'Afișează tot',
			lt: 'Rodyti viską',
			lv: 'Rādīt visu',
			ee: 'Näita kõiki',
		},
		date: {
			en: 'Date',
			de: 'Datum',
			it: 'Data',
			fr: 'Date',
			ru: 'Дата',
			ua: 'Дата',
			pl: 'Data',
			md: 'Data',
			lt: 'Data',
			lv: 'Datums',
			ee: 'Kuupäev',
		},
		amount: {
			en: 'Amount',
			de: 'Betrag',
			it: 'Quantità',
			fr: 'Montant',
			ru: 'Сумма',
			ua: 'Сума',
			pl: 'Kwota',
			md: 'Sumă',
			lt: 'Suma',
			lv: 'Summa',
			ee: 'Summa',
		},
		balance: {
			en: 'Balance',
			de: 'Bilanz',
			it: 'Bilancio',
			fr: 'Solde',
			ru: 'Баланс',
			ua: 'Баланс',
			pl: 'Bilans',
			md: 'Balans',
			lt: 'Balansas',
			lv: 'Bilance',
			ee: 'Bilanss',
		},
		total: {
			en: 'Total',
			de: 'Gesamt',
			it: 'Totale',
			fr: 'Total',
			ru: 'Итого',
			ua: 'Всього',
			pl: 'Razem',
			md: 'Total',
			lt: 'Iš viso',
			lv: 'Kopā',
			ee: 'Kokku',
		},
		currency: {
			en: 'Currency',
			de: 'Währung',
			it: 'Valuta',
			fr: 'Devise',
			ru: 'Валюта',
			ua: 'Валюта',
			pl: 'Waluta',
			md: 'Valută',
			lt: 'Valiuta',
			lv: 'Valūta',
			ee: 'Valuuta',
		},
		description: {
			en: 'Description',
			de: 'Beschreibung',
			it: 'Descrizione',
			fr: 'Description',
			ru: 'Описание',
			ua: 'Опис',
			pl: 'Opis',
			md: 'Descriere',
			lt: 'Aprašymas',
			lv: 'Apraksts',
			ee: 'Kirjeldus',
		},
		accountNumber: {
			en: 'Account #',
			de: 'Kontonummer',
			it: 'Numero del conto',
			fr: 'Numéro de compte',
			ru: 'Номер счета',
			ua: 'Номер рахунку',
			pl: 'Numer konta',
			md: 'Numărul contului',
			lt: 'Sąskaitos numeris',
			lv: 'Konta numurs',
			ee: 'Konto number',
		},
		accountName: {
			en: 'Account name',
			de: 'Kontoname',
			it: 'Nome del conto',
			fr: 'Nom du compte',
			ru: 'Название счета',
			ua: 'Назва рахунку',
			pl: 'Nazwa konta',
			md: 'Numele contului',
			lt: 'Sąskaitos pavadinimas',
			lv: 'Konta nosaukums',
			ee: 'Konto nimi',
		},
		accountClass: {
			en: 'Account class',
			de: 'Kontoklasse',
			it: 'Classe del conto',
			fr: 'Classe de compte',
			ru: 'Класс счета',
			ua: 'Клас рахунку',
			pl: 'Klasa konta',
			md: 'Clasa contului',
			lt: 'Sąskaitos klasė',
			lv: 'Konta klase',
			ee: 'Konto klass',
		},
		invoiceNumber: {
			en: 'Invoice #',
			de: 'Rechnungsnummer',
			it: 'Numero della fattura',
			fr: 'Numéro de facture',
			ru: 'Номер счета',
			ua: 'Номер рахунку',
			pl: 'Numer faktury',
			md: 'Numărul facturii',
			lt: 'Sąskaitos numeris',
			lv: 'Rēķina numurs',
			ee: 'Arve number',
		},
		invoiceDescription: {
			en: 'Invoice description',
			de: 'Rechnungsbeschreibung',
			it: 'Descrizione della fattura',
			fr: 'Description de la facture',
			ru: 'Описание счета',
			ua: 'Опис рахунку',
			pl: 'Opis faktury',
			md: 'Descrierea facturii',
			lt: 'Sąskaitos aprašymas',
			lv: 'Rēķina apraksts',
			ee: 'Arve kirjeldus',
		},
		createdAt: {
			en: 'Created at',
			de: 'Erstellt am',
			it: 'Creato il',
			fr: 'Créé le',
			ru: 'Создано',
			ua: 'Створено',
			pl: 'Stworzone',
			md: 'Creat la',
			lt: 'Sukurta',
			lv: 'Izveidots',
			ee: 'Loodud',
		},
		createdBy: {
			en: 'Created by',
			de: 'Erstellt von',
			it: 'Creato da',
			fr: 'Créé par',
			ru: 'Создано',
			ua: 'Створено',
			pl: 'Stworzone',
			md: 'Creat de',
			lt: 'Sukurta',
			lv: 'Izveidots',
			ee: 'Loodud',
		},
		updatedAt: {
			en: 'Updated at',
			de: 'Aktualisiert am',
			it: 'Aggiornato il',
			fr: 'Mis à jour le',
			ru: 'Обновлено',
			ua: 'Оновлено',
			pl: 'Zaktualizowane',
			md: 'Actualizat la',
			lt: 'Atnaujinta',
			lv: 'Atjaunots',
			ee: 'Uuendatud',
		},
		updatedBy: {
			en: 'Updated by',
			de: 'Aktualisiert von',
			it: 'Aggiornato da',
			fr: 'Mis à jour par',
			ru: 'Обновлено',
			ua: 'Оновлено',
			pl: 'Zaktualizowane',
			md: 'Actualizat de',
			lt: 'Atnaujinta',
			lv: 'Atjaunots',
			ee: 'Uuendatud',
		},
		comments: {
			en: 'Comments',
			de: 'Kommentare',
			it: 'Commenti',
			fr: 'Commentaires',
			ru: 'Комментарии',
			ua: 'Коментарі',
			pl: 'Komentarze',
			md: 'Comentarii',
			lt: 'Komentarai',
			lv: 'Komentāri',
			ee: 'Kommentaarid',
		},
		addComment: {
			en: 'Add comment',
			de: 'Kommentar hinzufügen',
			it: 'Aggiungi commento',
			fr: 'Ajouter un commentaire',
			ru: 'Добавить комментарий',
			ua: 'Додати коментар',
			pl: 'Dodaj komentarz',
			md: 'Adăugați comentariu',
			lt: 'Pridėti komentarą',
			lv: 'Pievienot komentāru',
			ee: 'Lisa kommentaar',
		},
		entryDate: {
			en: 'Entry date',
			de: 'Buchungsdatum',
			it: 'Data di registrazione',
			fr: 'Date de saisie',
			ru: 'Дата операции',
			ua: 'Дата операції',
			pl: 'Data operacji',
			md: 'Data operațiunii',
			lt: 'Įrašo data',
			lv: 'Ieraksta datums',
			ee: 'Kande kuupäev',
		},
		entryType: {
			en: 'Entry type',
			de: 'Buchungstyp',
			it: 'Tipo di registrazione',
			fr: 'Type de saisie',
			ru: 'Тип операции',
			ua: 'Тип операції',
			pl: 'Typ operacji',
			md: 'Tipul operațiunii',
			lt: 'Įrašo tipas',
			lv: 'Ieraksta tips',
			ee: 'Kande tüüp',
		},
		removeEntry: {
			en: 'Remove entry',
			de: 'Eintrag entfernen',
			it: 'Rimuovi voce',
			fr: 'Supprimer l\'entrée',
			ru: 'Удалить запись',
			ua: 'Видалити запис',
			pl: 'Usuń wpis',
			md: 'Eliminați intrarea',
			lt: 'Pašalinti įrašą',
			lv: 'Noņemt ierakstu',
			ee: 'Eemalda kanne',
		},
		saveEntry: {
			en: 'Save entry',
			de: 'Eintrag speichern',
			it: 'Salva voce',
			fr: 'Enregistrer l\'entrée',
			ru: 'Сохранить запись',
			ua: 'Зберегти запис',
			pl: 'Zapisz wpis',
			md: 'Salvați intrarea',
			lt: 'Išsaugoti įrašą',
			lv: 'Saglabāt ierakstu',
			ee: 'Salvesta kanne',
		},
		dr: {
			en: 'DR',
			de: 'DR',
			it: 'DR',
			fr: 'DR',
			ru: 'DR',
			ua: 'DR',
			pl: 'DR',
			md: 'DR',
			lt: 'DR',
			lv: 'DR',
			ee: 'DR',
		},
		cr: {
			en: 'CR',
			de: 'CR',
			it: 'CR',
			fr: 'CR',
			ru: 'CR',
			ua: 'CR',
			pl: 'CR',
			md: 'CR',
			lt: 'CR',
			lv: 'CR',
			ee: 'CR',
		},
		debitNumber: {
			en: '#DR',
			de: '#DR',
			it: '#DR',
			fr: '#DR',
			ru: '#DR',
			ua: '#DR',
			pl: '#DR',
			md: '#DR',
			lt: '#DR',
			lv: '#DR',
			ee: '#DR',
		},
		creditNumber: {
			en: '#CR',
			de: '#CR',
			it: '#CR',
			fr: '#CR',
			ru: '#CR',
			ua: '#CR',
			pl: '#CR',
			md: '#CR',
			lt: '#CR',
			lv: '#CR',
			ee: '#CR',
		},
		// ???
		conversionRate: {
			en: 'Conversion rate',
			de: 'Wechselkurs',
			it: 'Tasso di conversione',
			fr: 'Taux de conversion',
			ru: 'Курс обмена',
			ua: 'Курс обміну',
			pl: 'Kurs wymiany',
			md: 'Rata de schimb',
			lt: 'Valiutos kursas',
			lv: 'Konversijas likme',
			ee: 'Konversioonimäär',
		},
		vat: {
			en: 'VAT',
			de: 'MwSt.',
			it: 'IVA',
			fr: 'TVA',
			ru: 'НДС',
			ua: 'ПДВ',
			pl: 'VAT',
			md: 'TVA',
			lt: 'PVM',
			lv: 'PVN',
			ee: 'KMK',
		},
		vatCode: {
			en: 'VAT Code',
			de: 'MwSt. Code',
			it: 'Codice IVA',
			fr: 'Code TVA',
			ru: 'Код НДС',
			ua: 'Код ПДВ',
			pl: 'Kod VAT',
			md: 'Cod TVA',
			lt: 'PVM kodas',
			lv: 'PVN kods',
			ee: 'KMK kood',
		},
		vatAccount: {
			en: 'VAT account',
			de: 'MwSt. Konto',
			it: 'Conto IVA',
			fr: 'Compte TVA',
			ru: 'Счет НДС',
			ua: 'Рахунок ПДВ',
			pl: 'Konto VAT',
			md: 'Cont TVA',
			lt: 'PVM sąskaita',
			lv: 'PVN konts',
			ee: 'KMK konto',	
		},
		vatAccountNumber: {
			en: 'VAT account #',
			de: 'MwSt. Konto #',
			it: 'Conto IVA #',
			fr: 'Compte TVA #',
			ru: 'Счет НДС #',
			ua: 'Рахунок ПДВ #',
			pl: 'Konto VAT #',
			md: 'Cont TVA #',
			lt: 'PVM sąskaitos numeris',
			lv: 'PVN konta numurs',
			ee: 'KMK konto number',
		},
		document: {
			en: 'Document',
			de: 'Dokument',
			it: 'Documento',
			fr: 'Document',
			ru: 'Документ',
			ua: 'Документ',
			pl: 'Dokument',
			md: 'Document',
			lt: 'Dokumentas',
			lv: 'Dokuments',
			ee: 'Dokument',
		},
		attachDocument: {
			en: 'Attach document',
			de: 'Dokument anhängen',
			it: 'Allega documento',
			fr: 'Joindre un document',
			ru: 'Прикрепить документ',
			ua: 'Прикріпити документ',
			pl: 'Załącz dokument',
			md: 'Atașați documentul',
			lt: 'Pridėti dokumentą',
			lv: 'Pievienot dokumentu',
			ee: 'Manusta dokument',
		},
		sectionNumber: {
			en: 'Section #',
			de: 'Abschnitt #',
			it: 'Sezione #',
			fr: 'Section #',
			ru: 'Раздел #',
			ua: 'Розділ #',
			pl: 'Sekcja #',
			md: 'Secțiunea #',
			lt: 'Skyriaus #',
			lv: 'Sadaļa #',
			ee: 'Sektsioon #',
		},
		typeSectionNumberPlaceholder: {
			en: 'Type section number',
			de: 'Geben Sie die Abschnittsnummer ein',
			it: 'Digita il numero di sezione',
			fr: 'Entrez le numéro de section',
			ru: 'Введите номер раздела',
			ua: 'Введіть номер розділу',
			pl: 'Wprowadź numer sekcji',
			md: 'Introduceți numărul secțiunii',
			lt: 'Įveskite skyriaus numerį',
			lv: 'Ievadiet sadaļas numuru',
			ee: 'Sisesta sektsiooni number',
		},
		groupNumber: {
			en: 'Group #',
			de: 'Gruppe #',
			it: 'Gruppo #',
			fr: 'Groupe #',
			ru: 'Группа #',
			ua: 'Група #',
			pl: 'Grupa #',
			md: 'Grupul #',
			lt: 'Grupė #',
			lv: 'Grupa #',
			ee: 'Grupp #',
		},
		name: {
			en: 'Name',
			de: 'Name',
			it: 'Nome',
			fr: 'Nom',
			ru: 'Название',
			ua: 'Назва',
			pl: 'Nazwa',
			md: 'Nume',
			lt: 'Pavadinimas',
			lv: 'Nosaukums',
			ee: 'Nimi',
		},
		saveChanges: {
			en: 'Save changes',
			de: 'Änderungen speichern',
			it: 'Salva modifiche',
			fr: 'Enregistrer les modifications',
			ru: 'Сохранить изменения',
			ua: 'Зберегти зміни',
			pl: 'Zapisz zmiany',
			md: 'Salvați modificările',
			lt: 'Išsaugoti pakeitimus',
			lv: 'Saglabāt izmaiņas',
			ee: 'Salvesta muudatus',
		},
	},

	accountSectionPage: {
		pageTitle: {
			en: 'Account Sections',
			de: 'Kontenbereiche',
			it: 'Sezioni del conto',
			fr: 'Sections de compte',
			ru: 'Разделы счетов',
			ua: 'Розділи рахунків',
			pl: 'Sekcje kont',
			md: 'Secțiuni de conturi',
			lt: 'Sąskaitų skyriai',
			lv: 'Konta sadaļas',
			ee: 'Konto sektsioonid',
		},
		addNewSection: {
			en: 'Add new section',
			de: 'Neuen Bereich hinzufügen',
			it: 'Aggiungi nuova sezione',
			fr: 'Ajouter une nouvelle section',
			ru: 'Добавить новый раздел',
			ua: 'Додати новий розділ',
			pl: 'Dodaj nową sekcję',
			md: 'Adăugați o nouă secțiune',
			lt: 'Pridėti naują skyrių',
			lv: 'Pievienot jaunu sadaļu',
			ee: 'Lisa uus sektsioon',
		},
		section: {
			en: 'Section',
			de: 'Bereich',
			it: 'Sezione',
			fr: 'Section',
			ru: 'Раздел',
			ua: 'Розділ',
			pl: 'Sekcja',
			md: 'Secțiune',
			lt: 'Skyrius',
			lv: 'Sadaļa',
			ee: 'Sektsioon',
		},
		modal: {
			title: {
				addNew: {
					en: 'New account section',
					de: 'Neuer Kontenbereich',
					it: 'Nuova sezione del conto',
					fr: 'Nouvelle section de compte',
					ru: 'Новый раздел счета',
					ua: 'Новий розділ рахунку',
					pl: 'Nowy dział konta',
					md: 'Secțiune de cont nouă',
					lt: 'Naujas sąskaitos skyrius',
					lv: 'Jauna konta sadaļa',
					ee: 'Uus konto sektsioon',
				},
				edit: {
					en: 'Edit account section',
					de: 'Kontenbereich bearbeiten',
					it: 'Modifica sezione del conto',
					fr: 'Modifier la section de compte',
					ru: 'Редактировать раздел счета',
					ua: 'Редагувати розділ рахунку',
					pl: 'Edytuj sekcję konta',
					md: 'Editați secțiunea de cont',
					lt: 'Redaguoti sąskaitos skyrių',
					lv: 'Rediģēt konta sadaļu',
					ee: 'Muuda konto sektsiooni',
				},
			},
			sectionDescription: {
				en: 'Section description',
				de: 'Bereichsbeschreibung',
				it: 'Descrizione della sezione',
				fr: 'Description de la section',
				ru: 'Описание раздела',
				ua: 'Опис розділу',
				pl: 'Opis sekcji',
				md: 'Descrierea secțiunii',
				lt: 'Skyriaus aprašymas',
				lv: 'Sadaļas apraksts',
				ee: 'Sektsiooni kirjeldus',
			},
			accountSectionDescriptionIn: {
				en: 'Account section description in',
				de: 'Kontenbereichsbeschreibung in',
				it: 'Descrizione della sezione del conto in',
				fr: 'Description de la section de compte en',
				ru: 'Описание раздела счета на',
				ua: 'Опис розділу рахунку на',
				pl: 'Opis sekcji konta w',
				md: 'Descrierea secțiunii de cont în',
				lt: 'Sąskaitos skyriaus aprašymas',
				lv: 'Konta sadaļas apraksts',
				ee: 'Konto sektsiooni kirjeldus',
			},
		},
	},

	accountGroupsPage: {
		pageTitle: {
			en: 'Account Groups',
			de: 'Kontengruppen',
			it: 'Gruppi di conti',
			fr: 'Groupes de comptes',
			ru: 'Группы счетов',
			ua: 'Групи рахунків',
			pl: 'Grupy kont',
			md: 'Grupuri de conturi',
			lt: 'Sąskaitų grupės',
			lv: 'Konta grupas',
			ee: 'Konto grupid',
		},
		addNewButton: {
			en: 'Add new group',
			de: 'Neue Gruppe hinzufügen',
			it: 'Aggiungi nuovo gruppo',
			fr: 'Ajouter un nouveau groupe',
			ru: 'Добавить новую группу',
			ua: 'Додати нову групу',
			pl: 'Dodaj nową grupę',
			md: 'Adăugați un nou grup',
			lt: 'Pridėti naują grupę',
			lv: 'Pievienot jaunu grupu',
			ee: 'Lisa uus grupp',
		},
		modal: {
			title: {
				addNew: {
					en: 'New group number',
					de: 'Neue Gruppennummer',
					it: 'Nuovo numero di gruppo',
					fr: 'Nouveau numéro de groupe',
					ru: 'Новый номер группы',
					ua: 'Новий номер групи',
					pl: 'Nowy numer grupy',
					md: 'Numărul grupului nou',
					lt: 'Naujas grupės numeris',
					lv: 'Jauna grupas numurs',
					ee: 'Uus grupi number',
				},
				edit: {
					en: 'Edit group number',
					de: 'Gruppennummer bearbeiten',
					it: 'Modifica numero di gruppo',
					fr: 'Modifier le numéro de groupe',
					ru: 'Редактировать номер группы',
					ua: 'Редагувати номер групи',
					pl: 'Edytuj numer grupy',
					md: 'Editați numărul grupului',
					lt: 'Redaguoti grupės numerį',
					lv: 'Rediģēt grupas numuru',
					ee: 'Muuda grupi number',
				},
			},
			selectAccountSectionText: {
				en: 'Select account section',
				de: 'Kontenbereich auswählen',
				it: 'Seleziona la sezione del conto',
				fr: 'Sélectionner la section de compte',
				ru: 'Выберите раздел счета',
				ua: 'Виберіть розділ рахунку',
				pl: 'Wybierz sekcję konta',
				md: 'Selectați secțiunea de cont',
				lt: 'Pasirinkite sąskaitos skyrių',
				lv: 'Izvēlieties konta sadaļu',
				ee: 'Vali konto sektsioon',
			},
			groupName: {
				en: 'Group name',
				de: 'Gruppenname',
				it: 'Nome del gruppo',
				fr: 'Nom du groupe',
				ru: 'Название группы',
				ua: 'Назва групи',
				pl: 'Nazwa grupy',
				md: 'Numele grupului',
				lt: 'Grupės pavadinimas',
				lv: 'Grupas nosaukums',
				ee: 'Grupi nimi',
			},
		},
	},

	AccountNumberPage: {
		pageTitle: {
			en: 'Accounts plan',
			de: 'Kontenplan',
			it: 'Piano dei conti',
			fr: 'Plan de comptes',
			ru: 'План счетов',
			ua: 'План рахунків',
			pl: 'Plan kont',
			md: 'Planul de conturi',
			lt: 'Sąskaitų planas',
			lv: 'Konta plāns',
			ee: 'Konto plaan',
		},

		addNewButton: {
			en: 'Add new account',
			de: 'Neues Konto hinzufügen',
			it: 'Aggiungi nuovo conto',
			fr: 'Ajouter un nouveau compte',
			ru: 'Добавить новый счет',
			ua: 'Додати новий рахунок',
			pl: 'Dodaj nowe konto',
			md: 'Adăugați un cont nou',
			lt: 'Pridėti naują sąskaitą',
			lv: 'Pievienot jaunu kontu',
			ee: 'Lisa uus konto',
		},
		modal: {
			title: {
				en: 'Add new account number',
				de: 'Neue Kontonummer hinzufügen',
				it: 'Aggiungi nuovo numero di conto',
				fr: 'Ajouter un nouveau numéro de compte',
				ru: 'Добавить новый номер счета',
				ua: 'Додати новий номер рахунку',
				pl: 'Dodaj nowy numer konta',
				md: 'Adăugați un număr de cont nou',
				lt: 'Pridėti naują sąskaitos numerį',
				lv: 'Pievienot jaunu konta numuru',
				ee: 'Lisa uus konto number',
			},
			accountDescriptionIn: {
				en: 'Account description in',
				de: 'Kontobeschreibung in',
				it: 'Descrizione del conto in',
				fr: 'Description du compte en',
				ru: 'Описание счета на',
				ua: 'Опис рахунку на',
				pl: 'Opis konta w',
				md: 'Descrierea contului în',
				lt: 'Sąskaitos aprašymas',
				lv: 'Konta apraksts',
				ee: 'Konto kirjeldus',
			},
		},
	},

	loginPage: {
		emailLabel: {
			en: 'Email',
			de: 'Email',
			it: 'Email',
			fr: 'Email',
			ru: 'Электронная почта',
			ua: 'Пошта',
			pl: 'Email',
			md: 'Email',
			lt: 'Email',
			lv: 'Email',
			ee: 'Email',
		},
		passwordLabel: {
			en: 'Password',
			de: 'Passwort',
			it: 'Password',
			fr: 'Mot de passe',
			ru: 'Пароль',
			ua: 'Пароль',
			pl: 'Hasło',
			md: 'Parolă',
			lt: 'Slaptažodis',
			lv: 'Parole',
			ee: 'Parool',
		},
		languageLabel: {
			en: 'Language',
			de: 'Sprache',
			it: 'Lingua',
			fr: 'Langue',
			ru: 'Язык',
			ua: 'Мова',
			pl: 'Język',
			md: 'Limba',
			lt: 'Kalba',
			lv: 'Valoda',
			ee: 'Keel',
		},
		loginButton: {
			en: 'Login',
			de: 'Anmelden',
			it: 'Login',
			fr: 'Se connecter',
			ru: 'Вход',
			ua: 'Увійти',
			pl: 'Zaloguj się',
			md: 'Autentificare',
			lt: 'Prisijungti',
			lv: 'Pieslēgties',
			ee: 'Logi sisse',
		},
		createdByLabel: {
			en: 'Created by',
			de: 'Erstellt von',
			it: 'Creato da',
			fr: 'Créé par',
			ru: 'Создано',
			ua: 'Створено',
			pl: 'Stworzone przez',
			md: 'Creat de',
			lt: 'Sukurta',
			lv: 'Izveidoja',
			ee: 'Loodud',
		},
		createdBy: {
			en: 'ARVUT company',
			de: 'ARVUT Unternehmen',
			it: 'Compagnia ARVUT',
			fr: 'Entreprise ARVUT',
			ru: 'Компания ARVUT',
			ua: 'Компанія ARVUT',
			pl: 'Firma ARVUT',
			md: 'Compania ARVUT',
			lt: 'Įmonė ARVUT',
			lv: 'Uzņēmums ARVUT',
			ee: 'Ettevõte ARVUT',
		},
	},

	appTabsNavigator: {
		creditor: {
			en: 'Creditor',
			de: 'Gläubiger',
			it: 'Creditore',
			fr: 'Créancier',
			ru: 'Кредиторы',
			ua: 'Кредітори',
			pl: 'Wierzyciel',
			md: 'Creditor',
			lt: 'Kreditorius',
			lv: 'Kredītdevējs',
			ee: 'Võlausaldaja',
		},
		debtor: {
			en: 'Debtor',
			de: 'Schuldner',
			it: 'Debitore',
			fr: 'Débiteur',
			ru: 'Дебиторы',
			ua: 'Дебітори',
			pl: 'Dłużnik',
			md: 'Debitor',
			lt: 'Skolininkas',
			lv: 'Parādnieks',
			ee: 'Võlgnik',
		},
		journalEntries: {
			en: 'Journal entries',
			de: 'Buchungen',
			it: 'Voci di diario',
			fr: 'Écritures',
			ru: 'Журнал операций',
			ua: 'Журнал операцій',
			pl: 'Operacje księgowe',
			md: 'Înregistrări contabile',
			lt: 'Įrašai',
			lv: 'Ieraksti',
			ee: 'Kanded',
		},
		accountMovements: {
			en: 'Account movements',
			de: 'Bewegungen',
			it: 'Movimenti contabili',
			fr: 'Mouvements de compte',
			ru: 'Движения по счетам',
			ua: 'Рух коштів',
			pl: 'Ruch konta',
			md: 'Mișcările contului',
			lt: 'Sąskaitos judėjimas',
			lv: 'Konta kustības',
			ee: 'Konto liikumised',
		},
		vat: {
			title: {
				en: 'VAT',
				de: 'MwSt.',
				it: 'IVA',
				fr: 'TVA',
				ru: 'НДС',
				ua: 'ПДВ',
				pl: 'VAT',
				md: 'TVA',
				lt: 'PVM',
				lv: 'PVN',
				ee: 'KMK',
			},
			payed: {
				en: 'VAT Payed',
				de: 'MwSt. bezahlt',
				it: 'IVA pagato',
				fr: 'TVA payé',
				ru: 'НДС оплачено',
				ua: 'ПДВ сплачено',
				pl: 'VAT zapłacone',
				md: 'TVA plătit',
				lt: 'Apmokėtas PVM',
				lv: 'Apmaksāta PVN',
				ee: 'Makstud KMK',
			},
			payable: {
				en: 'VAT Payable',
				de: 'MwSt. zu zahlen',
				it: 'IVA da pagare',
				fr: 'TVA à payer',
				ru: 'НДС к оплате',
				ua: 'ПДВ до сплати',
				pl: 'VAT do zapłaty',
				md: 'TVA de plată',
				lt: 'Mokamas PVM',
				lv: 'Jāmaksā PVN',
				ee: 'Makstav KMK',
			},
		},
		
		balance: {
			title: {
				en: 'Balance',
				de: 'Bilanz',
				it: 'Bilancio',
				fr: 'Solde',
				ru: 'Баланс',
				ua: 'Баланс',
				pl: 'Bilans',
				md: 'Balans',
				lt: 'Balansas',
				lv: 'Bilance',
				ee: 'Bilanss',
			},
			assetLiabilityReport: {
				en: 'Asset-Liability report',
				de: 'Asset-Liability-Bericht',
				it: 'Rapporto attività-responsabilità',
				fr: 'Rapport actif-passif',
				ru: 'Отчет об активах и пассивах',
				ua: 'Звіт про активи та пасиви',
				pl: 'Raport aktywów i pasywów',
				md: 'Raportul activelor și pasivelor',
				lt: 'Turtas-Išlaidų ataskaita',
				lv: 'Aktīvu un parādu pārskats',
				ee: 'Varade-passiva aruanne',	
			},
			profitLostReport: {
				en: 'Profit-Lost report',
				de: 'Gewinn- und Verlustbericht',
				it: 'Rapporto di profitto e perdita',
				fr: 'Rapport de profit et de perte',
				ru: 'Отчет о прибылях и убытках',
				ua: 'Звіт про прибутки та збитки',
				pl: 'Raport zysków i strat',
				md: 'Raportul de profit și pierdere',
				lt: 'Pelnas-praradimo ataskaita',
				lv: 'Peļņas zaudējumu pārskats',
				ee: 'Kasumi-Kahjumi aruanne',
			},
			annualReport: {
				en: 'Annual report',
				de: 'Jahresbericht',
				it: 'Rapporto annuale',
				fr: 'Rapport annuel',
				ru: 'Годовой отчет',
				ua: 'Річний звіт',
				pl: 'Raport roczny',
				md: 'Raport anual',
				lt: 'Metinė ataskaita',
				lv: 'Gada pārskats',
				ee: 'Aastaaruanne',
			},
		},
		
		accountsPlan: {
			title: {
				en: 'Accounts plan',
				de: 'Kontenplan',
				it: 'Piano dei conti',
				fr: 'Plan de comptes',
				ru: 'План счетов',
				ua: 'План рахунків',
				pl: 'Plan kont',
				md: 'Planul de conturi',
				lt: 'Sąskaitų planas',
				lv: 'Kontu plāns',
				ee: 'Kontode plaan',
			},
			accountSections: {
				en: 'Account sections',
				de: 'Kontenabschnitte',
				it: 'Sezioni del conto',
				fr: 'Sections de compte',
				ru: 'Разделы счета',
				ua: 'Розділи рахунку',
				pl: 'Sekcje konta',
				md: 'Secțiuni de cont',
				lt: 'Sąskaitos skyriai',
				lv: 'Konta sadaļas',
				ee: 'Konto osad',
			},
			accountGroups: {
				en: 'Account groups',
				de: 'Kontengruppen',
				it: 'Gruppi di conti',
				fr: 'Groupes de comptes',
				ru: 'Группы счетов',
				ua: 'Групи рахунків',
				pl: 'Grupy kont',
				md: 'Grupuri de conturi',
				lt: 'Sąskaitų grupės',
				lv: 'Kontu grupas',
				ee: 'Konto grupid',
			},
			accountNumbers: {
				en: 'Account numbers',
				de: 'Kontonummern',
				it: 'Numeri di conto',
				fr: 'Numéros de compte',
				ru: 'Номера счетов',
				ua: 'Номери рахунків',
				pl: 'Numery kont',
				md: 'Numere de cont',
				lt: 'Sąskaitų numeriai',
				lv: 'Kontu numuri',
				ee: 'Konto numbrid',
			},
			uploadNewAccountsPlan: {
				en: 'Upload new accounts plan',
				de: 'Neuen Kontenplan hochladen',
				it: 'Carica nuovo piano dei conti',
				fr: 'Télécharger un nouveau plan de comptes',
				ru: 'Загрузить новый план счетов',
				ua: 'Завантажити новий план рахунків',
				pl: 'Prześlij nowy plan kont',
				md: 'Încărcați un nou plan de conturi',
				lt: 'Įkelti naują sąskaitų planą',
				lv: 'Augšupielādēt jaunu kontu plānu',
				ee: 'Laadi üles uus kontode plaan',
			},
			downloadAccountsPlan: {
				en: 'Download accounts plan',
				de: 'Kontenplan herunterladen',
				it: 'Scarica il piano dei conti',
				fr: 'Télécharger le plan de comptes',
				ru: 'Скачать план счетов',
				ua: 'Завантажити план рахунків',
				pl: 'Pobierz plan kont',
				md: 'Descărcați planul de conturi',
				lt: 'Atsisiųsti sąskaitų planą',
				lv: 'Lejupielādēt kontu plānu',
				ee: 'Laadi alla kontode plaan',
			},
		},
	},

	PageTitle: {
		uploadCsvFile: {
			en: 'Upload CSV file',
			de: 'CSV-Datei hochladen',
			it: 'Carica file CSV',
			fr: 'Télécharger un fichier CSV',
			ru: 'Загрузить CSV файл',
			ua: 'Завантажити файл CSV',
			pl: 'Prześlij plik CSV',
			md: 'Încărcați fișierul CSV',
			lt: 'Įkelti CSV failą',
			lv: 'Augšupielādēt CSV failu',
			ee: 'Laadi üles CSV fail',
		},
		zipOrPdfs: {
			en: 'Zip or pdfs',
			de: 'Zip oder pdfs',
			it: 'Zip o pdf',
			fr: 'Zip ou pdf',
			ru: 'Zip или pdf',
			ua: 'Zip або pdf',
			pl: 'Zip lub pdf',
			md: 'Zip sau pdf',
			lt: 'Zip arba pdf',
			lv: 'Zip vai pdf',
			ee: 'Zip või pdf',
		},
		export: {
			en: 'Export',
			de: 'Export',
			it: 'Esporta',
			fr: 'Exporter',
			ru: 'Экспортировать',
			ua: 'Експортувати',
			pl: 'Eksport',
			md: 'Export',
			lt: 'Eksportuoti',
			lv: 'Eksportēt',
			ee: 'Eksport',
		},
	},

	counterpartyPage: {
		debtorTitleLabel: {
			en: 'Debtor counterparties',
			de: 'Schuldner-Gegenparteien',
			it: 'Debitori controparti',
			fr: 'Débiteurs contreparties',
			ru: 'Дебиторы контрагенты',
			ua: 'Дебітори контрагенти',
			pl: 'Dłużnicy kontrahenci',
			md: 'Debitori contrapărți',
			lt: 'Skolininkai sandorio šalys',
			lv: 'Parādnieki darījuma partneri',
			ee: 'Võlgnike tehingupartnerid',
		},

		creditorTitleLabel: {
			en: 'Creditor counterparties',
			de: 'Gläubiger-Gegenparteien',
			it: 'Creditori controparti',
			fr: 'Créanciers contreparties',
			ru: 'Кредиторы контрагенты',
			ua: 'Кредитори контрагенти',
			pl: 'Wierzyciele kontrahenci',
			md: 'Creditori contrapărți',
			lt: 'Kreditorių sandorio šalys',
			lv: 'Kreditori darījuma partneri',
			ee: 'Võlausaldajate tehingupartnerid',
		},
		addNewButton: {
			en: 'Add new counterparty',
			de: 'Neuen Gläubiger hinzufügen',
			it: 'Aggiungi nuovo creditore',
			fr: 'Ajouter un nouveau créancier',
			ru: 'Добавить нового кредитора',
			ua: 'Додати нового кредитора',
			pl: 'Dodaj nowego wierzyciela',
			md: 'Adăugați un nou creditor',
			lt: 'Pridėti naują kreditorių',
			lv: 'Pievienot jaunu kreditoru',
			ee: 'Lisa uus võlausaldaja',
		},
		name: {
			en: 'Counterparty name',
			de: 'Name des Gläubigers',
			it: 'Nome del creditore',
			fr: 'Nom du créancier',
			ru: 'Название контрагента',
			ua: 'Назва контрагента',
			pl: 'Nazwa kontrahenta',
			md: 'Numele contrapărții',
			lt: 'Kreditoriaus pavadinimas',
			lv: 'Darījuma partnera nosaukums',
			ee: 'Tehingupartneri nimi',
		},
		totalInvoices: {
			en: 'Total invoices',
			de: 'Gesamtrechnungen',
			it: 'Fatture totali',
			fr: 'Factures totales',
			ru: 'Всего счетов',
			ua: 'Всього рахунків',
			pl: 'Wszystkie faktury',
			md: 'Total facturi',
			lt: 'Visi sąskaitos',
			lv: 'Visas rēķini',
			ee: 'Kõik arved',
		},
		totalInvoiceAmount: {
			en: 'Total invoice amount',
			de: 'Gesamtbetrag der Rechnung',
			it: 'Importo totale della fattura',
			fr: 'Montant total de la facture',
			ru: 'Общая сумма счета',
			ua: 'Загальна сума рахунку',
			pl: 'Całkowita kwota faktury',
			md: 'Totalul facturii',
			lt: 'Viso sąskaitos suma',
			lv: 'Kopējā rēķina summa',
			ee: 'Kogu arve summa',
		},
		modal: {
			title: {
				en: 'New counterparty',
				de: 'Neuer Gläubiger',
				it: 'Nuovo creditore',
				fr: 'Nouveau créancier',
				ru: 'Новый контрагент',
				ua: 'Новий контрагент',
				pl: 'Nowy wierzyciel',
				md: 'Nou contraparte',
				lt: 'Naujas kreditorius',
				lv: 'Jauns darījuma partneris',
				ee: 'Uus tehingupartner',
			},			
			description: {
				en: 'Counterparty description',
				de: 'Beschreibung des Gläubigers',
				it: 'Descrizione del creditore',
				fr: 'Description du créancier',
				ru: 'Описание контрагента',
				ua: 'Опис контрагента',
				pl: 'Opis kontrahenta',
				md: 'Descrierea contrapărții',
				lt: 'Kreditoriaus aprašymas',
				lv: 'Darījuma partnera apraksts',
				ee: 'Tehingupartneri kirjeldus',
			},
			saveButton: {
				en: 'Save new counterparty',
				de: 'Neuen Gläubiger speichern',
				it: 'Salva nuovo creditore',
				fr: 'Enregistrer le nouveau créancier',
				ru: 'Сохранить нового контрагента',
				ua: 'Зберегти нового контрагента',
				pl: 'Zapisz nowego wierzyciela',
				md: 'Salvați noua contraparte',
				lt: 'Išsaugoti naują kreditorių',
				lv: 'Saglabāt jaunu darījuma partneri',
				ee: 'Salvesta uus tehingupartner',
			},
		},		
	},

	journalEntriesPage: {
		titleLabel: {
			en: 'Journal entries',
			de: 'Buchungen',
			it: 'Voci di diario',
			fr: 'Écritures',
			ru: 'Журнал операций',
			ua: 'Журнал операцій',
			pl: 'Operacje księgowe',
			md: 'Înregistrări contabile',
			lt: 'Įrašai',
			lv: 'Ieraksti',
			ee: 'Kanded',
		},
		addNewButton: {
			en: 'Add new entry',
			de: 'Neue Buchung hinzufügen',
			it: 'Aggiungi nuova voce',
			fr: 'Ajouter une nouvelle écriture',
			ru: 'Добавить новую операцию',
			ua: 'Додати нову операцію',
			pl: 'Dodaj nową operację',
			md: 'Adăugați o nouă operațiune',
			lt: 'Pridėti naują įrašą',
			lv: 'Pievienot jaunu ierakstu',
			ee: 'Lisa uus kanne',
		},
		filter: {
			entryDescription: {
				en: 'Entry description',
				de: 'Buchungsbeschreibung',
				it: 'Descrizione della voce',
				fr: 'Description de l\'entrée',
				ru: 'Описание операции',
				ua: 'Опис операції',
				pl: 'Opis operacji',
				md: 'Descrierea operațiunii',
				lt: 'Įrašo aprašymas',
				lv: 'Ieraksta apraksts',
				ee: 'Kande kirjeldus',
			},
		},
		// included in modal and details pages
		journalEntryType: {
			en: 'Journal entry type',
			de: 'Buchungstyp',
			it: 'Tipo di voce',
			fr: 'Type d\'entrée',
			ru: 'Тип журнальной операции',
			ua: 'Тип журнальної операції',
			pl: 'Typ wpisu',
			md: 'Tipul de intrare',
			lt: 'Įrašo tipas',
			lv: 'Ieraksta tips',
			ee: 'Kande tüüp',
		},
		modal: {
			title: {
				addNew: {
					en: 'Add new journal entry',
					de: 'Neue Buchung hinzufügen',
					it: 'Aggiungi nuova voce di diario',
					fr: 'Ajouter une nouvelle écriture',
					ru: 'Добавить новую операцию',
					ua: 'Додати нову операцію',
					pl: 'Dodaj nową operację',
					md: 'Adăugați o nouă operațiune',
					lt: 'Pridėti naują įrašą',
					lv: 'Pievienot jaunu ierakstu',
					ee: 'Lisa uus kanne',
				},
				edit: {
					en: 'Edit journal entry',
					de: 'Buchung bearbeiten',
					it: 'Modifica voce di diario',
					fr: 'Modifier l\'écriture',
					ru: 'Редактировать операцию',
					ua: 'Редагувати операцію',
					pl: 'Edytuj operację',
					md: 'Editați operațiunea',
					lt: 'Redaguoti įrašą',
					lv: 'Rediģēt ierakstu',
					ee: 'Muuda kande',
				},
			},
		},
		comments: {
			entityVersion: {
				en: 'Entity version',
				de: 'Entität Version',
				it: 'Versione entità',
				fr: 'Version de l\'entité',
				ru: 'Версия операции',
				ua: 'Версія операції',
				pl: 'Wersja operacji',
				md: 'Versiunea operațiunii',
				lt: 'Įrašo versija',
				lv: 'Ieraksta versija',
				ee: 'Kande versioon',		
			},
			addEntryComment: {
				en: 'Add entry comment',
				de: 'Buchungskommentar hinzufügen',
				it: 'Aggiungi commento voce',
				fr: 'Ajouter un commentaire d\'entrée',
				ru: 'Добавить комментарий к операции',
				ua: 'Додати коментар до операції',
				pl: 'Dodaj komentarz do operacji',
				md: 'Adăugați un comentariu la operațiune',
				lt: 'Pridėti įrašo komentarą',
				lv: 'Pievienot ieraksta komentāru',
				ee: 'Lisa kande kommentaar',
			},
		},
	},

	operationsPage: {
		invoiceDate: {
			en: 'Invoice date',
			de: 'Rechnungsdatum',
			it: 'Data della fattura',
			fr: 'Date de la facture',
			ru: 'Дата счета',
			ua: 'Дата рахунку',
			pl: 'Data faktury',
			md: 'Data facturii',
			lt: 'Sąskaitos data',
			lv: 'Rēķina datums',
			ee: 'Arve kuupäev',
		},
		
		addNewButton: {
			en: 'Add new operation',
			de: 'Neue Operation hinzufügen',
			it: 'Aggiungi nuova operazione',
			fr: 'Ajouter une nouvelle opération',
			ru: 'Добавить новую операцию',
			ua: 'Додати нову операцію',
			pl: 'Dodaj nową operację',
			md: 'Adăugați o nouă operațiune',
			lt: 'Pridėti naują operaciją',
			lv: 'Pievienot jaunu darījumu',
			ee: 'Lisa uus tehing',
		},

		// handleCopySuccess: {
		// 	en: 'Operation link copied to clipboard',
		// 	de: 'Operation Link in die Zwischenablage kopiert',
		// 	it: 'Link operazione copiato negli appunti',
		// 	fr: 'Lien de l\'opération copié dans le presse-papiers',
		// 	ru: 'Ссылка на операцию скопирована в буфер обмена',
		// 	ua: 'Посилання на операцію скопійовано в буфер обміну',
		// 	pl: 'Link do operacji skopiowany do schowka',
		// 	md: 'Linkul operației a fost copiat în clipboard',
		// 	lt: 'Operacijos nuoroda nukopijuota į iškarpinę',
		// 	lv: 'Operācijas saite nokopēts starpliktuvē',
		// 	ee: 'Tehingu link kopeeritud lõikelauale',
		// },
		entryTable: {
			entryType: {
				en: 'Entry type',
				de: 'Buchungstyp',
				it: 'Tipo di voce',
				fr: 'Type d\'entrée',
				ru: 'Тип операции',
				ua: 'Тип операції',
				pl: 'Typ wpisu',
				md: 'Tipul de intrare',
				lt: 'Įrašo tipas',
				lv: 'Ieraksta tips',
				ee: 'Kande tüüp',
			},
			addPaymentDocument: {
				title: {
					en: 'Add payment document',
					de: 'Zahlungsdokument hinzufügen',
					it: 'Aggiungi documento di pagamento',
					fr: 'Ajouter un document de paiement',
					ru: 'Добавить платежный документ',
					ua: 'Додати платіжний документ',
					pl: 'Dodaj dokument płatności',
					md: 'Adăugați documentul de plată',
					lt: 'Pridėti mokėjimo dokumentą',
					lv: 'Pievienot maksājuma dokumentu',
					ee: 'Lisa makse dokument',
				},
				payableInvoice: {
					en: 'Add payable invoice (PI)',
					de: 'Zu zahlende Rechnung hinzufügen (PI)',
					it: 'Aggiungi fattura da pagare (PI)',
					fr: 'Ajouter une facture à payer (PI)',
					ru: 'Добавить к оплате счет (PI)',
					ua: 'Додати до сплати рахунок (PI)',
					pl: 'Dodaj fakturę do zapłaty (PI)',
					md: 'Adăugați o factură de plată (PI)',
					lt: 'Pridėti apmokėtiną sąskaitą (PI)',
					lv: 'Pievienot maksājuma rēķinu (PI)',
					ee: 'Lisa makstav arve (PI)',				
				},
				paymentOfPayableInvoice: {
					title: {
						en: 'Add payment of payable invoice (PPI)',
						de: 'Zahlung einer zu zahlenden Rechnung hinzufügen (PPI)',
						it: 'Aggiungi pagamento di fattura da pagare (PPI)',
						fr: 'Ajouter le paiement d\'une facture à payer (PPI)',
						ru: 'Добавить оплату к оплате счета (PPI)',
						ua: 'Додати оплату до сплати рахунку (PPI)',
						pl: 'Dodaj płatność za fakturę do zapłaty (PPI)',
						md: 'Adăugați plata facturii de plată (PPI)',
						lt: 'Pridėti apmokėjimą už apmokėtiną sąskaitą (PPI)',
						lv: 'Pievienot maksājumu par maksājuma rēķinu (PPI)',
						ee: 'Lisa makstava arve makse (PPI)',
					},
					ppiForLabel: {
						en: 'PPI for',
						de: 'PPI für',
						it: 'PPI per',
						fr: 'PPI pour',
						ru: 'PPI для',
						ua: 'PPI для',
						pl: 'PPI dla',
						md: 'PPI pentru',
						lt: 'PPI už',
						lv: 'PPI par',
						ee: 'PPI jaoks',
					},
				},
				bankChargesOnPayableInvoice: {
					title: {
						en: 'Add bank charges on payable invoice (BCPI)',
						de: 'Bankgebühren auf zu zahlender Rechnung hinzufügen (BCPI)',
						it: 'Aggiungi spese bancarie sulla fattura da pagare (BCPI)',
						fr: 'Ajouter des frais bancaires sur la facture à payer (BCPI)',
						ru: 'Добавить банковские комиссии на оплату счета (BCPI)',
						ua: 'Додати банківські комісії на сплату рахунку (BCPI)',
						pl: 'Dodaj opłaty bankowe do zapłaty rachunku (BCPI)',
						md: 'Adăugați comisioane bancare pe factura de plată (BCPI)',
						lt: 'Pridėti banko mokesčius už apmokėtiną sąskaitą (BCPI)',
						lv: 'Pievienot bankas izmaksas par maksājuma rēķinu (BCPI)',
						ee: 'Lisa panga tasud makstava arve (BCPI)',
					},
					pibsForLabel: {
						en: 'PIBS for',
						de: 'PIBS für',
						it: 'PIBS per',
						fr: 'PIBS pour',
						ru: 'PIBS для',
						ua: 'PIBS для',
						pl: 'PIBS dla',
						md: 'PIBS pentru',
						lt: 'PIBS už',
						lv: 'PIBS par',
						ee: 'PIBS jaoks',
					},
				},
				receivableInvoice: {
					en: 'Add receivable invoice (RI)',
					de: 'Forderungsrechnung hinzufügen (RI)',
					it: 'Aggiungi fattura da incassare (RI)',
					fr: 'Ajouter une facture à recevoir (RI)',
					ru: 'Добавить к получению счет (RI)',
					ua: 'Додати до отримання рахунок (RI)',
					pl: 'Dodaj fakturę do otrzymania (RI)',
					md: 'Adăugați o factură de încasat (RI)',
					lt: 'Pridėti gautiną sąskaitą (RI)',
					lv: 'Pievienot saņemamo rēķinu (RI)',
					ee: 'Lisa saadav arve (RI)',
				},
				paymentOfReceivableInvoice: {
					title: {
						en: 'Add payment of receivable invoice (PRI)',
						de: 'Zahlung einer zu erhaltenden Rechnung hinzufügen (PRI)',
						it: 'Aggiungi pagamento di fattura da incassare (PRI)',
						fr: 'Ajouter le paiement d\'une facture à recevoir (PRI)',
						ru: 'Добавить оплату к получению счета (PRI)',
						ua: 'Додати оплату до отримання рахунку (PRI)',
						pl: 'Dodaj płatność za fakturę do otrzymania (PRI)',
						md: 'Adăugați plata facturii de încasat (PRI)',
						lt: 'Pridėti apmokėjimą už gautiną sąskaitą (PRI)',
						lv: 'Pievienot maksājumu par saņemamo rēķinu (PRI)',
						ee: 'Lisa saadava arve makse (PRI)',
					},
					// ???
					ppiForLabel: {
						en: 'PPI for',
						de: 'PPI für',
						it: 'PPI per',
						fr: 'PPI pour',
						ru: 'PPI для',
						ua: 'PPI для',
						pl: 'PPI dla',
						md: 'PPI pentru',
						lt: 'PPI už',
						lv: 'PPI par',
						ee: 'PPI jaoks',
					}
				},
				bankChargesOnReceivableInvoice: {
					title: {
						en: 'Add Bank Charges on Receivable Invoice (RIBC)',
						de: 'Bankgebühren auf zu erhaltender Rechnung hinzufügen (RIBC)',
						it: 'Aggiungi spese bancarie sulla fattura da incassare (RIBC)',
						fr: 'Ajouter des frais bancaires sur la facture à recevoir (RIBC)',
						ru: 'Добавить банковские комиссии на получение счета (RIBC)',
						ua: 'Додати банківські комісії на отримання рахунку (RIBC)',
						pl: 'Dodaj opłaty bankowe do otrzymania rachunku (RIBC)',
						md: 'Adăugați comisioane bancare pe factura de încasat (RIBC)',
						lt: 'Pridėti banko mokesčius už gautiną sąskaitą (RIBC)',
						lv: 'Pievienot bankas izmaksas par saņemamo rēķinu (RIBC)',
						ee: 'Lisa panga tasud saadava arve (RIBC)',
					},
					// ???
					pibsForLabel: {
						en: 'PIBS for',
						de: 'PIBS für',
						it: 'PIBS per',
						fr: 'PIBS pour',
						ru: 'PIBS для',
						ua: 'PIBS для',
						pl: 'PIBS dla',
						md: 'PIBS pentru',
						lt: 'PIBS už',
						lv: 'PIBS par',
						ee: 'PIBS jaoks',
					},
				},
			},
		},
		modal: {
			title: {
				en: 'New operation',
				de: 'Neue Operation',
				it: 'Nuova operazione',
				fr: 'Nouvelle opération',
				ru: 'Новая операция',
				ua: 'Нова операція',
				pl: 'Nowa operacja',
				md: 'Operațiune nouă',
				lt: 'Naujas veiksmas',
				lv: 'Jauna darbība',
				ee: 'Uus tehing',
			},
			invoiceNumber: {
				en: 'Invoice number',
				de: 'Rechnungsnummer',
				it: 'Numero della fattura',
				fr: 'Numéro de facture',
				ru: 'Номер счета',
				ua: 'Номер рахунку',
				pl: 'Numer faktury',
				md: 'Numărul facturii',
				lt: 'Sąskaitos numeris',
				lv: 'Rēķina numurs',
				ee: 'Arve number',
			},
			remove: {
				en: 'Remove',
				de: 'Entfernen',
				it: 'Rimuovere',
				fr: 'Supprimer',
				ru: 'Удалить',
				ua: 'Видалити',
				pl: 'Usuń',
				md: 'Elimina',
				lt: 'Pašalinti',
				lv: 'Noņemt',
				ee: 'Eemalda',
			},
			saveInvoice: {
				en: 'Save invoice',
				de: 'Rechnung speichern',
				it: 'Salva fattura',
				fr: 'Enregistrer la facture',
				ru: 'Сохранить счет',
				ua: 'Зберегти рахунок',
				pl: 'Zapisz fakturę',
				md: 'Salvați factura',
				lt: 'Išsaugoti sąskaitą',
				lv: 'Saglabāt rēķinu',
				ee: 'Salvesta arve',
			},
		},
		comments: {
			addOperationComment: {
				en: 'Add operation comment',
				de: 'Operation Kommentar hinzufügen',
				it: 'Aggiungi commento operazione',
				fr: 'Ajouter un commentaire à l\'opération',
				ru: 'Добавить комментарий к операции',
				ua: 'Додати коментар до операції',
				pl: 'Dodaj komentarz do operacji',
				md: 'Adăugați comentariu la operațiune',
				lt: 'Pridėti operacijos komentarą',
				lv: 'Pievienot darbības komentāru',
				ee: 'Lisa tehingu kommentaar',
			},
		},

		filter: {
			invoiceDate: {
				en: 'Invoice date',
				de: 'Rechnungsdatum',
				it: 'Data della fattura',
				fr: 'Date de la facture',
				ru: 'Дата счета',
				ua: 'Дата рахунку',
				pl: 'Data faktury',
				md: 'Data facturii',
				lt: 'Sąskaitos data',
				lv: 'Rēķina datums',
				ee: 'Arve kuupäev',
			},
			creditor: {
				en: 'Creditor',
				de: 'Gläubiger',
				it: 'Creditore',
				fr: 'Créancier',
				ru: 'Кредитор',
				ua: 'Кредитор',
				pl: 'Wierzyciel',
				md: 'Creditor',
				lt: 'Kreditorius',
				lv: 'Kredītdevējs',
				ee: 'Võlausaldaja',
			},			
			description: {
				en: 'Description',
				de: 'Beschreibung',
				it: 'Descrizione',
				fr: 'Description',
				ru: 'Описание',
				ua: 'Опис',
				pl: 'Opis',
				md: 'Descriere',
				lt: 'Aprašymas',
				lv: 'Apraksts',
				ee: 'Kirjeldus',
			},
			currencyRate: {
				en: 'Currency rate',
				de: 'Wechselkurs',
				it: 'Tasso di cambio',
				fr: 'Taux de change',
				ru: 'Курс валюты',
				ua: 'Курс валюти',
				pl: 'Kurs waluty',
				md: 'Curs valutar',
				lt: 'Valiutos kursas',
				lv: 'Valūtas kurss',
				ee: 'Valuutakurss',
			},
			payment: {
				en: 'Payment',
				de: 'Zahlung',
				it: 'Pagamento',
				fr: 'Paiement',
				ru: 'Платеж',
				ua: 'Платіж',
				pl: 'Płatność',
				md: 'Plată',
				lt: 'Mokėjimas',
				lv: 'Maksājums',
				ee: 'Makse',
			},

		},
		entryModal: {
			title: {
				/// ???
				en: 'Operation entry',
				de: 'Operationseintrag',
				it: 'Voce operazione',
				fr: 'Entrée opération',
				ru: 'Запись операции',
				ua: 'Запис операції',
				pl: 'Wpis operacji',
				md: 'Intrare operațiune',
				lt: 'Operacijos įrašas',
				lv: 'Darbības ieraksts',
				ee: 'Tehingu kirje',
			},
		},
	},
	accountMovementsPage: {
		titleLabel: {
			en: 'Account movements',
			de: 'Kontobewegungen',
			it: 'Movimenti contabili',
			fr: 'Mouvements de compte',
			ru: 'Движения по счетам',
			ua: 'Рух по рахункам',
			pl: 'Ruch konta',
			md: 'Mișcări de cont',
			lt: 'Sąskaitos judesiai',
			lv: 'Konta kustības',
			ee: 'Konto liikumised',
		},
		filter: {
			AccountNumberFrom: {
				en: 'Account# \'from\'' ,
				de: 'Kontonummer \'von\'',
				it: 'Numero conto \'da\'',
				fr: 'Numéro de compte \'de\'',
				ru: 'Номер счета \'от\'',
				ua: 'Номер рахунку \'від\'',
				pl: 'Numer konta \'od\'',
				md: 'Numărul de cont \'de la\'',
				lt: 'Sąskaitos numeris \'nuo\'',
				lv: 'Konta numurs \'no\'',
				ee: 'Konto number \'alates\'',
			},
			AccountNumberTo: {
				en: 'Account# \'to\'',
				de: 'Kontonummer \'zu\'',
				it: 'Numero conto \'a\'',
				fr: 'Numéro de compte \'à\'',
				ru: 'Номер счета \'до\'',
				ua: 'Номер рахунку \'до\'',
				pl: 'Numer konta \'do\'',
				md: 'Numărul de cont \'la\'',
				lt: 'Sąskaitos numeris \'iki\'',
				lv: 'Konta numurs \'līdz\'',
				ee: 'Konto number \'kuni\'',
			},
		},
		table: {
			accountNumber: {
				en: '#Account',
				de: '#Konto',
				it: '#Conto',
				fr: '#Compte',
				ru: '#Счет',
				ua: '#Рахунок',
				pl: '#Konto',
				md: '#Cont',
				lt: '#Sąskaita',
				lv: '#Konts',
				ee: '#Konto',
			},
			invoiceAndDescription: {
				en: 'Invoice# / Description',
				de: 'Rechnungsnummer / Beschreibung',
				it: 'Numero fattura / Descrizione',
				fr: 'Numéro de facture / Description',
				ru: 'Номер счета / Описание',
				ua: 'Номер рахунку / Опис',
				pl: 'Numer faktury / Opis',
				md: 'Numărul facturii / Descriere',
				lt: 'Sąskaitos numeris / Aprašymas',
				lv: 'Rēķina numurs / Apraksts',
				ee: 'Arve nr / Kirjeldus',
			},
			drAmount: {
				en: 'DR amount',
				de: 'DR Betrag',
				it: 'Importo DR',
				fr: 'Montant DR',
				ru: 'Сумма Дебета',
				ua: 'Сума Дебету',
				pl: 'Kwota DR',
				md: 'Suma DR',
				lt: 'DR suma',
				lv: 'DR summa',
				ee: 'DR summa',
			},
			crAmount: {
				en: 'CR amount',
				de: 'CR Betrag',
				it: 'Importo CR',
				fr: 'Montant CR',
				ru: 'Сумма Кредита',
				ua: 'Сума Кредиту',
				pl: 'Kwota CR',
				md: 'Suma CR',
				lt: 'CR suma',
				lv: 'CR summa',
				ee: 'CR summa',
			},
		},
		journalEntryDetails: {
			title: {
				en: 'Journal Entry details',
				de: 'Details zum Journaleintrag',
				it: 'Dettagli della registrazione del giornale',
				fr: 'Détails de l\'écriture du journal',
				ru: 'Детали записи в журнале',
				ua: 'Деталі запису в журналі',
				pl: 'Szczegóły wpisu do dziennika',
				md: 'Detalii operațiune', // ???
				lt: 'Įrašo detalės', // ???
				lv: 'Ieraksta detaļas', // ???
				ee: 'Kande detailid', // ???
			},
		},
	},
	vatReport: {
		vatPayedPage: {
			title: {
				en: 'Payed VAT',
				de: 'Bezahlte MwSt',
				it: 'IVA pagata',
				fr: 'TVA payée',
				ru: 'Оплаченный НДС',
				ua: 'Сплачений ПДВ',
				pl: 'Zapłacony VAT',
				md: 'TVA plătită',
				lt: 'Apmokėtas PVM',
				lv: 'Samaksāta PVN',
				ee: 'Makstud KMK',
			},
			table: {
				headerLabel: {
					en: 'Payed VAT',
					de: 'Bezahlte MwSt',
					it: 'IVA pagata',
					fr: 'TVA payée',
					ru: 'Оплаченный НДС',
					ua: 'Сплачений ПДВ',
					pl: 'Zapłacony VAT',
					md: 'TVA plătită',
					lt: 'Apmokėtas PVM',
					lv: 'Samaksāta PVN',
					ee: 'Makstud KMK',
				},
				paymentsForVat: {
					en: 'Payments under VAT',
					de: 'Zahlungen unter MwSt',
					it: 'Pagamenti in base all\'IVA',
					fr: 'Paiements sous TVA',
					ru: 'Платы по НДС',
					ua: 'Платежі за ПДВ',
					pl: 'Płatności pod VAT',
					md: 'Plăți sub TVA',
					lt: 'Mokėjimai pagal PVM',
					lv: 'Maksājumi par PVN',
					ee: 'Maksed KMK alusel',
				},
				vatAmount: {
					en: 'VAT amount',
					de: 'MwSt Betrag',
					it: 'Importo IVA',
					fr: 'Montant TVA',
					ru: 'Сумма НДС',
					ua: 'Сума ПДВ',
					pl: 'Kwota VAT',
					md: 'Suma TVA',
					lt: 'PVM suma',
					lv: 'PVN summa',
					ee: 'KMK summa',
				},
				netAmount: {
					en: 'NET Amount',
					de: 'Netto-Betrag',
					it: 'Importo netto',
					fr: 'Montant net',
					ru: 'Сумма НЕТТО',
					ua: 'Сума НЕТТО',
					pl: 'Kwota netto',
					md: 'Suma netă',
					lt: 'Grynasis kiekis',
					lv: 'Neto summa',
					ee: 'Netosumma',
				},
				totalFor: {
					en: 'Total for',
					de: 'Summe für',
					it: 'Totale per',
					fr: 'Total pour',
					ru: 'Итого по',
					ua: 'Всього за',
					pl: 'Razem dla',
					md: 'Total pentru',
					lt: 'Viso',
					lv: 'Kopā',
					ee: 'Kokku',
				},
			},
			tableTotals: {
				headerLabel: {
					en: 'Payed VAT Totals',	
					de: 'Bezahlte MwSt Summen',
					it: 'IVA pagata Totale',
					fr: 'TVA payée Totale',
					ru: 'Оплаченный НДС Итого',
					ua: 'Сплачений ПДВ Всього',
					pl: 'Zapłacony VAT Razem',
					md: 'TVA plătită Total',
					lt: 'Apmokėtas PVM Viso',
					lv: 'Samaksāta PVN Kopā',
					ee: 'Makstud KMK Kokku',
				},
				vatTotal: {
					en: 'VAT Total',
					de: 'MwSt Summe',
					it: 'Totale IVA',
					fr: 'Total TVA',
					ru: 'Итого НДС',
					ua: 'Всього ПДВ',
					pl: 'Razem VAT',
					md: 'Total TVA',
					lt: 'PVM suma',
					lv: 'PVN summa',
					ee: 'KMK summa',
				},
				entryTotal: {
					en: 'Entry Total',
					de: 'Eintragssumme',
					it: 'Totale voce',
					fr: 'Total de l\'entrée',
					ru: 'Всего заявок',
					ua: 'Всього записів',
					pl: 'Razem wpisów',
					md: 'Total intrare',
					lt: 'Viso įrašų',
					lv: 'Ierakstu kopā',
					ee: 'Kirje kokku',
				}
			},
		},
		vatForPayPage: {
			title: {
				en: 'VAT Payable',
				de: 'MwSt zu zahlen',
				it: 'IVA da pagare',
				fr: 'TVA à payer',
				ru: 'НДС к оплате',
				ua: 'ПДВ до сплати',
				pl: 'VAT do zapłaty',
				md: 'TVA de plată',
				lt: 'PVM mokėtina suma',
				lv: 'PVN maksājums',
				ee: 'KMK tasumine',
			},
		},
	},
	assetLiabilityReportPage: {
		titleLabel: {
			en: 'Asset-Liability Report',
			de: 'Vermögens-Liability Report',
			it: 'Asset-Liability Report',
			fr: 'Rapport actif-passif',
			ru: 'Отчет об активах и пассивах',
			ua: 'Звіт про активи та пасиви',
			pl: 'Raport aktywów i pasywów',
			md: 'Raport despre active și pasive',
			lt: 'Turto ir įsipareigojimų ataskaita',
			lv: 'Aktīvu un saistību pārskats',
			ee: 'Vara-kohustuste aruanne',
		},
		table: {
			currentPeriod: {
				en: 'Current period',
				de: 'Aktueller Zeitraum',
				it: 'Periodo attuale',
				fr: 'Période actuelle',
				ru: 'Текущий период',
				ua: 'Поточний період',
				pl: 'Bieżący okres',
				md: 'Perioada curentă',
				lt: 'Dabartinis laikotarpis',
				lv: 'Pašreizējais periods',
				ee: 'Praegune periood',
			},
			previousPeriod: {
				en: 'Previous period',
				de: 'Vorheriger Zeitraum',
				it: 'Periodo precedente',
				fr: 'Période précédente',
				ru: 'Предыдущий период',
				ua: 'Попередній період',
				pl: 'Poprzedni okres',
				md: 'Perioada anterioară',
				lt: 'Ankstesnis laikotarpis',
				lv: 'Iepriekšējais periods',
				ee: 'Eelmine periood',
			},
			title: {
				en: 'Title',
				de: 'Titel',
				it: 'Titolo',
				fr: 'Titre',
				ru: 'Заголовок',
				ua: 'Заголовок',
				pl: 'Tytuł',
				md: 'Titlu',
				lt: 'Pavadinimas',
				lv: 'Nosaukums',
				ee: 'Pealkiri',
			},
			variance: {
				en: 'Variance',
				de: 'Abweichung',
				it: 'Varianza',
				fr: 'Variance',
				ru: 'Отклонение',
				ua: 'Відхилення',
				pl: 'Wariancja',
				md: 'Abatere',
				lt: 'Skirtumas',
				lv: 'Atšķirība',
				ee: 'Kõrvalekalle',
			},
		},
	},

};

const lang = {
	en: 'Email',
	de: 'de',
	it: 'it',
	fr: 'fr',
	ru: 'ru',
	ua: 'ua',
	pl: '',
	md: '',
	lt: '',
	lv: '',
	ee: '',
}
