import {Accordion, Card, Form} from "react-bootstrap";
import {AiOutlineFilter} from "react-icons/ai";
import {Typeahead} from "react-bootstrap-typeahead";
import { useUserStore } from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

export const OperationsFilter = () => {
	const lang = useUserStore(state => state.userLanguage);
  	return (
		<>
			<Accordion>
				<Accordion.Item eventKey={'0'}>
					<Accordion.Header style={{color: '#ECECEC'}}>
						<AiOutlineFilter style={{marginRight: '5px'}}/> {dict.common.filter[lang]}
					</Accordion.Header>
					<Accordion.Body>
						<Card.Body>
							<Form>
								<Form.Group controlId="date" className="mb-3">
									<Form.Label>{dict.operationsPage.filter.invoiceDate[lang]}</Form.Label>
									<Form.Control type="date"/>
								</Form.Group>
								<Form.Group controlId="description" className="mb-1">
									<Form.Label>{dict.operationsPage.filter.description[lang]}</Form.Label>
									<Form.Control type="text"/>
								</Form.Group>
								<Form.Group controlId="amount" className="mb-1">
									<Form.Label>{dict.common.amount[lang]}</Form.Label>
									<Form.Control type="number"/>
								</Form.Group>
								<Form.Group controlId="currency" className="mb-1">
									<Form.Label>{dict.common.currency[lang]}</Form.Label>
									<Typeahead id={'currency'} options={['USD', 'CHF', 'EUR']} />
								</Form.Group>
								<Form.Group controlId="exchangeRate" className="mb-2">
									<Form.Label>{dict.operationsPage.filter.currencyRate[lang]}</Form.Label>
									<Form.Control type="number"/>
								</Form.Group>

								<Form.Group controlId="date" className="mb-3">
									<Form.Label>{dict.common.date[lang]}</Form.Label>
									<Form.Control type="date"/>
								</Form.Group>
								<Form.Group controlId="payments" className="mb-1">
									<Form.Check type={'checkbox'} label={dict.operationsPage.filter.payment[lang]}/>
								</Form.Group>
							</Form>
						</Card.Body>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}
