import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {toast} from "react-toastify";
import {AccountGroupRequestDto, AccountGroupResponseDto, AccountPlanApi} from "../api/account-plan.api";
import {Language} from "../type";

export interface AccountGroupStore {
	isLoading: boolean;
	isSubmitting: boolean;
	showModal: boolean;

	accountGroups: AccountGroupResponseDto[];
	activeAccountGroup: AccountGroupRequestDto;

	fetchAccountGroups: (companyId: string) => void;
	setGroupValue: (name: keyof AccountGroupRequestDto, value: any, lang?: Language) => void;
	createAccountGroup: (companyId: string) => void;
	updateAccountGroup: (companyId: string) => void;
}

export const useAccountGroupStore = create<AccountGroupStore>()(immer((set) => ({
	isLoading: false,
	isSubmitting: false,
	showModal: false,

	accountGroups: [],
	activeAccountGroup: {
		id: '',
		sectionId: '',
		groupNumber: 0,
		title: {
			en: '',
			de: '',
			it: '',
			fr: '',
			ru: '',
			ua: '',
			pl: '',
			md: '',
			lt: '',
			lv: '',
			ee: '',
		}
	},


	fetchAccountGroups: async (companyId: string) => {
		set({ isLoading: true });
		const accountGroups = await AccountPlanApi.getInstance().getAccountGroups(companyId);
		set({ isLoading: false, accountGroups });
	},

	setGroupValue: (name: keyof AccountGroupRequestDto, value: any, lang?: Language) => {	set (state => {
		if (name === 'title' && lang) {
			state.activeAccountGroup.title[lang] = value;
			return;
		}
		state.activeAccountGroup[name] = value;
	})},

	createAccountGroup: async (companyId: string) => {
		set({ isSubmitting: true });
		const createAccountGroup = await AccountPlanApi.getInstance().createAccountGroup(companyId, {
			...useAccountGroupStore.getState().activeAccountGroup
		});
		set({ isSubmitting: false });

		if (createAccountGroup) {
			set(state => {
				state.accountGroups.push(createAccountGroup);
				state.showModal = false;
				toast.success('Account group created');
			});
		}
	},

	updateAccountGroup: async (companyId: string) => {
		set({ isSubmitting: true });
		const updatedAccountGroup = await AccountPlanApi.getInstance().updateAccountGroup(companyId, {
			...useAccountGroupStore.getState().activeAccountGroup
		});
		set({ isSubmitting: false });

		if (updatedAccountGroup) {
			set(state => {
				const index = state.accountGroups.findIndex(s => s.id === updatedAccountGroup.id);
				state.accountGroups[index] = updatedAccountGroup;
				state.showModal = false;
				toast.success('Account group updated');
			});
		}
	}

})));
