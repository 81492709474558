import {Button, Container, Pagination, Stack, Table} from "react-bootstrap";
import {AiOutlinePlus} from "react-icons/ai";
import PageTitle from "../../components/PageTitle";
import React, {FC, useEffect} from "react";
import {OperationsFilter} from "./widget/OperationsFilter";
import {OperationModal} from "./widget/OperationModal";
import {useOperationsStore} from "../../app/store/operations.store";
import CollapsibleOperationRow from "./widget/CollapsibleOperationRow";
import {useUserStore} from "../../app/store/user.store";
import {AppLoader} from "../../components/AppLoader";
import {useParams} from "react-router-dom";
import {Currency, OperationEntrySide} from "../../app/type";
import {OperationComments} from "./widget/OperationComments";
import {OperationEntryModal} from "./widget/OperationEntryModal";
import {displayDate} from "../../app/utils";
import {dict} from "../../app/dictionary";

const OperationsPage: FC = () => {
	const { counterpartyId, operationEntrySide, operationId } = useParams();

	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const isFetching = useOperationsStore(state => state.isFetching);
	const counterpartyOperations = useOperationsStore(state => state.counterpartyOperations);
	const lang = useUserStore(state => state.userLanguage);

	const operationEntrySideParam = OperationEntrySide[operationEntrySide?.toUpperCase() as keyof typeof OperationEntrySide];

	/**
	 * Fetch single operation by id
	 */
	useEffect(() => {
		if (activeCompany.companyId && counterpartyId && operationEntrySide && operationId) {
			useOperationsStore.getState().fetchCounterpartyOperationById(
				activeCompany.companyId,
				counterpartyId,
				operationEntrySideParam,
				operationId
			)
		}
	}, [activeCompany.companyId, counterpartyId, operationEntrySide, operationEntrySideParam, operationId]);

	/**
	 * Fetch operations by counterparty
	 */
	useEffect(() => {
		if (activeCompany.companyId && counterpartyId && operationEntrySide && !operationId) {
			useOperationsStore.getState().fetchCounterpartyOperations(
				activeCompany.companyId,
				counterpartyId,
				operationEntrySideParam,
				startDate,
				endDate
			);
		}
	}, [activeCompany.companyId, counterpartyId, operationEntrySide, startDate, endDate, operationId, operationEntrySideParam])

	const handleShowModal = () => {
		useOperationsStore.setState({
			showModal: true,
			operationRequest: {
				id: '',
				operationEntrySide: OperationEntrySide[operationEntrySide!.toUpperCase() as keyof typeof OperationEntrySide],
				operationDate: startDate,
				invoiceNumber: '',
				operationDescription: '',
				invoiceAmount: undefined,
				currency: Currency.CHF,
			}
		});
	}

  return (
		<>
			<AppLoader showLoader={isFetching} />
			<OperationModal />
			<OperationEntryModal company={activeCompany} />
			<OperationComments />

			<Container>
				<section className={"mt-3"}>
					<PageTitle title={`${counterpartyOperations?.counterparty.name} (${displayDate(startDate)} - ${displayDate(endDate)})`}
										 showBackButton={true}
										 showPrintButton={false}
										 showCogButton={false}
					/>
				</section>

				<section className={"mt-3"} id={'collapse-filter'}>
					<OperationsFilter />
				</section>

				<section className="mt-3">
					<Button variant="success" className="mb-1" onClick={handleShowModal}>
						<AiOutlinePlus/> {dict.operationsPage.addNewButton[lang]}
					</Button>
					<Table id='operationsTable' striped>
						<thead>
						<tr>
							<th>{dict.operationsPage.invoiceDate[lang]}</th>
							<th>{dict.common.invoiceNumber[lang]}</th>
							<th>{dict.common.description[lang]}</th>
							<th>{dict.common.amount[lang]}</th>
							<th>{dict.common.currency[lang]}</th>
							<th>{dict.common.balance[lang]}</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
							{counterpartyOperations?.items?.map((operation) => (
								<CollapsibleOperationRow key={operation.id}
																				 company={activeCompany}
																				 operationEntrySide={OperationEntrySide[operationEntrySide!.toUpperCase() as keyof typeof OperationEntrySide]}
																				 counterpartyId={counterpartyId!}
																				 operation={operation}
								/>
							))}
						</tbody>
					</Table>

					<div>
						<Stack>
							<Pagination className={"d-flex justify-content-center mt-5"}>
								<Pagination.Item active={true}> 0 </Pagination.Item>
								<Pagination.Item active={false}>1</Pagination.Item>
								<Pagination.Item active={false}>2</Pagination.Item>
								<Pagination.Item active={false}>3</Pagination.Item>
								<Pagination.Item active={false}>4</Pagination.Item>
								<Pagination.Item active={false}>5</Pagination.Item>
							</Pagination>
							<Button variant="link" size={"sm"}>{dict.common.displayAll[lang]}</Button>
						</Stack>
					</div>
				</section>
			</Container>
		</>
	);
}

export default OperationsPage;
