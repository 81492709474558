import {toast} from "react-toastify";

export interface UploadedFileResponseDto {
	fileName: string;
	fileUrl: string;
}

export class FileUploadApi {

	/**
	 * Upload file
	 * @param companyId
	 * @param file
	 */
	public static async uploadFile(companyId: string, file: File): Promise<UploadedFileResponseDto | null> {
		try {
			const formData = new FormData();
			formData.append('companyId', companyId);
			formData.append('file', file);

			const url = `${process.env.REACT_APP_API_HOST}/file/v1/upload`;
			const response = await fetch(url, {
				method: 'POST',
				body: formData
			});

			const data: UploadedFileResponseDto = await response.json();
			return data;
		} catch (e) {
			toast.error('Failed to upload file. Please try again later');
			return null
		}
	}

}
