import {Button, Container, Table} from "react-bootstrap";
import {
	AiOutlinePlus,
	AiOutlineDelete,
	AiOutlineEdit, AiOutlineCopy, AiOutlineMessage, AiOutlineLink
} from 'react-icons/ai';
import React, {useEffect} from "react";
import PageTitle from "../../components/PageTitle";
import {useJournalEntryStore} from "../../app/store/journal-entry.store";
import {useUserStore} from "../../app/store/user.store";
import {AppLoader} from "../../components/AppLoader";
import {JournalEntryModal} from "./widgets/JournalEntryModal";
import {JournalEntryResponseDto} from "../../app/api/journal-entry.api";
import {displayAmount, displayDate} from "../../app/utils";
import {JournalEntryComments} from "./widgets/JournalEntryComments";
import FileButton from "../../components/FileButton";
import {useParams} from "react-router-dom";
import {AppRoutes} from "../../App";
import JournalEntriesFilter from "./widgets/JournalEntriesFilter";
import LabelTooltip from "../../components/LabelTooltip";
import {JournalEntryType} from "../../app/type";
import {dict} from "../../app/dictionary";

const JournalEntriesPage = () => {
	const { jeId} = useParams();

	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const isLoading = useJournalEntryStore(state => state.isLoading);
	const journalEntries = useJournalEntryStore(state => state.journalEntries);
	const lang = useUserStore(state => state.userLanguage);

	const getJournalEntryAmount = (journalEntry: JournalEntryResponseDto) => {
		return `${displayAmount(journalEntry.companyAmount)} ${activeCompany.companyCurrency} (1 ${activeCompany.companyCurrency} = ${journalEntry.conversionRate} ${journalEntry.currency})`;
	}

	/**
	 * Get single journal entry
	 */
	useEffect(() => {
		if (jeId) {
			useJournalEntryStore.getState().getJournalEntry(jeId)
		}
	}, [jeId]);

	/**
	 * Get journal entries
	 */
	useEffect(() => {
		if (activeCompany.companyId && !jeId) {
			useJournalEntryStore.getState().getJournalEntries(activeCompany.companyId, startDate, endDate);
		}
	}, [activeCompany.companyId, startDate, endDate]);

	/**
	 * Add new journal entry
	 */
	const addNewJournalEntry = () => {
		useJournalEntryStore.setState({
			showModal: true,
			journalEntry: {
				id: '',
				entryDate: startDate,
				invoiceNumber: '',
				entryDescription: '',
				debitAccountId: null,
				creditAccountId: null,
				entryAmount: 0.00,
				vatEntryAmount: 0.00,
				currency: activeCompany.companyCurrency,
				journalEntryType: JournalEntryType.JE,
				conversionRate: null,
				version: null,
				attachedFileUrl: null,
				vatCode: null,
				vatAccountId: null,
			}
		});
	}

	/**
	 * Edit journal entry
	 * @param journalEntry
	 * @param clone
	 */
	const editJournalEntry = (journalEntry: JournalEntryResponseDto, clone: boolean = false) => {
		useJournalEntryStore.setState({
			showModal: true,
			journalEntry: {
				id: clone ? '' : journalEntry.id,
				entryDate: new Date(journalEntry.entryDate),
				invoiceNumber: journalEntry.invoiceNumber,
				entryDescription: journalEntry.entryDescription,
				debitAccountId: journalEntry.debit?.id ?? null,
				creditAccountId: journalEntry.credit?.id ?? null,
				journalEntryType: journalEntry.entryType,
				entryAmount: journalEntry.entryAmount,
				vatEntryAmount: journalEntry.vatEntryAmount,
				currency: journalEntry.currency,
				conversionRate: journalEntry.conversionRate,
				version: journalEntry.version,
				attachedFileUrl: null,
				vatCode: journalEntry.vat?.vatCode ?? null,
				vatAccountId: journalEntry.vatAccount?.id ?? null,
			}
		});
	}

	/**
	 * Remove journal entry
	 * @param entryId
	 */
	const handleRemoveEntry = (entryId: string) => {
		useJournalEntryStore.getState().removeJournalEntry(entryId);
	}

	/**
	 * Copy journal entry link
	 * @param entryId
	 */
	const handleCopyLink = async (entryId: string) => {
		const journalEntryUrl = window.location.origin + AppRoutes.getSingleJournalEntryRoute(activeCompany.companyId, entryId);
		window.open(journalEntryUrl, '_blank');
	}

	/**
	 * Show journal entry comments
	 * @param journalEntry
	 */
	const showEntryComments = (journalEntry: JournalEntryResponseDto) => {
		useJournalEntryStore.setState({
			showEntryComments: true,
			selectedJournalEntry: journalEntry,
			journalEntry: {
				id: journalEntry.id,
				entryDate: new Date(journalEntry.entryDate),
				invoiceNumber: journalEntry.invoiceNumber,
				entryDescription: journalEntry.entryDescription,
				debitAccountId: journalEntry.debit?.id ?? null,
				creditAccountId: journalEntry.credit?.id ?? null,
				entryAmount: journalEntry.entryAmount,
				vatEntryAmount: journalEntry.vatEntryAmount,
				journalEntryType: journalEntry.entryType,
				currency: journalEntry.currency,
				conversionRate: journalEntry.conversionRate,
				version: journalEntry.version,
				attachedFileUrl: null,
				vatCode: journalEntry.vat?.vatCode ?? null,
				vatAccountId: journalEntry.vatAccount?.id ?? null,
			}
		});
	}

	const handleExportClick = () => {
		useJournalEntryStore.getState().exportJournalEntries(activeCompany, startDate, endDate);
	}

	const handleUploadClick = (file: File) => {
		useJournalEntryStore.getState().uploadJournalEntryFile(activeCompany.companyId, file);
	}

	return (
		<>
			<Container>
				<AppLoader showLoader={isLoading} />
				<JournalEntryModal company={activeCompany} />
				<JournalEntryComments />

				<section className={"mt-3"}>
					<PageTitle title={`${dict.journalEntriesPage.titleLabel[lang]}: ${activeCompany.companyName} (${displayDate(startDate)} - ${displayDate(endDate)})`}
										 showCogButton={true}
										 showExportButton={true}
										 showUploadButton={true}
										 handleExport={handleExportClick}
										 handleUpload={handleUploadClick}
					/>
				</section>

				<section className={"mt-3"} id={'collapse-filter'}>
					<JournalEntriesFilter />
				</section>

				<section className="mt-3">
					<Button variant="success" className="mb-1" onClick={addNewJournalEntry}>
						<AiOutlinePlus /> {dict.journalEntriesPage.addNewButton[lang]}
					</Button>
					<Table id='journalTable' striped hover>
						<thead>
						<tr>
							<th>{dict.common.date[lang]}</th>
							<th>{dict.common.entryType[lang]}</th>
							<th>{dict.common.invoiceNumber[lang]}</th>
							<th>{dict.common.description[lang]}</th>
							<th>{dict.common.debitNumber[lang]}</th>
							<th>{dict.common.creditNumber[lang]}</th>
							<th>{dict.common.amount[lang]}</th>
							<th>{dict.common.currency[lang]}</th>
							<th>{dict.common.vat[lang]}</th>
							<th>{dict.common.document[lang]}</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
						{journalEntries.map((entry) => (
							<tr key={entry.id}>
								<td>{displayDate(entry.entryDate)}</td>
								<td>{entry.entryType}</td>
								<td>{entry.invoiceNumber}</td>
								<td>{entry.entryDescription}</td>
								<td>
									<LabelTooltip id={entry.id} title={entry.debit?.title[lang]}>
										#{entry.debit?.accountNumber}
									</LabelTooltip>
								</td>
								<td>
									<LabelTooltip id={entry.id} title={entry.credit?.title[lang]}>
										#{entry.credit?.accountNumber}
									</LabelTooltip>
								</td>
								<td>
									<LabelTooltip id={entry.id} title={getJournalEntryAmount(entry)}>
										{displayAmount(entry.entryAmount)}
									</LabelTooltip>
								</td>
								<td>{entry.currency}</td>
								<td>
									{ entry.vat?.vatCode && 
									<div style={{display: 'flex', justifyContent: 'space-between'}}>
										<LabelTooltip id={entry.id} title={entry.vat?.title[lang]}>
											{entry.vat?.vatCode}
										</LabelTooltip>

										<LabelTooltip id={entry.id} title={displayAmount(entry.vatCompanyAmount) + ' ' + activeCompany.companyCurrency}>
											{displayAmount(entry.vatEntryAmount)}
										</LabelTooltip>

										<LabelTooltip id={entry.id} title={entry.vatAccount?.title[lang]}>
											#{entry.vatAccount?.accountNumber}
										</LabelTooltip>
									</div>}
								</td>
								<td>
									<FileButton fileUrl={entry.attachedFileUrl} />
								</td>
								<td style={{display: 'flex', justifyContent: 'space-between'}}>
									<Button style={{ padding: 0 }} variant={'link'} onClick={() => handleCopyLink(entry.id)}>
										<AiOutlineLink size={20}/>
									</Button>
									<Button style={{ padding: 0 }} variant={'link'} onClick={() => editJournalEntry(entry, true)}>
										<AiOutlineCopy size={20}/>
									</Button>
									<Button style={{ padding: 0 }} variant={'link'} onClick={() => editJournalEntry(entry)}>
										<AiOutlineEdit size={20}/>
									</Button>
									<Button style={{ padding: 0 }} variant={'link'} onClick={() => showEntryComments(entry)}>
										<AiOutlineMessage size={20}/>
									</Button>
									<Button variant={'link'} onClick={() => handleRemoveEntry(entry.id)}>
										<AiOutlineDelete size={20} color={'red'}/>
									</Button>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
				</section>
			</Container>
		</>
	);
}

export default JournalEntriesPage;
