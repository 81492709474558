import React from 'react'
import ReactDOM from 'react-dom/client'
import App from "./App";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './index.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <ToastContainer />
    <App />
  </>
)
