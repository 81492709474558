import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {toast} from "react-toastify";
import {
	CounterpartyRequestDto,
	CounterpartyOperationsTotalResponseDto,
	CounterpartyRestApi,
	TotalByCurrencyResponseDto, CounterpartyResponseDto
} from "../api/counterparty-rest.api";
import {useUserStore} from "./user.store";
import {AppRoutes} from "../../App";
import {OperationEntrySide} from "../type";
import {NavigateFunction} from "react-router-dom";

export interface CounterpartyStore {
	isFetching: boolean;
	isProcessing: boolean;
	showModal: boolean;

	counterparties: CounterpartyResponseDto[];
	counterpartiesOperationTotals: CounterpartyOperationsTotalResponseDto[];
	counterpartiesTotals: TotalByCurrencyResponseDto[];
	newCounterparty: CounterpartyRequestDto;

	fetchCounterparties: (companyId: string) => void;
	fetchCounterpartiesTotal: (companyId: string, operationEntrySide: OperationEntrySide, startDate: Date, endDate: Date) => void;
	setCounterpartyValue: (key: keyof CounterpartyRequestDto, value: string) => void;
	addCounterparty: (navigate: NavigateFunction, operationEntrySide: OperationEntrySide) => void;
}

export const useCounterpartyStore = create<CounterpartyStore>()(immer((set) => ({
	isFetching: false,
	isProcessing: false,
	showModal: false,

	counterparties: [],
	counterpartiesOperationTotals: [],
	counterpartiesTotals: [],
	newCounterparty: { name: '', description: '' },

	/**
	 * Fetch counterparties
	 * @param companyId
	 */
	fetchCounterparties: async (companyId: string) => {
		set({ isFetching: true, counterparties: [] });
		const counterparties = await CounterpartyRestApi.getInstance().getCounterparties(companyId);
		set({ counterparties, isFetching: false });
	},

	/**
	 * Fetch counterparties
	 * @param companyId
	 * @param operationEntrySide
	 * @param startDate
	 * @param endDate
	 */
	fetchCounterpartiesTotal: async (companyId: string, operationEntrySide: OperationEntrySide, startDate: Date, endDate: Date) => {
		set({ isFetching: true, counterpartiesOperationTotals: [] });
		const counterpartiesOperationTotals = await CounterpartyRestApi.getInstance().getCounterpartiesTotals(companyId, operationEntrySide, startDate, endDate);
		const counterpartiesTotals: TotalByCurrencyResponseDto[] = [];

		counterpartiesOperationTotals.forEach(c => {
			c.totals?.forEach(t => {
				const currencyTotal = counterpartiesTotals.find(tc => tc.currency === t.currency);
				if (currencyTotal) {
					currencyTotal.totalInvoices += t.totalInvoices;
					currencyTotal.totalInvoiceAmount += t.totalInvoiceAmount;
					currencyTotal.totalBalance += t.totalBalance;
				} else {
					counterpartiesTotals.push({ ...t });
				}
			});
		});

		set({ counterpartiesOperationTotals, isFetching: false, counterpartiesTotals });
	},

	setCounterpartyValue: (key: keyof CounterpartyRequestDto, value: string) => {
		set(state => {
			state.newCounterparty[key] = value;
		})
	},

	addCounterparty: async (navigate: NavigateFunction, operationEntrySide: OperationEntrySide) => {
		const companyId = useUserStore.getState().activeCompany?.companyId;
		const newCounterparty = useCounterpartyStore.getState().newCounterparty;

		// Check is counterpart exists by name
		const existedCounterpart = useCounterpartyStore.getState().counterpartiesOperationTotals.find(c => c.name === newCounterparty.name);
		if (existedCounterpart) {
			navigate(AppRoutes.getOperationsRoute(companyId!, operationEntrySide, existedCounterpart.id));
			return;
		}

		set({ isProcessing: true });
		const addedCounterparty =  await CounterpartyRestApi.getInstance().addCounterparty(companyId, newCounterparty);
		set({ isProcessing: false });

		if (addedCounterparty) {
			set((state) => {
				state.counterpartiesOperationTotals.push(addedCounterparty);
				state.showModal = false;
				toast.success('New counterpart added successfully');
				navigate(AppRoutes.getOperationsRoute(companyId!, operationEntrySide, addedCounterparty.id));
			})
		}
	}

})));
