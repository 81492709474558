import {BaseRestApi} from "../BaseRestApi";
import {AccountNumberResponseDto} from "./account-plan.api";
import {AxiosError, AxiosResponse} from "axios";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {toast} from "react-toastify";
import {Currency} from "../type";

export interface AccountMovementJournalEntryResponseDto {
	id: string;
	companyId: string;
	entryDate: Date;
	entryType: string;
	invoiceNumber: string;
	entryDescription: string;
	counterAccount: AccountNumberResponseDto;
	attachedFileUrl: string;
	entryCurrency: Currency;
	companyCurrency: Currency;

	crCompanyAmount: number;
	crAmount: number;

	drCompanyAmount: number;
	drAmount: number;
}

export interface AccountMovementTotalResponseDto {
	currency: Currency;
	drTotal: number;
	crTotal: number;
	variance: number;
}

export interface AccountMovementResponseDto {
	accountNumber: AccountNumberResponseDto;
	prevPeriodTotals: AccountMovementTotalResponseDto[];
	journalEntries: AccountMovementJournalEntryResponseDto[];
	currentPeriodTotals: AccountMovementTotalResponseDto[];
}

export class AccountMovementsApi extends BaseRestApi {

	private static instance: AccountMovementsApi;

	public static getInstance(): AccountMovementsApi {
		if (!AccountMovementsApi.instance) {
			AccountMovementsApi.instance = new AccountMovementsApi();
		}
		return AccountMovementsApi.instance;
	}

	public async getList(companyId: string, startDate: Date, endDate: Date): Promise<AccountMovementResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/report/account-movement/v1/list/${companyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<AccountMovementResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}
}
