import {Accordion, Card, Form, InputGroup} from "react-bootstrap";
import {AiOutlineFilter} from "react-icons/ai";
import {Typeahead} from "react-bootstrap-typeahead";
import React from "react";
import { useUserStore } from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

const JournalEntriesFilter = () => {
	const lang = useUserStore(state => state.userLanguage);
	return (
		<Accordion>
			<Accordion.Item eventKey={'0'}>
				<Accordion.Header style={{ color: '#ECECEC' }}>
					<AiOutlineFilter style={{ marginRight: '5px' }} /> {dict.common.filter[lang]}
				</Accordion.Header>
				<Accordion.Body>
					<Card.Body>
						<Form>
							<Form.Group controlId="date" className="mb-3">
								<Form.Label>{dict.common.entryDate[lang]}</Form.Label>
								<Form.Control type="date" />
							</Form.Group>
							<Form.Group controlId="description" className="mb-2">
								<Form.Label>{dict.common.invoiceNumber[lang]}</Form.Label>
								<Form.Control type="text" />
							</Form.Group>
							<Form.Group controlId="description" className="mb-2">
								<Form.Label>{dict.journalEntriesPage.filter.entryDescription[lang]}</Form.Label>
								<Form.Control type="text" />
							</Form.Group>
							<Form.Group controlId="accounts" className="mb-1">
								<Form.Label>{dict.common.accountNumber[lang]}</Form.Label>
								<InputGroup>
									<Typeahead
										id="account-to"
										placeholder={'Debtor #'}
										options={['1010 - Почтовый счет', '1020 - ZKB CHF Geschäftlich', '1036 - Wise EUR']}
									/>
									<Typeahead
										id="account-from"
										placeholder={'Creditor #'}
										options={['1010 - Почтовый счет', '1020 - ZKB CHF Geschäftlich', '1036 - Wise EUR']}
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group controlId="amount" className="mb-1">
								<Form.Label>{dict.common.amount[lang]}</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
							<Form.Group controlId="currency" className="mb-1">
								<Form.Label>{dict.common.currency[lang]}</Form.Label>
								<Form.Control type="text" />
							</Form.Group>
							<Form.Group controlId="exchangeRate" className="mb-1">
								<Form.Label>{dict.common.currency[lang]}</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
							<Form.Group controlId="amount" className="mb-1">
								<Form.Label>{dict.common.vat[lang]}</Form.Label>
								<Typeahead
									id="vat"
									options={['1010 - Почтовый счет', '1020 - ZKB CHF Geschäftlich', '1036 - Wise EUR']}
								/>
							</Form.Group>
						</Form>
					</Card.Body>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default JournalEntriesFilter;
