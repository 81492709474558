import {Container} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import VatTableTotals from "./widgets/VatTableTotals";
import {useUserStore} from "../../app/store/user.store";
import {useVatReportStore} from "../../app/store/vat-report.store";
import {useEffect} from "react";
import VatTable from "./widgets/VatTable";
import {AppLoader} from "../../components/AppLoader";
import {dict} from "../../app/dictionary";

const VATForPayPage = () => {

	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);

	const isFetching = useVatReportStore(state => state.isFetching);
	const journalEntries = useVatReportStore(state => state.journalEntries);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		if (activeCompany.companyId) {
			useVatReportStore.getState().fetchVATForPayed(activeCompany.companyId, startDate, endDate);
		}
	}, [activeCompany.companyId, startDate, endDate]);

	return (
		<>
			<Container>
				<AppLoader showLoader={isFetching} />
				<PageTitle title={dict.vatReport.vatForPayPage.title[lang]} showPrintButton={true} />

				<VatTable company={activeCompany}
									startDate={startDate}
									endDate={endDate}
									journalEntries={journalEntries}
									lang={lang}
				/>

				<VatTableTotals company={activeCompany}
												startDate={startDate}
												endDate={endDate}
												journalEntries={journalEntries}
												lang={lang}
				/>
			</Container>
		</>
	);
}

export default VATForPayPage;
