import moment from 'moment';
import {AccountNumberResponseDto, VatResponseDto} from "./api/account-plan.api";
import {CounterpartyResponseDto} from "./api/counterparty-rest.api";
import {Currency, Language} from "./type";

export function isInt(str: string) {
	return !isNaN(parseInt(str));
}

export function formatDate(date: Date | undefined) {
	if (!date) {
		return '';
	}

	return date?.toString().split('T')[0]	;
}

export function displayDate(date: Date) {
	if (!date) {
		return '';
	}

	const momentDate = moment(date);
	return momentDate.format('DD.MM.YY');
}

export function displayYear(date: Date) {
	if (!date) {
		return '';
	}

	const momentDate = moment(date);
	return momentDate.format('YYYY');
}

export function subtractOneYear(date: Date) {
	let dateCopy = new Date(date); // create a copy to avoid mutating the original date
	dateCopy.setFullYear(dateCopy.getFullYear() - 1);
	return dateCopy;
}

export function getFixedAmount(num: number) {
	if (!num) {
		return 0;
	}
	return parseFloat(num.toFixed(2));
}

export function displayAmount(amount: number, currency?: Currency) {
	if (amount === 0) {
		return '';
	}

	amount = Math.abs(amount);

	return currency ? currency + ' ' + amount.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
		amount.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

/**
 * Map account number to typeahead option
 * @param accounts
 */
export function mapAccountNumberToOption(accounts: AccountNumberResponseDto[], lang: Language) {
	return accounts?.map(account => {
		return {
			id: account.id,
			label: account.accountNumber.toString() + ' - ' + account.title[lang]
		}
	});
}

export function getSelectedAccountNumberOption(accounts: AccountNumberResponseDto[], accountId: string, lang: Language) {
	const account = accounts?.find(account => account.id === accountId);
	return {
		id: accountId,
		label: account?.accountNumber.toString() + ' - ' + account?.title[lang]
	}
}

export function mapCounterpartyToOption(counterparties: CounterpartyResponseDto[]) {
	return counterparties?.map(counterparty => {
		return {
			id: counterparty.id,
			label: counterparty.name
		}
	});

}

export function mapVatToOption(vats: VatResponseDto[], lang: Language) {
	return vats?.map(vat => {
		return {
			id: vat.vatCode,
			label: vat.vatCode + ' - ' + vat.title[lang]
		}
	});
}

export function getSelectedVatOption(vats: VatResponseDto[], vatCode: string, lang: Language) {
	const vat = vats?.find(vat => vat.vatCode === vatCode);
	return {
		id: vatCode,
		label: vat?.vatCode + ' - ' + vat?.title[lang]
	}
}
