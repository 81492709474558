import {Accordion, Card, Container, Form, FormControl, Table} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import {AiOutlineFilter} from "react-icons/ai";
import React, {useEffect, useState} from "react";
import {AddButton} from "../../components/AddButton";
import {AppLoader} from "../../components/AppLoader";
import {AccountSectionModal} from "./widget/AccountSectionModal";
import {AccountSectionResponseDto, buildEmptyAccountSection} from "../../app/api/account-plan.api";
import {useAccountSectionStore} from "../../app/store/account-section.store";
import {useUserStore} from "../../app/store/user.store";
import {dict} from "../../app/dictionary";

const AccountSectionPage = () => {
	const [sectionNumberFilter, setSectionNumberFilter] = useState<string>('');

	const lang = useUserStore(state => state.userLanguage);
	const activeCompany = useUserStore(state => state.activeCompany);
	const isLoading = useAccountSectionStore(state => state.isLoading);
	const showModal = useAccountSectionStore(state => state.showModal);
	const accountSections = useAccountSectionStore(state => state.accountSections);

	const setActiveSection = useAccountSectionStore(state => state.setActiveSection);
	const fetchAccountsSections = useAccountSectionStore(state => state.fetchAccountsSections);

	const getFilteredSections = () => {
		let filteredSections = accountSections;

		if (sectionNumberFilter) {
			filteredSections = filteredSections.filter(f => f.sectionNumber.toString().includes(sectionNumberFilter));
		}
		return filteredSections;
	}

	/**
	 * Reload account sections when active company is changed
	 */
	useEffect(() => {
		if (activeCompany) {
			fetchAccountsSections(activeCompany.companyId);
		}
	}, [activeCompany, fetchAccountsSections]);

	const handleAddNewSection = () => {
		setActiveSection(buildEmptyAccountSection());
		useAccountSectionStore.setState({ showModal: true });
	}

	const editSection = (section: AccountSectionResponseDto) => {
		setActiveSection(section);
		useAccountSectionStore.setState({ showModal: true });
	}

	return (
		<>
			<AppLoader showLoader={isLoading} />
			<AccountSectionModal showModal={showModal} />

			<Container>
				<section className={"mt-3"}>
					<PageTitle title={dict.accountSectionPage.pageTitle[lang]}/>
				</section>

				<section>
					<Accordion>
						<Accordion.Item eventKey={'0'}>
							<Accordion.Header style={{color: '#ECECEC'}}>
								<AiOutlineFilter style={{marginRight: '5px'}}/>{dict.common.filter[lang]}
							</Accordion.Header>
							<Accordion.Body>
								<Card.Body>
									<Form>
										<Form.Group className="mb-1">
											<Form.Label>{dict.accountSectionPage.section[lang]}</Form.Label>
											<FormControl type="number"
																	 placeholder={dict.common.typeSectionNumberPlaceholder[lang]}
																	 value={sectionNumberFilter}
																	 onChange={(e) => setSectionNumberFilter(e.target.value)}
											/>
										</Form.Group>
									</Form>
								</Card.Body>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className={"mt-3"}>
					<AddButton title={dict.accountSectionPage.addNewSection[lang]}
										 handleClick={handleAddNewSection}
					/>
					<Table className={"mt-2"} striped bordered hover>
						<thead>
						<tr>
							<th>{dict.common.sectionNumber[lang]}</th>
							<th>{dict.common.name[lang]}</th>
						</tr>
						</thead>
						<tbody style={{ cursor: 'pointer' }}>
						{getFilteredSections().map((item) => (
							<tr key={item.id} onClick={() => editSection(item)}>
								<td>{item.sectionNumber}</td>
								<td>{item.title[lang]}</td>
							</tr>
						))}
						</tbody>
					</Table>
				</section>
			</Container>
		</>
	);
}

export default AccountSectionPage;
