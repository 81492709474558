import {Button, Card, Form, Offcanvas, Spinner, Stack} from "react-bootstrap";
import {useJournalEntryStore} from "../../../app/store/journal-entry.store";
import {AppLoader} from "../../../components/AppLoader";
import React, {useEffect} from "react";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";


export const JournalEntryComments = () => {
	const [commentBody, setCommentBody] = React.useState('');

	const showEntryComments = useJournalEntryStore(state => state.showEntryComments);
	const isLoading = useJournalEntryStore(state => state.isLoading);
	const isProcessing = useJournalEntryStore(state => state.isProcessing);

	const selectedJournalEntry = useJournalEntryStore(state => state.selectedJournalEntry);
	const journalEntryComments = useJournalEntryStore(state => state.journalEntryComments);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		setCommentBody('');
	}, []);

	useEffect(() => {
		if (showEntryComments && selectedJournalEntry?.id) {
			useJournalEntryStore.getState().getJournalEntryComments(selectedJournalEntry.id);
		}
	}, [showEntryComments, selectedJournalEntry?.id]);


	const handleAddComment = () => {
		if (selectedJournalEntry?.id) {
			useJournalEntryStore.getState().addJournalEntryComment(selectedJournalEntry.id, commentBody);
			setCommentBody('');
		}
	}

	if (!selectedJournalEntry) {
		return null;
	}

	return (
		<>

			<AppLoader showLoader={isLoading} />

			<Offcanvas style={{ minWidth: '650px', backgroundColor: '#F1F2F5' }} placement={'end'}
								 show={showEntryComments}
								 onHide={() => { useJournalEntryStore.setState({ showEntryComments: false }) }}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>{selectedJournalEntry.invoiceNumber} {selectedJournalEntry.entryDescription}</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Stack gap={0}>
						<div>
							<span><strong>{dict.journalEntriesPage.journalEntryType[lang]}:</strong> {selectedJournalEntry.entryType}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdAt[lang]}:</strong> {selectedJournalEntry.createdAt.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdBy[lang]}:</strong> {selectedJournalEntry?.createdBy?.fullName}</span>
						</div>
						<div>
							<span><strong>{dict.common.updatedAt[lang]}:</strong> {selectedJournalEntry.updatedAt?.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.updatedBy[lang]}:</strong> {selectedJournalEntry?.updatedBy?.fullName}</span>
						</div>
						<div>
							<span><strong>{dict.journalEntriesPage.comments.entityVersion[lang]}:</strong> {selectedJournalEntry?.version}</span>
						</div>
					</Stack>
					<hr/>

					<Form style={{marginBottom: '25px'}}>
						<Form.Group className="mb-2" controlId="formBasicEmail">
							<Form.Control as="textarea" rows={1}
														value={commentBody}
														placeholder={dict.journalEntriesPage.comments.addEntryComment[lang]}
														onChange={(e) => setCommentBody(e.target.value)}
							/>
						</Form.Group>

						<Button variant="primary"
										size={'sm'}
										disabled={isProcessing || commentBody.length === 0}
										onClick={handleAddComment}>
							{isProcessing ? <Spinner animation="border" size="sm" /> : dict.common.addComment[lang]}
						</Button>
					</Form>

					<h5>{dict.common.comments[lang]} ({journalEntryComments.length})</h5>
					{journalEntryComments.map((entryComment) => {
						return (
							<Card key={entryComment.id} style={{marginBottom: '5px'}}>
								<Card.Body>
									<Card.Text>
										{entryComment.comment}
									</Card.Text>
									<Card.Footer>
										<small>{entryComment?.author?.fullName}, 5 mins ago</small>
									</Card.Footer>
								</Card.Body>
							</Card>
						)
					})}

				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}
