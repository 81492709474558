import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {toast} from "react-toastify";
import {
	AccountPlanApi,
	AccountSectionCreateRequestDto,
	AccountSectionResponseDto,
	AccountSectionUpdateRequestDto, buildEmptyAccountSection
} from "../api/account-plan.api";
import {Language} from "../type";

export interface AccountSectionStore {
	isLoading: boolean;
	showModal: boolean;
	accountSections: AccountSectionResponseDto[];
	activeSection: AccountSectionResponseDto;

	fetchAccountsSections: (companyId: string) => void;
	createNewSection: (companyId: string, section: AccountSectionCreateRequestDto) => void;
	updateSection: (companyId: string, sectionId: string, section: AccountSectionUpdateRequestDto) => void;

	setActiveSection: (section: AccountSectionResponseDto) => void;
	changeSectionValue: (name: keyof AccountSectionResponseDto, value: any, lang?: Language) => void;
}

export const useAccountSectionStore = create<AccountSectionStore>()(immer((set) => ({
	isLoading: false,
	showModal: false,

	accountSections: [],
	activeSection: buildEmptyAccountSection(),

	accountsPlan: [],

	// Account sections
	fetchAccountsSections: async (companyId: string) => {
		set({ isLoading: true });
		const accountSections = await AccountPlanApi.getInstance().getAccountSections(companyId);
		set({ isLoading: false, accountSections });
	},

	updateSection: async (companyId: string, sectionId: string, section: AccountSectionUpdateRequestDto) => {
		console.log('updateSection', companyId, sectionId, section)

		set({ isLoading: true });
		const accountSection = await AccountPlanApi.getInstance().updateAccountSection(companyId, sectionId, section);
		set({ isLoading: false });

		if (accountSection) {
			set(state => {
				const index = state.accountSections.findIndex(s => s.id === sectionId);
				state.accountSections[index] = accountSection;
				state.showModal = false;
				toast.success('Account section updated');
			});
		}
	},

	createNewSection: async (companyId: string, section: AccountSectionCreateRequestDto) => {
		console.log('createNewSection', companyId, section)

		set({ isLoading: true });
		const newAccountSection = await AccountPlanApi.getInstance().createAccountSection(companyId, section);
		set({ isLoading: false });

		if (newAccountSection) {
			set(state => {
				state.accountSections.push(newAccountSection);
				state.showModal = false;
				toast.success('New account section created');
			});
		}
	},

	setActiveSection: (section: AccountSectionResponseDto) => {
		set({ activeSection: section });
		console.log('activeSection', section);
	},

	changeSectionValue: (name: keyof AccountSectionResponseDto, value: any, lang?: Language) => {	set (state => {
		if (name === 'title' && lang) {
			state.activeSection.title[lang] = value;
			return;
		}
		state.activeSection[name] = value;
	})},

})));
