import {Button} from "react-bootstrap";
import {AiOutlinePlus} from "react-icons/ai";
import {FC} from "react";

interface AddButtonProps {
	title: string,
	handleClick?: () => void
}

export const AddButton: FC<AddButtonProps> = ({ title, handleClick }) => {
	return (
		<>
			<Button variant="success" onClick={handleClick}>
				<AiOutlinePlus/> {title}
			</Button>
		</>
	);
}
