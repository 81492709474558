import {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {Currency, OperationEntrySide} from "../type";
import {InternalUserResponseDto} from "./login.api";
import {BaseRestApi} from "../BaseRestApi";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {JournalEntryRequestDto, JournalEntryResponseDto} from "./journal-entry.api";

export interface OperationEntriesResponseDto {
	invoiceEntry: JournalEntryResponseDto;
	relatedEntries: JournalEntryResponseDto[];
}

export interface OperationCommentResponseDto {
	commentId: string;
	operationId: string;
	commentBody: string;
	createdAt: Date;
	author: InternalUserResponseDto;
}

export interface OperationCommentRequestDto {
	operationId: string;
	commentBody: string;
}

export interface CompanyCounterpartResponseDto {
	id: string;
	companyId: string;
	name: string;
}

export interface CounterpartyOperationsResponseDto {
	counterparty: CompanyCounterpartResponseDto;
	items: OperationResponseDto[];
}

export interface OperationResponseDto {
	id: string;
	companyId: string;
	counterpartyId: string;
	operationEntrySide: OperationEntrySide;
	operationDate: Date;
	invoiceNumber: string;
	operationDescription: string;
	invoiceAmount: number;
	balance: number;
	currency: Currency;
	createdBy: InternalUserResponseDto;
	createdAt: Date;
	updatedBy: InternalUserResponseDto;
	updatedAt: Date;
	entries: OperationEntriesResponseDto[]
}

export interface OperationRequestDto {
	[key: string]: any;
	id: string;
	operationDate: Date;
	invoiceNumber: string;
	operationDescription: string;
	invoiceAmount: number | undefined;
	currency: Currency;
}

export class OperationApi extends BaseRestApi {

	private static instance: OperationApi;

	public static getInstance(): OperationApi {
		if (!OperationApi.instance) {
			OperationApi.instance = new OperationApi();
		}
		return OperationApi.instance;
	}

	/**
	 * Get operations
	 * @param companyId
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param startDate
	 * @param endDate
	 */
	public async getOperations(
		companyId: string,
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		startDate: Date,
		endDate: Date
	): Promise<CounterpartyOperationsResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/list/${companyId}/${counterpartyId}/${operationEntrySide}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<CounterpartyOperationsResponseDto> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Get operation by id
	 * @param companyId
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param operationId
	 */
	public async getOperationById(
		companyId: string,
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		operationId: string
	): Promise<CounterpartyOperationsResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/single/${companyId}/${counterpartyId}/${operationEntrySide}/${operationId}`;
			const response: AxiosResponse<CounterpartyOperationsResponseDto> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Add counterparty operation
	 * @param companyId
	 * @param counterpartyId
	 * @param operationEntrySide
	 * @param operation
	 */
	public async createOperation(
		companyId: string,
		counterpartyId: string,
		operationEntrySide: OperationEntrySide,
		operation: OperationRequestDto
	): Promise<OperationResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/add/${companyId}/${counterpartyId}/${operationEntrySide}`;
			const response: AxiosResponse<OperationResponseDto> = await this.axiosInstance.post(url, operation);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Update counterparty operation
	 * @param companyId
	 * @param operation
	 */
	public async updateOperation(
		companyId: string,
		operation: OperationRequestDto
	): Promise<OperationResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/update/${companyId}/${operation.id}`;
			const response: AxiosResponse<OperationResponseDto> = await this.axiosInstance.put(url, operation);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Remove counterparty operation
	 * @param companyId
	 * @param operationId
	 */
	public async removeOperation(
		companyId: string,
		operationId: string
	): Promise<boolean> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/remove/${companyId}/${operationId}`;
			await this.axiosInstance.delete(url);
			return true;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return false;
		}
	}

	/**
	 * Add counterparty operation comment
	 * @param operationComment
	 */
	public async addComment(operationComment: OperationCommentRequestDto): Promise<OperationCommentResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/add-comment`;
			const response: AxiosResponse<OperationCommentResponseDto> = await this.axiosInstance.post(url, operationComment);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Get counterparty operation comments
	 * @param operationId
	 */
	public async getComments(operationId: string): Promise<OperationCommentResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/comments/${operationId}`;
			const response: AxiosResponse<OperationCommentResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * Get operation entries
	 * @param operationId
	 */
	public async getOperationEntries(operationId: string): Promise<OperationEntriesResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/operation-entries/${operationId}`;
			const response: AxiosResponse<OperationEntriesResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * Remove operation journal entry (will return whole row with recalculated balance
	 * @param operationId
	 * @param journalEntryId
	 */
	public async removeOperationEntry(operationId: string, journalEntryId: string): Promise<OperationResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/operation/remove-entry/${operationId}/${journalEntryId}`;
			const response: AxiosResponse<OperationResponseDto> = await this.axiosInstance.delete(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Update operation entry
	 * @param operationEntryRequest
	 */
	public async updateOperationEntry(operationEntryRequest: JournalEntryRequestDto): Promise<OperationResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/operation/update-entry/${operationEntryRequest.id}`;
			const response: AxiosResponse<OperationResponseDto> = await this.axiosInstance.put(url, operationEntryRequest);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Add operation entry
	 * @param companyId
	 * @param operationId
	 * @param operationEntryRequest
	 */
	public async addOperationEntry(companyId: string, operationId: string, operationEntryRequest: JournalEntryRequestDto): Promise<OperationResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/operations/v1/operation/add-entry/${companyId}/${operationId}`;
			const response: AxiosResponse<OperationResponseDto> = await this.axiosInstance.post(url, operationEntryRequest);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

}
