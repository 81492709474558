import {Button, Card, Form, Offcanvas, Spinner, Stack} from "react-bootstrap";
import {AppLoader} from "../../../components/AppLoader";
import React, {useEffect} from "react";
import {useOperationCommentStore} from "../../../app/store/operation-comment.store";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

export const OperationComments = () => {
	const [commentBody, setCommentBody] = React.useState('');

	const isLoading = useOperationCommentStore(state => state.isFetching);
	const isProcessing = useOperationCommentStore(state => state.isProcessing);
	const showComments = useOperationCommentStore(state => state.showCommentsModal);

	const operationComments = useOperationCommentStore(state => state.operationComments);
	const selectedOperation = useOperationCommentStore(state => state.selectedOperation);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		setCommentBody('');
	}, []);

	useEffect(() => {
		if (showComments && selectedOperation?.id) {
			useOperationCommentStore.getState().fetchOperationComments(selectedOperation.id);
		}
	}, [showComments, selectedOperation?.id]);


	const handleAddComment = () => {
		if (selectedOperation?.id) {
			useOperationCommentStore.getState().addOperationComment(selectedOperation.id, commentBody);
			setCommentBody('');
		}
	}

	if (!selectedOperation) {
		return null;
	}

	return (
		<>

			<AppLoader showLoader={isLoading} />

			<Offcanvas style={{ minWidth: '650px', backgroundColor: '#F1F2F5' }} placement={'end'}
								 show={showComments}
								 onHide={() => { useOperationCommentStore.setState({ showCommentsModal: false }) }}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>{dict.common.invoiceNumber[lang]} {selectedOperation.invoiceNumber}</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Stack gap={0}>
						<div>
							<span><strong>{dict.common.createdAt[lang]}:</strong> {selectedOperation.createdAt?.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdBy[lang]}:</strong> {selectedOperation?.createdBy?.fullName}</span>
						</div>
						<div>
							<span><strong>{dict.common.updatedAt[lang]}:</strong> {selectedOperation.updatedAt?.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.updatedBy[lang]}:</strong> {selectedOperation?.updatedBy?.fullName}</span>
						</div>
					</Stack>
					<hr/>

					<Form style={{marginBottom: '25px'}}>
						<Form.Group className="mb-2" controlId="formBasicEmail">
							<Form.Control as="textarea" rows={1}
														value={commentBody}
														placeholder={dict.operationsPage.comments.addOperationComment[lang]}
														onChange={(e) => setCommentBody(e.target.value)}/>
						</Form.Group>

						<Button variant="primary"
										size={'sm'}
										disabled={isProcessing || commentBody.length === 0}
										onClick={handleAddComment}>
							{isProcessing ? <Spinner animation="border" size="sm" /> : dict.common.addComment[lang]}
						</Button>
					</Form>

					<h5>{dict.common.comments[lang]} ({operationComments.length})</h5>
					{operationComments.map((comment) => {
						return (
							<Card id={comment.commentId} style={{marginBottom: '5px'}}>
								<Card.Body>
									<Card.Text>
										{comment.commentBody}
									</Card.Text>
									<Card.Footer>
										<small>{comment?.author?.fullName}, 5 mins ago</small>
									</Card.Footer>
								</Card.Body>
							</Card>
						)
					})}

				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}
