import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FC, ReactElement, ReactNode} from "react";

interface LabelTooltipProps {
	id: string | undefined;
	title: string | undefined;
	children: any;
}

const LabelTooltip: FC<LabelTooltipProps> = ({ title, children }) => {
	return (
		<OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
			<span>{children}</span>
		</OverlayTrigger>
	)
}


export default LabelTooltip;
