import React, {FC} from "react";
import {Badge, Button} from "react-bootstrap";
import {
	AiOutlineCopy,
	AiOutlineDelete,
	AiOutlineEdit,
	AiOutlineEye, AiOutlineLink,
	AiOutlineMessage,
} from "react-icons/ai";
import {OperationResponseDto} from "../../../app/api/operation.api";
import {displayAmount, displayDate} from "../../../app/utils";
import {useOperationsStore} from "../../../app/store/operations.store";
import {OperationEntrySide} from "../../../app/type";
import {useOperationCommentStore} from "../../../app/store/operation-comment.store";
import {CollapsibleOperationEntryTable} from "./CollapsibleOperationEntryTable";
import {AppRoutes} from "../../../App";
import {UserCompany} from "../../../app/api/login.api";

interface CollapsibleRowProps {
	company: UserCompany,
	operationEntrySide: OperationEntrySide,
	counterpartyId: string,
	operation: OperationResponseDto
}

const CollapsibleOperationRow: FC<CollapsibleRowProps> = (
	{ company, operationEntrySide, counterpartyId, operation}
) => {
	const selectedOperation = useOperationsStore(state => state.selectedOperation);

	const totalEntriesAmount = () => {
		let totalAmount = 0;
		operation.entries.filter(f => f.invoiceEntry.credit && f.invoiceEntry.debit).forEach(entry => {
			totalAmount += entry.invoiceEntry.entryAmount + entry.invoiceEntry.vatEntryAmount;
		});
		return totalAmount;
	}

	const documentNotFoundCounter = () => {
		let counter = 0;
		operation.entries.forEach(entry => {
			if (!entry.invoiceEntry.attachedFileUrl) {
				counter++;
			}

			entry.relatedEntries.forEach(subEntry => {
				if (!subEntry.attachedFileUrl) {
					counter++;
				}
			});
		});
		return counter;
	}

	const handleCopyOperation = () => {
		useOperationsStore.setState({
			showModal: true,
			operationRequest: {
				id: '',
				operationEntrySide: operation.operationEntrySide,
				operationDate: new Date(operation.operationDate),
				invoiceNumber: operation.invoiceNumber,
				operationDescription: operation.operationDescription,
				invoiceAmount: operation.invoiceAmount,
				currency: operation.currency,
			}
		});
	}

	const handleEditOperation = () => {
		useOperationsStore.setState({
			showModal: true,
			operationRequest: {
				id: operation.id,
				operationEntrySide: operation.operationEntrySide,
				operationDate: new Date(operation.operationDate),
				invoiceNumber: operation.invoiceNumber,
				operationDescription: operation.operationDescription,
				invoiceAmount: operation.invoiceAmount,
				currency: operation.currency,
			}
		});
	}

	const handleRemoveOperation = () => {
		useOperationsStore.getState().removeOperation(operation.id);
	}

	const handleOpenComments = () => {
		useOperationCommentStore.setState({ showCommentsModal: true, selectedOperation: operation });
	}

	const handleOpenOperationEntries = () => {
		useOperationsStore.setState({
			selectedOperation: selectedOperation?.id !== operation.id ? operation : null
		});
	}

	const handleCopyLink = (operationId: string) => {
		const operationUrl = window.location.origin + AppRoutes.getSingleOperationRoute(company.companyId, operationEntrySide, counterpartyId, operationId);
		window.open(operationUrl, '_blank');
	}

	
	return (
		<>
			<tr style={{ display: selectedOperation?.id && operation.id !== selectedOperation.id ? 'none' : '' }}>
				<td>
					{ documentNotFoundCounter() > 0 && 
						<Badge bg="danger" style={{ marginRight: '10px' }}>{documentNotFoundCounter()}</Badge>}
					{displayDate(operation.operationDate)}
				</td>
				<td>{operation.invoiceNumber}</td>
				<td>{operation.operationDescription}</td>
				<td style={{ color: operation.invoiceAmount !== totalEntriesAmount() ? 'red' : 'black' }}>
					{displayAmount(operation.invoiceAmount)}
				</td>
				<td>{operation.currency}</td>
				<td>{displayAmount(operation.balance)}</td>
				<td style={{display: 'flex', justifyContent: 'space-between'}}>
					<div>
						<Button variant={'link'} onClick={() => handleCopyLink(operation.id)}>
							<AiOutlineLink size={20}/>
						</Button>
						<Button variant={'link'} onClick={handleOpenOperationEntries}>
							<AiOutlineEye size={20}/>
						</Button>
						<Button variant={'link'} onClick={handleCopyOperation}>
							<AiOutlineCopy size={20}/>
						</Button>
						<Button variant={'link'} onClick={handleEditOperation}>
							<AiOutlineEdit size={20}/>
						</Button>
						<Button variant={'link'} onClick={handleOpenComments}>
							<AiOutlineMessage size={20}/>
						</Button>
					</div>
					<div>
						<Button variant={'link'} onClick={handleRemoveOperation}>
							<AiOutlineDelete size={20} color={'red'}/>
						</Button>
					</div>
				</td>
			</tr>

			{operation.id === selectedOperation?.id && (
				<CollapsibleOperationEntryTable
					operation={selectedOperation}
					company={company}
				/>
			)}
		</>
	);
}

export default CollapsibleOperationRow;
