import React, {FC, ReactNode, useEffect} from "react";
import AppHeader from "../components/AppHeader";
import AppTabsNavigation from "../components/AppTabsNavigation";
import {Container} from "react-bootstrap";
import {useAccountNumberStore} from "../app/store/account-number.store";
import {useUserStore} from "../app/store/user.store";
import {useParams} from "react-router-dom";

interface BasePageProps {
	children: ReactNode | ReactNode[];
}

const BasePage: FC<BasePageProps> = ({ children }) => {
	const { companyId: companyIdParam} = useParams();

	const userInit = useUserStore(state => state.init);
	const activeCompany = useUserStore(state => state.activeCompany);
	const accountNumbers = useAccountNumberStore(state => state.accountNumbers);

	useEffect(() => {
		if (!activeCompany.companyId) {
			userInit();
		}
	}, []);

	useEffect(() => {
		if (companyIdParam && activeCompany.companyId !== companyIdParam) {
			useUserStore.getState().setActiveCompany(companyIdParam);
		}
	}, [companyIdParam]);

	return (
		<>
			<Container>
				<AppHeader/>
				<AppTabsNavigation/>
			</Container>

			{children}
		</>
	)
}

export default BasePage;
