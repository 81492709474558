import {Button, Container, Form, Spinner, Stack} from 'react-bootstrap';
import React from "react";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../app/store/user.store";
import {Language} from "../app/type";
import {LoginRequest} from "../app/api/login.api";
import {dict} from "../app/dictionary";

const LoginPage: React.FC = () => {
	const navigate = useNavigate();

	const [loginRequest, setLoginRequest] = React.useState<LoginRequest>({
		email: '',
		password: ''
	});

	const isLoading = useUserStore(state => state.isLoading);
	const lang = useUserStore(state => state.userLanguage);
	const sendLoginRequest = useUserStore(state => state.sendLoginRequest);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target;
		setLoginRequest({...loginRequest, [name]: value});
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		sendLoginRequest(loginRequest, navigate);
	}

	return (
		<>
			<Container fluid={'md'}>
				<Stack gap={2} className="col-md-5 mx-auto mt-5">
					<h1 className="text-center">ARVUT AI Application</h1>
					<p className="text-center"><b>Account Artificial Intelligence</b></p>
					<Form className={"login-form"} onSubmit={handleSubmit}>
						<Form.Group className="mt-3" controlId="formBasicEmail">
							<Form.Label>{dict.loginPage.emailLabel[lang]} *</Form.Label>
							<Form.Control type="email"
														required
														name={"email"}
														placeholder="Enter email"
														value={loginRequest.email}
														disabled={isLoading}
														onChange={handleChange}/>
						</Form.Group>
						<Form.Group className="mt-3" controlId="formBasicPassword">
							<Form.Label>{dict.loginPage.passwordLabel[lang]} *</Form.Label>
							<Form.Control type="password"
														required
														placeholder="Password"
														name={"password"}
														value={loginRequest.password}
														disabled={isLoading}
														onChange={handleChange}/>
						</Form.Group>

						<Form.Group className="mt-3">
							<Form.Label>{dict.loginPage.languageLabel[lang]}</Form.Label>
							<Form.Select aria-label="Language selector"
													 className={"me-3"}
													 value={lang}
													 onChange={(e) => useUserStore.setState({userLanguage: e.target.value as Language})}
							>
								{Object.values(Language).sort().map((item, index) => (
									<option key={index} value={item}>
										{item}
									</option>
								))}
							</Form.Select>
						</Form.Group>

						<div className="d-flex justify-content-center">
							<Button variant="primary"
											type="submit"
											className={"w-50 mt-4"}
											disabled={isLoading}>
								{isLoading ? <Spinner animation="border" size="sm" /> : dict.loginPage.loginButton[lang]}
							</Button>
						</div>
					</Form>
					<p className={"text-center"}>{dict.loginPage.createdByLabel[lang]}: <a href={'https://arvut.ch/'}>{dict.loginPage.createdBy[lang]}</a></p>
				</Stack>
			</Container>
		</>
	)
}

export default LoginPage;
