import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {toast} from "react-toastify";
import {
	buildDefaultJournalEntryRequest,
	JournalEntryApi,
	JournalEntryCommentResponseDto,
	JournalEntryRequestDto,
	JournalEntryResponseDto,
	UploadJournalEntryRequestDto
} from "../api/journal-entry.api";
import {useUserStore} from "./user.store";
import {FileUploadApi} from "../api/file-upload.api";
import {UserCompany} from "../api/login.api";
import Papa from 'papaparse';
import {Currency, JournalEntryType} from "../type";

export interface JournalEntryStore {
	isLoading: boolean;
	isProcessing: boolean;
	showModal: boolean;
	showEntryComments: boolean;
	selectedJournalEntry: JournalEntryResponseDto | null;
	journalEntries: JournalEntryResponseDto[];
	journalEntry: JournalEntryRequestDto;
	file: File | null;
	journalEntryComments: JournalEntryCommentResponseDto[];

	setEntryValue: (name: keyof JournalEntryRequestDto, value: any) => void;
	getJournalEntries: (companyId: string, startDate: Date, endDate: Date) => void;
	getJournalEntry: (jeId: string) => void;
	submitJournalEntry: (journalEntry: JournalEntryRequestDto) => void;
	removeJournalEntry: (journalEntryIid: string) => void;

	// Comments
	getJournalEntryComments: (journalEntryId: string) => void;
	addJournalEntryComment: (journalEntryId: string, comment: string) => void;

	exportJournalEntries: (company: UserCompany, startDate: Date, endDate: Date) => void;
	uploadJournalEntryFile: (companyId: string, file: File) => void;
}

export const useJournalEntryStore = create<JournalEntryStore>()(immer((set) => ({
	isLoading: false,
	isProcessing: false,
	showModal: false,
	showEntryComments: false,
	selectedJournalEntry: null,
	journalEntries: [],
	journalEntry: buildDefaultJournalEntryRequest(),
	file: null,
	journalEntryComments: [],

	/**
	 * Set journal entry value
	 * @param name
	 * @param value
	 */
	setEntryValue: (name: keyof JournalEntryRequestDto, value: any) => {	set (state => {
		state.journalEntry[name] = value
	})},

	/**
	 * Fetch journal entries
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 */
	getJournalEntries: async (companyId: string, startDate: Date, endDate: Date) => {
		set({ isLoading: true });
		const journalEntries = await JournalEntryApi.getInstance().getJournalEntries(companyId, startDate, endDate);
		set({	isLoading: false, journalEntries });
	},

	getJournalEntry: async (jeId: string) => {
		set({ isLoading: true });
		const journalEntry = await JournalEntryApi.getInstance().findJournalEntryById(jeId);
		set({ isLoading: false });

		if (journalEntry) {
			set(state => {
				state.journalEntries = [];
				state.journalEntries.push(journalEntry);
			});
		}
	},

	/**
	 * Create journal entry
	 * @param journalEntryRequest
	 */
	submitJournalEntry: async (journalEntryRequest: JournalEntryRequestDto) => {
		set({ isProcessing: true });
		const companyId = useUserStore.getState().activeCompany?.companyId;
		const file = useJournalEntryStore.getState().file;
		const isNewEntry = !journalEntryRequest.id;

		if (file) {
			const uploadedFile = await FileUploadApi.uploadFile(companyId, file);
			if (uploadedFile) {
				journalEntryRequest = { ...journalEntryRequest, attachedFileUrl: uploadedFile.fileUrl }
			}
		}

		const createdJournalEntry = isNewEntry ?
			await JournalEntryApi.getInstance().createJournalEntry(companyId, journalEntryRequest) :
			await JournalEntryApi.getInstance().updateJournalEntry(companyId, journalEntryRequest);
		set({ isProcessing: false });

		if (createdJournalEntry) {
			useUserStore.getState().updateAccountingPeriod(createdJournalEntry.entryDate);

			set(state => {
				if (isNewEntry) {
					state.journalEntries.unshift(createdJournalEntry);
					toast.success('New journal entry created successfully')
				} else {
					const index = state.journalEntries.findIndex(entry => entry.id === createdJournalEntry.id);
					state.journalEntries[index] = createdJournalEntry;
					toast.success('Journal entry updated successfully')
				}
				state.showModal = false;
				state.file = null;
			});
		}
	},

	removeJournalEntry: async (journalEntryId: string) => {
		const companyId = useUserStore.getState().activeCompany?.companyId;

		set({ isProcessing: true });
		const isRemoved = await JournalEntryApi.getInstance().removeJournalEntry(companyId, journalEntryId);
		set({ isProcessing: false });

		if (isRemoved) {
			set(state => {
				const index = state.journalEntries.findIndex(entry => entry.id === journalEntryId);
				state.journalEntries.splice(index, 1);
				toast.success('Journal entry removed successfully')
			});
		}
	},

	getJournalEntryComments: async (journalEntryId: string) => {
		set({ isLoading: true });
		const journalEntryComments = await JournalEntryApi.getInstance().getJournalEntryComments(journalEntryId);
		set({	isLoading: false, journalEntryComments });
	},

	addJournalEntryComment: async (journalEntryId: string, comment: string) => {
		set({ isProcessing: true });
		const journalEntryComment = await JournalEntryApi.getInstance().addJournalEntryComment({ journalEntryId, comment });
		set({ isProcessing: false });

		if (journalEntryComment) {
			set(state => {
				state.journalEntryComments.unshift(journalEntryComment);
				toast.success('Journal entry comment added successfully')
			});
		}
	},

	/**
	 *
	 * @param company
	 * @param startDate
	 * @param endDate
	 */
	exportJournalEntries: async (company: UserCompany, startDate: Date, endDate: Date) => {
		set({ isLoading: true });
		await JournalEntryApi.getInstance().exportJournalEntries(company, startDate, endDate);
		set({ isLoading: false });
	},

	/**
	 *
	 * @param companyId
	 * @param file
	 */
	uploadJournalEntryFile: async (companyId, file) => {
		//set({ isLoading: true });

		Papa.parse(file, {
			complete: async (results) => {
				try {
					const journalEntries: UploadJournalEntryRequestDto[] = [];

					for (let i = 0; i < results.data.length; i++) {
						const row = results.data[i] as string[];
						if (row.length !== 16) {
							toast.error('Invalid file format');
							return;
						}

						// Skip header row
						if (i === 0) {
							continue;
						}

						journalEntries.push({
							id: row[0],
							companyId: row[1],
							entryType: row[2] as JournalEntryType,
							entryDate: row[3],
							invoiceNumber: row[4],
							entryDescription: row[5],
							dr: parseInt(row[6]),
							cr: parseInt(row[7]),
							currency: row[8] as Currency,
							entryAmount: parseFloat(row[9]),
							companyEntryAmount: parseFloat(row[10]),
							conversionRate: parseFloat(row[11]),
							vatCode: row[12] === '' ? null : row[12],
							vatAmount: parseFloat(row[13]) ?? null,
							vatCompanyAmount: parseFloat(row[14]) ?? null,
							vatNumber: parseInt(row[15]) ?? null
						});
					} // for

					const uploadResult = await JournalEntryApi.getInstance().uploadJournalEntriesFromFile(companyId, journalEntries);
					if (uploadResult) {
						toast.success('File uploaded successfully')
						window.location.reload();
					}
				} catch (e) {
					toast.error('Error uploading file');
				} finally {
					set({ isLoading: false });
				}
			}
		});
	}

})));

