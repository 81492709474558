import { Container } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import AccountMovementsTable from "./widgets/AccountMovementsTable";
import {useUserStore} from "../../app/store/user.store";
import {useAccountMovementsStore} from "../../app/store/account-movements.store";
import React, {useEffect} from "react";
import {AppLoader} from "../../components/AppLoader";
import {JournalEntryDetails} from "./widgets/JournalEntryDetails";
import AccountMovementsFilter from "./widgets/AccountMovementsFilter";
import {displayDate} from "../../app/utils";
import {dict} from "../../app/dictionary";

const AccountMovementsPage = () => {
	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);

	const isFetching = useAccountMovementsStore(state => state.isFetching);
	const accountMovements = useAccountMovementsStore(state => state.accountMovements);
	const filteredAccountsIds = useAccountMovementsStore(state => state.filteredAccountsIds);

	const lang = useUserStore(state => state.userLanguage);

	const filteredAccounts = accountMovements?.filter(accountMovement => {
		if (!filteredAccountsIds.length) {
			return true;
		}
		return filteredAccountsIds.includes(accountMovement.accountNumber.id);
	});

	useEffect(() => {
		if (activeCompany.companyId) {
			useAccountMovementsStore.getState().fetchAccountMovements(activeCompany.companyId, startDate, endDate);
		}
	}, [activeCompany.companyId, startDate, endDate]);

	return (
		<>
			<AppLoader showLoader={isFetching} />
			<JournalEntryDetails activeCompany={activeCompany} />

			<Container>
				<section className={"mt-3"}>
					<PageTitle title={`${dict.accountMovementsPage.titleLabel[lang]}: ${activeCompany.companyName} (${displayDate(startDate)} - ${displayDate(endDate)})`}
										 showPrintButton={true}
					/>
				</section>

				<section className={"mt-3"} style={{paddingLeft: '5px'}}>
					<AccountMovementsFilter accountNumbers={activeCompany?.accountsPlan}/>

					<div style={{ paddingTop: '30px' }}>
						<hr style={{width: '100%'}}/>
					</div>
				</section>

				<section style={{paddingLeft: '5px'}}>
					{filteredAccounts?.map(accountMovement => (
						<AccountMovementsTable key={accountMovement.accountNumber.id}
																	 startDate={startDate}
																	 endDate={endDate}
																	 company={activeCompany}
																	 accountMovement={accountMovement}
						/>
					))}
				</section>
			</Container>
		</>
	);
}

export default AccountMovementsPage;
