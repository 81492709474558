import {
	Button,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	Modal, Spinner,
	Tab, Tabs
} from "react-bootstrap";
import {Language} from "../../../app/type";
import React, {ChangeEvent, FC} from "react";
import {useAccountSectionStore} from "../../../app/store/account-section.store";
import {useUserStore} from "../../../app/store/user.store";
import { dict } from "../../../app/dictionary";

export interface AccountSectionModalProps {
	showModal: boolean;
}

export const AccountSectionModal: FC<AccountSectionModalProps> = ({ showModal }) => {
	const activeCompany = useUserStore(state => state.activeCompany);
	const isLoading = useAccountSectionStore(state => state.isLoading);
	const activeSection = useAccountSectionStore(state => state.activeSection);
	const changeSectionValue = useAccountSectionStore(state => state.changeSectionValue);
	const submitNewSection = useAccountSectionStore(state => state.createNewSection);
	const updateSection = useAccountSectionStore(state => state.updateSection);
	const lang = useUserStore(state => state.userLanguage);

	const isFormNotValid = () => {
		return activeSection.sectionNumber === 0;
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'sectionNumber' && Number(value) < 0) {
			return;
		}
		changeSectionValue(name, Number(value));
	}

	const handleSectionTitleChange = (e: ChangeEvent<any>, lang: Language) => {
		changeSectionValue('title', e.target.value, lang);
	}

	const handleCloseModal = () => {
		useAccountSectionStore.setState({ showModal: false })
	}

	const handleSaveChanges = () => {
		if (activeSection.id) {
			updateSection(activeCompany.companyId, activeSection.id, {
				sectionNumber: activeSection.sectionNumber,
				title: activeSection.title
			});
			return;
		}

		submitNewSection(activeCompany.companyId, {
			sectionNumber: activeSection.sectionNumber,
			title: activeSection.title
		});
	}

	return (
		<div>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{activeSection.id ? dict.accountSectionPage.modal.title.edit[lang] : dict.accountSectionPage.modal.title.addNew[lang]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormGroup controlId="accountNumber" className="mt-3">
							<FormLabel>{dict.common.sectionNumber[lang]}</FormLabel>
							<Form.Control name={'sectionNumber'}
														type={'number'}
														value={activeSection.sectionNumber}
														onChange={handleInputChange}
														isInvalid={activeSection.sectionNumber === 0}
							/>
						</FormGroup>

						<FormGroup controlId="accountDescription" className="mt-3">
							<FormLabel>{dict.accountSectionPage.modal.sectionDescription[lang]}</FormLabel>
							<Tabs defaultActiveKey={Language.en}>
								{Object.values(Language).map((lang) => (
									<Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
										<FormControl type="text"
																 placeholder={`${dict.accountSectionPage.modal.accountSectionDescriptionIn[lang]} ${lang}`}
																 value={activeSection.title[lang]}
																 onChange={(e) => handleSectionTitleChange(e, lang)}
										/>
									</Tab>
								))}
							</Tabs>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button variant="primary"
									disabled={isFormNotValid() || isLoading}
									onClick={handleSaveChanges}
					>
						{isLoading ? <Spinner animation="border" size="sm" /> : dict.common.saveChanges[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
