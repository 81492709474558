import React, {ChangeEvent, createRef, RefObject} from "react";
import {AiOutlineUpload} from "react-icons/ai";
import {isInt} from "../../../app/utils";
import {AccountPlanApi} from "../../../app/api/account-plan.api";
import {toast} from "react-toastify";
import {useUserStore} from "../../../app/store/user.store";
import {useAccountNumberStore} from "../../../app/store/account-number.store";
import {AccountClass, Currency} from "../../../app/type";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../App";
import Papa from 'papaparse';
import { dict } from "../../../app/dictionary";
import { AccountPlanTitle } from "../../../app/const";

export class RawSectionData {
	public sectionNumber: number = 0;
	public title: { en: string, de: string, fr: string, it: string, ru: string, ua: string } = {
		en: "",
		de: "",
		fr: "",
		it: "",
		ru: "",
		ua: ""
	};

	public groups: RawGroupData[] = [];
}

export class RawGroupData {
	public groupNumber: number = 0;
	public title: { en: string, de: string, fr: string, it: string, ru: string, ua: string } = {
		en: "",
		de: "",
		fr: "",
		it: "",
		ru: "",
		ua: ""
	};
	public accountNumbers: RawAccountNumberData[] = [];
}

export class RawAccountNumberData {
	public accountNumber: number = 0;
	public accountClass: AccountClass = AccountClass.ASSET;
	public title: { en: string, de: string, fr: string, it: string, ru: string, ua: string } = {
		en: "",
		de: "",
		fr: "",
		it: "",
		ru: "",
		ua: ""
	};

	public currency: Currency = Currency.CHF;
	public isVat: boolean = false;
}

export class RawAccountPlanRequestDto {

	public sections: RawSectionData[] = [];

	public processNextLine(dataLine: string[], rowNumber: number): boolean {
		const [sectionNumber, groupNumber, accountNumber, accountClass, en, de, it, fr, ru, ua, currency, isVat] = dataLine;

		if (rowNumber === 0) {
			if (sectionNumber !== AccountPlanTitle.SECTION || groupNumber !== AccountPlanTitle.GROUP || accountNumber !== AccountPlanTitle.ACCOUNT_NUMBER 
				|| accountClass !== AccountPlanTitle.ACCOUNT_CLASS || en !== AccountPlanTitle.TITLE_EN || de !== AccountPlanTitle.TITLE_DE 
				|| fr !== AccountPlanTitle.TITLE_FR || it !== AccountPlanTitle.TITLE_IT || ru !== AccountPlanTitle.TITLE_RU 
				|| ua !== AccountPlanTitle.TITLE_UA || currency !== AccountPlanTitle.CURRENCY || isVat !== AccountPlanTitle.IS_VAT
			) {
				return false;
			}
			return true;
		}		

		if (!isInt(sectionNumber)) {
			toast.error(`Section number is not a number in row ${rowNumber}`);
			return false;
		}

		// Section
		if (sectionNumber && !groupNumber && !accountNumber && !accountClass && !currency && !isVat) {
			this.putSection(parseInt(sectionNumber), en, de, fr, it, ru, ua);
		}

		// Group
		if (sectionNumber && groupNumber && !accountNumber && !accountClass && !currency && !isVat) {
			if (!isInt(groupNumber)) {
				toast.error(`Group number is not a number in row ${rowNumber}`);
				return false;
			}
			const result = this.putGroup(parseInt(sectionNumber), parseInt(groupNumber), en, de, fr, it, ru, ua);
			if (!result) {
				toast.error(`Failed to put group in row ${rowNumber}... ${sectionNumber}, ${groupNumber}`);
				return false;
			}
		}

		// Account number
		if (sectionNumber && groupNumber && accountNumber && accountClass && currency && isVat) {
			if (!isInt(groupNumber)) {
				toast.error(`Group number is not a number in row ${rowNumber}`);
				return false;
			}

			if (!isInt(accountNumber)) {
				toast.error(`Account number is not a number in row ${rowNumber}`);
				return false;
			}

			if (AccountClass[accountClass as AccountClass] === undefined) {
				toast.error(`Account class is not correct in row ${rowNumber}, ${accountClass}`);
				return false;
			}

			if (Currency[currency as Currency] === undefined) {
				toast.error(`Currency is not correct in row ${rowNumber}, ${currency}`);
				return false;
			}

			if (isVat !== 'true' && isVat !== 'false') {
				toast.error(`Is VAT is not correct in row ${rowNumber}, ${isVat}`);
				return false;
			}

			const result = this.putAccountNumber(
				parseInt(sectionNumber), 
				parseInt(groupNumber), 
				parseInt(accountNumber), 
				accountClass as AccountClass, 
				en, de, fr, it, ru, ua, 
				currency as Currency, 
				isVat === 'true'
			);

			if (!result) {
				toast.error(`Failed to put account number in row ${rowNumber}... ${sectionNumber}, ${groupNumber}, ${accountNumber}`);
				return false;
			}
		}

		return true;
	}

	private putSection(
		sectionNumber: number, 
		en: string, 
		de: string, 
		fr: string, 
		it: string, 
		ru: string, 
		ua: string
	): boolean {
		const section = new RawSectionData();
		section.sectionNumber = sectionNumber;
		section.title = { en, de, fr, it, ru, ua };
		this.sections.push(section);

		return true;
	}

	private putGroup(
		sectionNumber: number, 
		groupNumber: number, 
		en: string, 
		de: string, 
		fr: string, 
		it: string, 
		ru: string, 
		ua: string
	): boolean {
		const section = this.sections.find(section => section.sectionNumber === sectionNumber);
		if (!section) {
			return false;
		}

		const groupIndex = section.groups.findIndex(group => group.groupNumber === groupNumber);
			if (groupIndex === -1) {
				section.groups.push({ groupNumber, title: { en, de, fr, it, ru, ua }, accountNumbers: []});
			} else {
				section.groups[groupIndex].title = { en, de, fr, it, ru, ua	};
				section.groups[groupIndex].groupNumber = groupNumber;
			}

		return true;
	}

	private putAccountNumber(
		sectionNumber: number, 
		groupNumber: number, 
		accountNumber: number,
		accountClass: AccountClass,
		en: string, 
		de: string, 
		fr: string, 
		it: string, 
		ru: string, 
		ua: string,
		currency: Currency,
		isVat: boolean
	): boolean {
		const section = this.sections.find(section => section.sectionNumber === sectionNumber);
		if (!section) {
			return false;
		}

		const group = section.groups.find(group => group.groupNumber === groupNumber);
		if (!group) {
			return false;
		}

		if (group) {
			const accountIndex = group.accountNumbers.findIndex(account => account.accountNumber === accountNumber);
			if (accountIndex === -1) {
				group.accountNumbers.push({ accountNumber, title: { en, de, fr, it, ru, ua }, accountClass, currency, isVat });
			} else {
				group.accountNumbers[accountIndex].title = { en, de, fr, it, ru, ua };
				group.accountNumbers[accountIndex].accountClass = accountClass;
				group.accountNumbers[accountIndex].accountNumber = accountNumber;
				group.accountNumbers[accountIndex].currency = currency;
				group.accountNumbers[accountIndex].isVat = isVat;
			}
		}

		return true;
	}
}

export function AccountPlanCSVUploader() {
	const inputOpenFileRef : RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
	const navigate = useNavigate();
	const lang = useUserStore(state => state.userLanguage);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		inputOpenFileRef.current?.click();
	}

	const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			try {
				useAccountNumberStore.setState({ isProcessing: true });

				const file = e.target.files[0];
				Papa.parse(file, {
					complete: async (results) => {
						const rawAccountPlanData = new RawAccountPlanRequestDto();
						for (let i = 0; i < results.data.length; i++) {
							const result = rawAccountPlanData.processNextLine(results.data[i] as string[], i);
							if (!result) {
								useAccountNumberStore.setState({ isProcessing: false });
								toast.error("Incorrect account plan file");
								return;
							}
						}

						const activeCompany = useUserStore.getState().activeCompany;
						if (!activeCompany) {
							toast.error("No active company");
							return;
						}

						const uploadResult = await AccountPlanApi.getInstance().uploadCompanyAccountPlan(activeCompany.companyId, rawAccountPlanData);
						useAccountNumberStore.setState({ isProcessing: false });
						if (uploadResult) {
							await useAccountNumberStore.getState().fetchAccountNumbers(activeCompany.companyId);
							navigate(AppRoutes.ACCOUNT_PLAN_PAGE);
						}
					}
				});

			} catch (error) {
				toast.error('Failed to read csv file');
				useAccountNumberStore.setState({ isProcessing: false });
			}
		}
	};

	return (
		<>
			<div onClick={handleClick}>
				<AiOutlineUpload/> {dict.appTabsNavigator.accountsPlan.uploadNewAccountsPlan[lang]}
				<input ref={inputOpenFileRef} style={{ display: 'none' }} type="file" accept=".csv" onChange={handleFileChange} />
			</div>
		</>
	);
}
