import {toast} from "react-toastify";
import {AiOutlineFileDone, AiOutlineWarning} from "react-icons/ai";
import {Button} from "react-bootstrap";
import React, {FC} from "react";

interface FileButtonProps {
	fileUrl: string | null | undefined;
}

const FileButton: FC<FileButtonProps> = ({ fileUrl }) => {

	const handleClick = () => {
		if (!fileUrl) {
			toast.error('No file attached')
			return;
		}
		window.open(`${process.env.REACT_APP_API_HOST}${fileUrl}`, '_blank')
	}

	return (
		<Button variant={'link'} onClick={handleClick}>
			{fileUrl ? <AiOutlineFileDone size={25}/> :
				<AiOutlineWarning size={25} color={'red'}/>}
		</Button>
	);
}

export default FileButton;
