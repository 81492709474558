import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {AiOutlineCheck, AiOutlineDelete} from "react-icons/ai";
import {useOperationsStore} from "../../../app/store/operations.store";
import {useUserStore} from "../../../app/store/user.store";
import React from "react";
import {useParams} from "react-router-dom";
import {Currency, OperationEntrySide} from "../../../app/type";
import {Typeahead} from "react-bootstrap-typeahead";
import {dict} from "../../../app/dictionary";

export const OperationModal = () => {
	const { counterpartyId, operationEntrySide } = useParams();
	const showModal = useOperationsStore(state => state.showModal);
	const isProcessing = useOperationsStore(state => state.isProcessing);
	const operationRequest = useOperationsStore(state => state.operationRequest);
	const setValue = useOperationsStore(state => state.setValue);
	const lang = useUserStore(state => state.userLanguage);

	const isFormNotValid = () => {
		return !operationRequest?.operationDate || !operationRequest?.invoiceNumber || !operationRequest?.operationDescription || !operationRequest?.invoiceAmount;
	}

	const handleClose = () => {
		useOperationsStore.setState({ showModal: false });
	}

	const handleSave = () => {
		useOperationsStore.getState().saveOperation(
			counterpartyId!,
			OperationEntrySide[operationEntrySide!.toUpperCase() as keyof typeof OperationEntrySide],
			operationRequest!
		);
	}

  return (
		<>
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{dict.operationsPage.modal.title[lang]}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="date" className="mb-3">
							<Form.Label>{dict.common.date[lang]} *</Form.Label>
							<Form.Control type="date"
														name={'operationDate'}
														isInvalid={!operationRequest?.operationDate}
														value={operationRequest?.operationDate.toISOString().split('T')[0]}
														onChange={(e) => setValue('operationDate', new Date(e.target.value))}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mb-2">
							<Form.Label>{dict.operationsPage.modal.invoiceNumber[lang]} *</Form.Label>
							<Form.Control type="text"
														name={'invoiceNumber'}
														isInvalid={!operationRequest?.invoiceNumber}
														value={operationRequest?.invoiceNumber}
														onChange={(e) => setValue('invoiceNumber', e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mb-2">
							<Form.Label>{dict.common.invoiceDescription[lang]} *</Form.Label>
							<Form.Control type="text"
														name={'operationDescription'}
														isInvalid={!operationRequest?.operationDescription}
														value={operationRequest?.operationDescription}
														onChange={(e) => setValue('operationDescription', e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="amount" className="mb-1">
							<Form.Label>{dict.common.amount[lang]} *</Form.Label>
							<Form.Control type="number"
														name={'invoiceAmount'}
														isInvalid={!operationRequest?.invoiceAmount}
														value={operationRequest?.invoiceAmount}
														onChange={(e) => setValue('invoiceAmount', parseFloat(e.target.value))}
							/>
						</Form.Group>
						<Form.Group controlId="currency" className="mb-1">
							<Form.Label>{dict.common.currency[lang]}</Form.Label>
							<Typeahead
								id="currency"
								clearButton={true}
								selected={operationRequest?.currency ? [operationRequest.currency] : []}
								options={Object.values(Currency)}
								onChange={(selected) => setValue('currency', selected[0] ?? null)}
								isInvalid={!operationRequest?.currency}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className={"d-flex justify-content-between"}>
					<Button variant="danger" disabled={!operationRequest?.id}>
						<AiOutlineDelete/> {dict.operationsPage.modal.remove[lang]}
					</Button>
					<hr style={{height: '30px', width: '2px', backgroundColor: '#000', margin: '0 10px'}}/>
					<Button variant="success"
									onClick={handleSave}
									disabled={isFormNotValid() || isProcessing}
					>
						<AiOutlineCheck/> {isProcessing ? <Spinner animation="border" size="sm" /> : dict.operationsPage.modal.saveInvoice[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
