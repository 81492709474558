import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {Currency} from "../../../app/type";
import {AiOutlineCheck, AiOutlineDelete} from "react-icons/ai";
import React, {FC} from "react";
import {useOperationsStore} from "../../../app/store/operations.store";
import {UserCompany} from "../../../app/api/login.api";
import {
	getFixedAmount,
	getSelectedAccountNumberOption,
	getSelectedVatOption,
	mapAccountNumberToOption,
	mapVatToOption
} from "../../../app/utils";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface OperationEntryModalProps {
	company: UserCompany
}

export const OperationEntryModal: FC<OperationEntryModalProps> = ({ company }) => {
	const vatAccounts = company.accountsPlan?.filter(account => account.isVat) ?? [];

	const showModal = useOperationsStore(state => state.showOperationEntryModal);
	const isProcessing = useOperationsStore(state => state.isProcessing);
	const operationEntryRequest = useOperationsStore(state => state.operationEntryRequest);
	const setOperationEntryValue = useOperationsStore(state => state.setOperationEntryValue);
	const updateOperationEntry = useOperationsStore(state => state.updateOperationEntry);
	const addOperationEntry = useOperationsStore(state => state.addOperationEntry);
	const lang = useUserStore(state => state.userLanguage);

	const isNotValidForm = () => {
		if (operationEntryRequest?.vatCode && !operationEntryRequest?.vatAccountId) return true;

		return !operationEntryRequest?.entryDate || !operationEntryRequest?.entryDescription ||
		!operationEntryRequest?.debitAccountId || !operationEntryRequest?.creditAccountId || !operationEntryRequest?.entryAmount
	};

	const accountNumberOptions = company.accountsPlan?.map(account => {
		return {
			id: account.id,
			label: account.accountNumber.toString() + '-' + account.title[lang]
		}
	});

	const getSelectAccountOption = (accountId: string) => {
		const account = company.accountsPlan?.find(account => account.id === accountId);

		return {
			id: accountId,
			label: account?.accountNumber.toString() + ' -' + account?.title[lang]
		}
	}

	const handleSelectorChange = (name: string, selected: any[]) => {
		setOperationEntryValue(name, selected[0]?.id ?? null);

		if (name === 'vatCode' && !selected.length) {
			setOperationEntryValue('vatEntryAmount', 0);
		}

		if (name === 'vatCode') {
			const vat = company.vats.find(vat => vat.vatCode === selected[0]?.id);
			if (vat) {
				setOperationEntryValue('vatEntryAmount', getFixedAmount(vat.amount * operationEntryRequest!.entryAmount));
			}
		}
	}

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files) return;
		useOperationsStore.setState({ attachedFile: files[0] });
	}

	const handleSave = () => {
		operationEntryRequest?.id ? updateOperationEntry(operationEntryRequest!) : addOperationEntry(operationEntryRequest!);
	}

	return (
		<>
			<Modal show={showModal} onHide={() => { useOperationsStore.setState({ showOperationEntryModal: false }) }}>
				<Modal.Header closeButton>
					<Modal.Title>{dict.operationsPage.entryModal.title[lang]}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="date">
							<Form.Label>{dict.common.entryDate[lang]} *</Form.Label>
							<Form.Control type="date"
														name={'entryDate'}
														value={operationEntryRequest?.entryDate?.toISOString()?.split('T')[0]}
														isInvalid={!operationEntryRequest?.entryDate}
														onChange={(e) => { setOperationEntryValue('entryDate', new Date(e.target.value)) }}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mt-3">
							<Form.Label>{dict.common.invoiceNumber[lang]}</Form.Label>
							<Form.Control type="text"
														name={'invoiceNumber'}
														value={operationEntryRequest?.invoiceNumber}
														isInvalid={!operationEntryRequest?.invoiceNumber}
														onChange={(e) => { setOperationEntryValue('invoiceNumber', e.target.value) }}
							/>
						</Form.Group>
						<Form.Group controlId="description" className="mt-3">
							<Form.Label>{dict.common.invoiceDescription[lang]} *</Form.Label>
							<Form.Control type="text"
														name={'entryDescription'}
														value={operationEntryRequest?.entryDescription}
														isInvalid={!operationEntryRequest?.entryDescription}
														onChange={(e) => { setOperationEntryValue('entryDescription', e.target.value) }}
							/>
						</Form.Group>

						<Row>
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group controlId="debit" className="mt-3">
									<Form.Label>{dict.common.dr[lang]}</Form.Label>
									<Typeahead id="debit"
														 clearButton={true}
														 isInvalid={!operationEntryRequest?.debitAccountId}
														 options={accountNumberOptions}
														 selected={operationEntryRequest?.debitAccountId ? [getSelectAccountOption(operationEntryRequest.debitAccountId)] : []}
														 onChange={(selected) => handleSelectorChange('debitAccountId', selected)}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="credit" className="mt-3">
									<Form.Label>{dict.common.cr[lang]}</Form.Label>
									<Typeahead id="credit"
														 clearButton={true}
														 isInvalid={!operationEntryRequest?.creditAccountId}
														 options={accountNumberOptions}
														 selected={operationEntryRequest?.creditAccountId ? [getSelectAccountOption(operationEntryRequest.creditAccountId)] : []}
														 onChange={(selected) => handleSelectorChange('creditAccountId', selected)}
									/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mt-3">
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group  className="mb-1">
									<Form.Label>{dict.common.amount[lang]}</Form.Label>
									<Form.Control type="number"
																name={'entryAmount'}
																value={operationEntryRequest?.entryAmount}
																isInvalid={!operationEntryRequest?.entryAmount}
																onChange={(e) => { setOperationEntryValue('entryAmount', parseFloat(e.target.value)) }}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="currency" className="mb-1">
									<Form.Label>{dict.common.currency[lang]}</Form.Label>
									<Typeahead
										id="currency"
										clearButton={true}
										selected={operationEntryRequest?.currency ? [operationEntryRequest?.currency] : []}
										options={Object.values(Currency)}
										onChange={(selected) => setOperationEntryValue('currency', selected[0])}
										isInvalid={!operationEntryRequest?.currency}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId="exchangeRate" className="mb-1">
							<Form.Label>{dict.common.conversionRate[lang]}</Form.Label>
							<Form.Control type="number"
														name={'conversionRate'}
														placeholder={`1 ${company.companyCurrency} = ? ${operationEntryRequest?.currency}`}
														value={operationEntryRequest?.conversionRate ?? ''}
														onChange={(e) => { setOperationEntryValue('conversionRate', parseFloat(e.target.value)) }}
							/>
						</Form.Group>
						<Row className="mt-3">
							<Col style={{ paddingRight: '0px' }}>
								<Form.Group controlId="vat" className="mt-3">
									<Form.Label>{dict.common.vat[lang]}</Form.Label>
									<Typeahead id="vat"
														 clearButton={true}
														 disabled={vatAccounts?.length === 0}
														 selected={operationEntryRequest?.vatCode ? [getSelectedVatOption(company.vats, operationEntryRequest?.vatCode, lang)] : []}
														 onChange={(selected) => handleSelectorChange('vatCode', selected)}
														 options={mapVatToOption(company.vats ?? [], lang)}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="vatAmount" className="mt-3">
									<Form.Label>VAT Amount</Form.Label>
									<Form.Control type="number"
																disabled={!operationEntryRequest?.vatCode}
																name={'vatEntryAmount'}
																value={operationEntryRequest?.vatEntryAmount}
																onChange={(e) => { setOperationEntryValue('vatEntryAmount', parseFloat(e.target.value)) }}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId="vatAccountNumber" className="mt-3">
							<Form.Label>{dict.common.vatAccount[lang]} #</Form.Label>
							<Typeahead id="vat"
												 clearButton={true}
												 disabled={!operationEntryRequest?.vatCode}
												 selected={operationEntryRequest?.vatAccountId ? [getSelectedAccountNumberOption(vatAccounts, operationEntryRequest?.vatAccountId, lang)] : []}
												 onChange={(selected) => handleSelectorChange('vatAccountId', selected)}
												 options={mapAccountNumberToOption(vatAccounts ?? [], lang)}
							/>
						</Form.Group>
						<Form.Group controlId="file" className="mt-3">
							<Form.Label>{dict.common.attachDocument[lang]}</Form.Label>
							<Form.Control type="file" accept=".pdf, .png, .jpg, .jpg" onChange={handleFileChange}/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className={"d-flex justify-content-between"}>
					{/* <Button variant="danger" disabled={isProcessing}>
						<AiOutlineDelete /> {dict.common.removeEntry[lang]}
					</Button>

					<hr style={{ height: '30px', width: '2px', backgroundColor: '#000', margin: '0 10px' }} /> */}

					<Button variant="success" onClick={handleSave} disabled={isNotValidForm()}>
						<AiOutlineCheck /> &nbsp;
						{isProcessing ? <Spinner animation="border" size="sm" /> : dict.common.saveEntry[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
