import {Button, Offcanvas, Stack, Table} from "react-bootstrap";
import React, {FC, useEffect} from "react";
import {useAccountMovementsStore} from "../../../app/store/account-movements.store";
import {formatDate} from "../../../app/utils";
import {AiFillEye} from "react-icons/ai";
import FileButton from "../../../components/FileButton";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../App";
import LabelTooltip from "../../../components/LabelTooltip";
import {UserCompany} from "../../../app/api/login.api";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface JournalEntryDetailsProps {
	activeCompany: UserCompany;
}

export const JournalEntryDetails: FC<JournalEntryDetailsProps> = ({ activeCompany }) => {
	const navigate = useNavigate();
	const selectedJournalEntryId = useAccountMovementsStore(state => state.selectedJournalEntryId);
	const journalEntry = useAccountMovementsStore(state => state.selectedJournalEntry);
	const lang = useUserStore(state => state.userLanguage);

	useEffect(() => {
		if (selectedJournalEntryId !== null) {
			useAccountMovementsStore.getState().fetchJournalEntry(selectedJournalEntryId);
		}
	}, [selectedJournalEntryId]);

	const handleClose = () => {
		useAccountMovementsStore.setState({
			selectedJournalEntryId: null
		});
	}

	return (
		<>
			<Offcanvas show={selectedJournalEntryId !== null}
								 onHide={handleClose}
								 placement={'bottom'}
								 style={{ minHeight: '350px', padding: '10px' }}
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						{dict.accountMovementsPage.journalEntryDetails.title[lang]}
						<Button variant="link" size={'lg'}
										onClick={() => navigate(AppRoutes.getSingleJournalEntryRoute(activeCompany.companyId, selectedJournalEntryId!))} >
							<AiFillEye />
						</Button>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Table id='journalTable' striped hover>
						<thead>
						<tr>
							<th>{dict.common.date[lang]}</th>
							<th>{dict.common.entryType[lang]}</th>
							<th>{dict.common.invoiceNumber[lang]}</th>
							<th>{dict.common.description[lang]}</th>
							<th>{dict.common.debitNumber[lang]}</th>
							<th>{dict.common.creditNumber[lang]}</th>
							<th>{dict.common.amount[lang]}</th>
							<th>{dict.common.currency[lang]}</th>
							<th>{dict.common.vat[lang]}</th>
							<th>{dict.common.document[lang]}</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
						<tr key={journalEntry?.id}>
							<td>{formatDate(journalEntry?.entryDate)}</td>
							<td>{journalEntry?.entryType}</td>
							<td>{journalEntry?.invoiceNumber}</td>
							<td>{journalEntry?.entryDescription}</td>
							<td>#{journalEntry?.debit?.accountNumber}</td>
							<td>#{journalEntry?.credit?.accountNumber}</td>
							<td>{journalEntry?.entryAmount}</td>
							<td>{journalEntry?.currency}</td>
							<td>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<LabelTooltip id={journalEntry?.id} title={journalEntry?.vat?.title.en}>
										{journalEntry?.vat?.vatCode}
									</LabelTooltip>

									<LabelTooltip id={journalEntry?.id} title={journalEntry?.vatCompanyAmount + ' ' + activeCompany.companyCurrency}>
										{journalEntry?.vatEntryAmount}
									</LabelTooltip>

									<LabelTooltip id={journalEntry?.id} title={journalEntry?.vatAccount?.title.en}>
										{journalEntry?.vatAccount?.accountNumber}
									</LabelTooltip>
								</div>
							</td>
							<td>
								<FileButton fileUrl={journalEntry?.attachedFileUrl}/>
							</td>
						</tr>
						</tbody>
					</Table>

					<Stack gap={0}>
						<div>
							<span><strong>{dict.journalEntriesPage.journalEntryType[lang]}:</strong> {journalEntry?.entryType}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdAt[lang]}:</strong> {journalEntry?.createdAt.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdBy[lang]}:</strong> {journalEntry?.createdBy?.fullName}</span>
						</div>
						<div>
							<span><strong>{dict.common.updatedAt[lang]}:</strong> {journalEntry?.updatedAt?.toString()}</span>
						</div>
						<div>
							<span><strong>{dict.common.createdBy[lang]}:</strong> {journalEntry?.updatedBy?.fullName}</span>
						</div>
					</Stack>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}
