import {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {BaseRestApi} from "../BaseRestApi";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {Currency, OperationEntrySide} from "../type";

export interface CounterpartyResponseDto {
	id: string;
	companyId: string;
	name: string;
	description: string;
}

export interface TotalByCurrencyResponseDto {
	totalInvoices: number;
	totalInvoiceAmount: number;
	totalBalance: number;
	currency: Currency;
}

export interface CounterpartyOperationsTotalResponseDto {
	id: string;
	companyId: string;
	name: string;
	description: string,
	totals?: TotalByCurrencyResponseDto[]
}

export interface CounterpartyRequestDto {
	[key: string]: any,
	name: string;
	description: string
}

export class CounterpartyRestApi extends BaseRestApi {
	private static instance: CounterpartyRestApi;

	public static getInstance(): CounterpartyRestApi {
		if (!CounterpartyRestApi.instance) {
			CounterpartyRestApi.instance = new CounterpartyRestApi();
		}
		return CounterpartyRestApi.instance;
	}

	constructor() {
		super();
	}

	public async getCounterparties(companyId: string): Promise<CounterpartyResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/counterparty/v1/list/${companyId}`;
			const response: AxiosResponse<CounterpartyResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	public async getCounterpartiesTotals(
		companyId: string,
		operationEntrySide: OperationEntrySide,
		startDate: Date,
		endDate: Date
	): Promise<CounterpartyOperationsTotalResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/counterparty/v1/totals/${companyId}/${operationEntrySide}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<CounterpartyOperationsTotalResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	public async addCounterparty(companyId: string, counterparty: CounterpartyRequestDto): Promise<CounterpartyOperationsTotalResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/counterparty/v1/add/${companyId}`;
			const response: AxiosResponse<CounterpartyOperationsTotalResponseDto> = await this.axiosInstance.post(url, counterparty);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}
}
