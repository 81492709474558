export enum AccountClass {
	ASSET = 'ASSET',
	LIABILITY = 'LIABILITY',
	INCOME = 'INCOME',
	EXPENSE = 'EXPENSE',
}

export enum Currency {
	CHF = 'CHF',
	USD = 'USD',
	EUR = 'EUR',
	JPY = 'JPY',
	HKD = 'HKD',
	MYR = 'MYR',
	SGD = 'SGD',
	THB = 'THB',
	RUB = 'RUB',
	UAH = 'UAH',
	SEK = 'SEK',
	GBP = 'GBP',
	CNY = 'CNY',
	DKK = 'DKK',
	HUF = 'HUF',
	CZK = 'CZK',
	PLN = 'PLN',
}

export enum Language {
	en = 'en',
	de = 'de',
	it = 'it',
	fr = 'fr',
	ru = 'ru',
	ua = 'ua',
	// pl = 'pl', // Polish
	// md = 'md', // Moldavian
	// lt = 'lt', // Lithuanian
	// lv = 'lv', // Latvian
	// ee = 'ee', // Estonian
}

export enum JournalEntryType {
	JE = 'JE',

	PI = 'PI', // Payable Invoice
	PPI = 'PPI', // Payment of Payable Invoice
	PIBC = 'PIBC', // Bank Charges on Payable Invoice

	RI = 'RI', // Receivable Invoice
	RPI = 'RPI', // Payment of Receivable Invoice
	RIBC = 'RIBC', // Bank Charges on Receivable Invoice
}

export enum OperationEntrySide {
	CREDITOR = 'CREDITOR',
	DEBTOR = 'DEBTOR',
}
