import {AnnualClassReportResponseDto, BalanceReportApi, BalanceReportResponseDto} from "../api/balance-report.api";
import {immer} from "zustand/middleware/immer";
import {create} from "zustand";
import { Language } from "../type";

export interface BalanceReportStore {
	isFetching: boolean;

	assetLiabilityReportItems: BalanceReportResponseDto[];
	fetchAssetLiabilityReport: (companyId: string, startDate: Date, endDate: Date, lang: Language) => void;

	profitLostReportItems: BalanceReportResponseDto[];
	fetchProfitLostReport: (companyId: string, startDate: Date, endDate: Date, lang: Language) => void;

	annualReportItems: AnnualClassReportResponseDto[];
	fetchAnnualReport: (companyId: string, startDate: Date, endDate: Date, lang: Language) => void;
}

export const useBalanceReportStore = create<BalanceReportStore>()(immer((set) => ({
	isFetching: false,

	assetLiabilityReportItems: [],
	profitLostReportItems: [],
	annualReportItems: [],

	/**
	 * Fetch asset-liability report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param lang
	 */
	fetchAssetLiabilityReport: async (companyId: string, startDate: Date, endDate: Date, lang: Language) => {
		set({ isFetching: true });
		const assetLiabilityReportItems = 
			await BalanceReportApi.getInstance().getAssetLiabilityReport(companyId, startDate, endDate, lang);
		set({ assetLiabilityReportItems, isFetching: false });
	},

	/**
	 * Fetch profit-lost report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param lang
	 */
	fetchProfitLostReport: async (companyId: string, startDate: Date, endDate: Date, lang: Language) => {
		set({ isFetching: true });
		const profitLostReportItems= await BalanceReportApi.getInstance().getProfitLostReport(companyId, startDate, endDate, lang);
		set({ profitLostReportItems, isFetching: false });
	},

	/**
	 * Fetch annual report
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 * @param lang
	 */
	fetchAnnualReport: async (companyId: string, startDate: Date, endDate: Date, lang: Language) => {
		set({ isFetching: true });
		const annualReportItems = await BalanceReportApi.getInstance().getAnnualReport(companyId, startDate, endDate, lang);
		set({ annualReportItems, isFetching: false });
	}
})));
