import {Form, Nav, Navbar} from "react-bootstrap";
import { FaSignOutAlt } from 'react-icons/fa';
import React, {useCallback} from "react";
import {useUserStore} from "../app/store/user.store";
import {Language, OperationEntrySide} from "../app/type";
import {AppRoutes} from "../App";
import {useNavigate} from "react-router-dom";

const AppHeader = () => {
	const navigate = useNavigate();
	const logout = useUserStore(state => state.logout);
	const activeCompany = useUserStore(state => state.activeCompany);
	const setActiveCompany = useUserStore(state => state.setActiveCompany);
	const userCompanies = useUserStore(state => state.userCompanies);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const language = useUserStore(state => state.userLanguage);

	/**
	 * Logout the user and redirect to the login page
	 */
	const handleSignOut = useCallback(() => {
		logout();
	}, []);

	/**
	 * Handle the company change
	 */
	const handleCompanyChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value: activeCompanyId } = e.target;
		setActiveCompany(activeCompanyId);
		navigate(AppRoutes.getCounterpartiesRoute(activeCompanyId, OperationEntrySide.CREDITOR));
	}, []);


	return (
		<header>
			<Navbar className="bg-body-tertiary justify-content-between p-2">
				<Navbar.Brand href="#home">
					<img
						src="https://optim.tildacdn.one/tild3061-6235-4362-a662-663231306665/-/resize/200x/-/format/webp/Logo.png"
						width="100"
						height="40"
						className="d-inline-block align-top"
						alt="React Bootstrap logo"
					/>
				</Navbar.Brand>

				<Form className="d-flex me-auto">
					<Form.Select aria-label="Company selector"
											 style={{ minWidth: '300px' }}
											 className={"me-3"}
											 value={activeCompany.companyId}
											 onChange={handleCompanyChange}
					>
						{userCompanies.map((item) => (
							<option key={item.companyId} value={item.companyId}>
								{item.companyName}
							</option>
						))}
					</Form.Select>
					<Form.Control type="date"
												value={startDate.toISOString().split('T')[0]}
												className="me-3"
												onChange={(e) => useUserStore.setState({ startDate: new Date(e.target.value) })}
					/>
					<Form.Control type="date"
												placeholder="Select date"
												value={endDate.toISOString().split('T')[0]}
												className="me-3"
												onChange={(e) => useUserStore.setState({ endDate: new Date(e.target.value) })}
					/>
					<Form.Select aria-label="Year selector" className={"me-1"}
											 onChange={(e) => useUserStore.getState().setCompanyAccountingPeriod(parseInt(e.target.value)) }>
						{activeCompany.accountingPeriods.map((item, index) => (
							<option key={index} value={item}>{item}</option>
						))}
					</Form.Select>
				</Form>

				<Nav className="ms-auto">
					<Nav.Link>
						<Form.Select aria-label="Lang selector" className={"me-1"}
												 value={language}
												 onChange={(e) => useUserStore.setState({userLanguage: e.target.value as Language})}>
							{Object.values(Language).map((item, index) => (
								<option key={index} value={item}>
									{item}
								</option>
							))}
						</Form.Select>
					</Nav.Link>
					<Nav.Link onClick={handleSignOut}>
						<FaSignOutAlt size={19} color={'#0d6efd'} />
					</Nav.Link>
				</Nav>
			</Navbar>
		</header>
	);
}

export default AppHeader;
