import {Accordion, Card, Form} from "react-bootstrap";
import {AiOutlineFilter} from "react-icons/ai";
import {Typeahead} from "react-bootstrap-typeahead";
import {FC} from "react";
import {CounterpartyOperationsTotalResponseDto} from "../../../app/api/counterparty-rest.api";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface CounterpartyFilterProps {
	counterparties: CounterpartyOperationsTotalResponseDto[];
	handleCounterpartyFilter: (counterpartyId: string | undefined) => void;
}

const CounterpartyFilter: FC<CounterpartyFilterProps> = ({ counterparties, handleCounterpartyFilter }) => {

	const counterpartiesOptions = counterparties.map(counterparties => {
		return {
			id: counterparties.id,
			label: counterparties.name
		}
	});

	const handleSelectorChange = (selected: any[]) => {
		handleCounterpartyFilter(selected[0]?.id);
	}

	const lang = useUserStore(state => state.userLanguage);

	return (
		<>
			<Accordion>
				<Accordion.Item eventKey={'0'}>
					<Accordion.Header style={{color: '#ECECEC'}}>
						<AiOutlineFilter style={{marginRight: '5px'}}/> {dict.common.filter[lang]}
					</Accordion.Header>
					<Accordion.Body>
						<Card.Body>
							<Form>
								<Form.Group controlId="credit" className="mb-1">
									<Form.Label>{dict.counterpartyPage.name[lang]}</Form.Label>
									<Typeahead options={counterpartiesOptions}
														 id={'counterpartySelector'}
														 clearButton={true}
														 onChange={handleSelectorChange}
									/>
								</Form.Group>
							</Form>
						</Card.Body>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

export default CounterpartyFilter;
