import {Container} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import {useUserStore} from "../../app/store/user.store";
import {useBalanceReportStore} from "../../app/store/balance-report.store";
import React, {useEffect} from "react";
import BalanceReportTable from "./widgets/BalanceReportTable";
import {AppLoader} from "../../components/AppLoader";
import {displayDate} from "../../app/utils";

const ProfitLostReportPage = () => {
	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const lang = useUserStore(state => state.userLanguage);

	const isFetching = useBalanceReportStore(state => state.isFetching);
	const profitLostReportItems = useBalanceReportStore(state => state.profitLostReportItems);

	useEffect(() => {
		if (activeCompany.companyId) {
			useBalanceReportStore.getState().fetchProfitLostReport(activeCompany.companyId, startDate, endDate, lang);
		}
	}, [activeCompany.companyId, startDate, endDate, lang]);

	return (
		<>
			<Container>
				<AppLoader showLoader={isFetching} />

				<section className={"mt-3"}>
					<PageTitle title={`Profit-Lost Report ${activeCompany.companyName}: (${displayDate(startDate)} - ${displayDate(endDate)})`}
										 showPrintButton={true}
					/>
				</section>

				<section>
					<BalanceReportTable
						activeCompany={activeCompany}
						startDate={startDate}
						endDate={endDate}
						reportData={profitLostReportItems} />
				</section>

			</Container>
		</>
	);
}

export default ProfitLostReportPage;
