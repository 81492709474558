import {BaseRestApi} from "../BaseRestApi";
import {AxiosError, AxiosResponse} from "axios";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import {toast} from "react-toastify";
import {JournalEntryResponseDto} from "./journal-entry.api";

export class VatReportApi extends BaseRestApi {

	private static instance: VatReportApi;

	public static getInstance(): VatReportApi {
		if (!VatReportApi.instance) {
			VatReportApi.instance = new VatReportApi();
		}
		return VatReportApi.instance;
	}

	constructor() {
		super();
	}

	/**
	 * VAT report for pay
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 */
	public async getVATPayed(
		companyId: string,
		startDate: Date,
		endDate: Date
	): Promise<JournalEntryResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/vat-report/v1/payed/${companyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<JournalEntryResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * VATs for pay
	 * @param companyId
	 * @param startDate
	 * @param endDate
	 */
	public async getVATForPay(
		companyId: string,
		startDate: Date,
		endDate: Date
	): Promise<JournalEntryResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/vat-report/v1/for-pay/${companyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<JournalEntryResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}
}
