import {
	OperationApi,
	OperationCommentResponseDto,
	OperationResponseDto
} from "../api/operation.api";
import {create} from "zustand";
import {immer} from "zustand/middleware/immer";


export interface OperationCommentStore {
	isFetching: boolean;
	isProcessing: boolean;
	showCommentsModal: boolean;
	selectedOperation: OperationResponseDto | null;
	operationComments: OperationCommentResponseDto[];

	addOperationComment: (operationId: string, commentBody: string) => void;
	fetchOperationComments: (operationId: string) => void;
}

export const useOperationCommentStore = create<OperationCommentStore>()(immer((set) => ({
	isFetching: false,
	isProcessing: false,
	showCommentsModal: false,
	operationComments: [],
	selectedOperation: null,

	/**
	 * Add operation comment
	 * @param operationId
	 * @param commentBody
	 */
	addOperationComment: async (operationId: string, commentBody: string) => {
		set({ isProcessing: true })
		const comment = await OperationApi.getInstance().addComment({ operationId, commentBody});
		set({ isProcessing: false })
		if (comment) {
			set(state => {
				state.operationComments.unshift(comment);
			});
		}
	},

	/**
	 * Fetch operation comments
	 * @param operationId
	 */
	fetchOperationComments: async (operationId: string) => {
		set({ isFetching: true });
		const comments = await OperationApi.getInstance().getComments(operationId);
		set({ isFetching: false, operationComments: comments });
	},
})));
