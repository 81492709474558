import {AxiosError, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {Currency, JournalEntryType} from "../type";
import {AccountNumberResponseDto, VatResponseDto} from "./account-plan.api";
import {InternalUserResponseDto, UserCompany} from "./login.api";
import {BaseRestApi} from "../BaseRestApi";
import {BaseHttpServerResponse} from "./HttpServerResponse";

	export interface UploadJournalEntryRequestDto {
	id: string;
	companyId: string;
	entryType: JournalEntryType;
	entryDate: string;
	invoiceNumber: string;
	entryDescription: string;
	dr: number;
	cr: number;
	currency: Currency;
	entryAmount: number;
	companyEntryAmount: number;
	conversionRate: number;
	vatCode: string | null;
	vatAmount: number;
	vatCompanyAmount: number | null;
	vatNumber: number | null;
}

export interface UploadResultResponseDto {
	total: number;
	inserted: number;
	updated: number;
	failed: number;
}

export interface JournalEntryResponseDto {
	id: string;
	companyId: string;
	entryDate: Date;
	entryType: JournalEntryType;
	invoiceNumber: string;
	entryDescription: string;
	debit: AccountNumberResponseDto | null;
	credit: AccountNumberResponseDto | null;
	currency: Currency;
	entryAmount: number;
	vatEntryAmount: number;
	companyAmount: number;
	vatCompanyAmount: number;
	conversionRate: number | null;
	attachedFileUrl: string,
	version: number;
	createdAt: Date;
	createdBy: InternalUserResponseDto;
	updatedAt?: Date;
	updatedBy?: InternalUserResponseDto;
	vat: VatResponseDto | null;
	vatAccount: AccountNumberResponseDto;
}

export interface JournalEntryRequestDto {
	[key: string]: any;
	id: string;
	entryDate: Date;
	invoiceNumber: string;
	entryDescription: string;
	debitAccountId: string | undefined | null;
	creditAccountId: string | undefined | null;
	entryAmount: number;
	vatEntryAmount: number;
	currency: Currency;
	conversionRate: number | null;
	attachedFileUrl: string | null;
	version: number | null;
	journalEntryType: JournalEntryType;
	parentEntryId?: string | null;
	vatCode: string | null;
	vatAccountId: string | undefined | null;
}

export const buildDefaultJournalEntryRequest = (): JournalEntryRequestDto => {
	return {
		id: '',
		entryDate: new Date(),
		invoiceNumber: '',
		entryDescription: '',
		debitAccountId: null,
		creditAccountId: null,
		entryAmount: 0.00,
		vatEntryAmount: 0.00,
		currency: Currency.CHF,
		journalEntryType: JournalEntryType.JE,
		vatCode: null,
		vatAccountId: null,
		conversionRate: null,
		version: null,
		attachedFileUrl: null
	};
}

// Comments
export interface JournalEntryCommentResponseDto {
	id: string;
	journalEntryId: string;
	comment: string;
	author: InternalUserResponseDto;
	createAt: Date;
}

export interface JournalEntryCommentRequestDto {
	journalEntryId: string;
	comment: string;
}


export class JournalEntryApi extends BaseRestApi {

	private static instance: JournalEntryApi;

	public static getInstance(): JournalEntryApi {
		if (!JournalEntryApi.instance) {
			JournalEntryApi.instance = new JournalEntryApi();
		}
		return JournalEntryApi.instance;
	}

	constructor() {
		super();
	}

	public async getJournalEntries(companyId: string, startDate: Date, endDate: Date): Promise<JournalEntryResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/list/${companyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<JournalEntryResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	public async createJournalEntry(companyId: string, journalEntry: JournalEntryRequestDto): Promise<JournalEntryResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/create/${companyId}`;
			const response = await this.axiosInstance.post(url, journalEntry);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	public async updateJournalEntry(companyId: string, journalEntry: JournalEntryRequestDto): Promise<JournalEntryResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/update/${companyId}/${journalEntry.id}`;
			const response = await this.axiosInstance.put(url, journalEntry);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	public async removeJournalEntry(companyId: string, journalEntryId: string): Promise<boolean> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/remove/${companyId}/${journalEntryId}`;
			await this.axiosInstance.delete(url);
			return true
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return false;
		}
	}

	// Comments
	public async getJournalEntryComments(journalEntryId: string): Promise<JournalEntryCommentResponseDto[]> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/comments/${journalEntryId}`;
			const response: AxiosResponse<JournalEntryCommentResponseDto[]> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return [];
		}
	}

	/**
	 * Add journal entry comment
	 * @param journalEntryCommentRequest
	 */
	public async addJournalEntryComment(journalEntryCommentRequest: JournalEntryCommentRequestDto): Promise<JournalEntryCommentResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/add-comment`;
			const response: AxiosResponse<JournalEntryCommentResponseDto> = await this.axiosInstance.post(url, journalEntryCommentRequest);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Find journal entry by id
	 * @param journalEntryId
	 */
	public async findJournalEntryById(journalEntryId: string): Promise<JournalEntryResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/single/${journalEntryId}`;
			const response: AxiosResponse<JournalEntryResponseDto> = await this.axiosInstance.get(url);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

	/**
	 * Upload journal entry file
	 * @param company
	 * @param startDate
	 * @param endDate
	 */
	public async exportJournalEntries(company: UserCompany, startDate: Date, endDate: Date): Promise<void> {
		try {
			const startDateStr = startDate.toISOString().split('T').shift();
			const endDateStr = endDate.toISOString().split('T').shift();

			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/export/${company.companyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			const response: AxiosResponse<Blob> = await this.axiosInstance.get(url, {responseType: 'blob'});
			const urlFile = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = urlFile;
			link.setAttribute('download', `${company.companyName}-journal-entries-${startDateStr}-${endDateStr}.csv`);

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
		}
	}

	/**
	 * Upload journal entry file
	 * @param companyId
	 * @param fileJournalEntries
	 */
	public async uploadJournalEntriesFromFile(
		companyId: string,
		fileJournalEntries: UploadJournalEntryRequestDto[]
	): Promise<UploadResultResponseDto | null> {
		try {
			const url = `${process.env.REACT_APP_API_HOST}/journal-entries/v1/upload/${companyId}`;
			const response: AxiosResponse<UploadResultResponseDto> = await this.axiosInstance.post(url, fileJournalEntries);
			return response.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;
			toast.error(errorResponse!.operationResult);
			return null;
		}
	}

}
