import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Dropdown} from "react-bootstrap";
import {AiOutlineAccountBook, AiOutlineDollarCircle, AiOutlineDownload, AiOutlineGroup,} from "react-icons/ai";
import {AppRoutes} from "../App";
import {useUserStore} from "../app/store/user.store";
import {AccountPlanCSVUploader} from "../page/accounts-plan/widget/AccountPlanCSVUploader";
import {AppLoader} from "./AppLoader";
import React from "react";
import {useAccountNumberStore} from "../app/store/account-number.store";
import {OperationEntrySide} from "../app/type";
import {dict} from "../app/dictionary";

const AppTabsNavigation = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isProcessing = useAccountNumberStore(state => state.isProcessing);
	const activeCompany = useUserStore(state => state.activeCompany);
	const lang = useUserStore(state => state.userLanguage);

	const isAccountsPlanRoute = [AppRoutes.ACCOUNT_SECTION_PAGE, AppRoutes.ACCOUNT_GROUP_PAGE, AppRoutes.ACCOUNT_PLAN_PAGE].includes(location.pathname);

	const isCreditorsRoute = [
		`/${activeCompany.companyId}/counterparties/${OperationEntrySide.CREDITOR.toLowerCase()}`,
		`/${activeCompany.companyId}/operations/${OperationEntrySide.CREDITOR.toLowerCase()}`
	].some(route => location.pathname.startsWith(route));

	const isDebtorsRoute = [
		`/${activeCompany.companyId}/counterparties/${OperationEntrySide.DEBTOR.toLowerCase()}`,
		`/${activeCompany.companyId}/operations/${OperationEntrySide.DEBTOR.toLowerCase()}`
	].some(route => location.pathname.startsWith(route));

	const isBalanceRoute = [AppRoutes.BALANCE_PAGE, AppRoutes.PROFIT_LOST_REPORT_PAGE, AppRoutes.ANNUAL_REPORT_PAGE].includes(location.pathname);
	const isVatRoute = [AppRoutes.VAT_PAYED_PAGE, AppRoutes.VAT_FOR_PAY_PAGE].includes(location.pathname);

	const handleNavItemClick = (route: string) => {
		navigate(route);
	}

	return (
		<section className={"mt-2"}>

			<AppLoader showLoader={isProcessing} />

			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="app-tabs-navigation">
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<Link
										style={{fontSize: '0.9em'}}
										className={`nav-link ${isCreditorsRoute ? 'active' : ''}`}
										to={AppRoutes.getCounterpartiesRoute(activeCompany.companyId, OperationEntrySide.CREDITOR)}
										role="tab"
										aria-controls="tab-content-1"
										aria-selected={isCreditorsRoute}
									>
										{dict.appTabsNavigator.creditor[lang]}
									</Link>
								</li>

								<li className="nav-item">
									<Link
										style={{fontSize: '0.9em'}}
										className={`nav-link ${isDebtorsRoute ? 'active' : ''}`}
										to={AppRoutes.getCounterpartiesRoute(activeCompany.companyId, OperationEntrySide.DEBTOR)}
										role="tab"
										aria-controls="tab-content-1"
										aria-selected={isDebtorsRoute}
									>
										{dict.appTabsNavigator.debtor[lang]}
									</Link>
								</li>

								<li className="nav-item">
									<Link
										style={{fontSize: '0.9em'}}
										className={`nav-link ${location.pathname === AppRoutes.getJournalEntriesRoute(activeCompany.companyId) ? 'active' : ''}`}
										to={AppRoutes.getJournalEntriesRoute(activeCompany.companyId)}
										role="tab"
										aria-controls="tab-content-1"
										aria-selected={location.pathname === AppRoutes.getJournalEntriesRoute(activeCompany.companyId)}
									>
										{dict.appTabsNavigator.journalEntries[lang]}
									</Link>
								</li>

								<li className="nav-item">
									<Link
										style={{fontSize: '0.9em'}}
										className={`nav-link ${location.pathname === AppRoutes.getAccountMovementsRoute(activeCompany.companyId) ? 'active' : ''}`}
										to={AppRoutes.getAccountMovementsRoute(activeCompany.companyId)}
										role="tab"
										aria-controls="tab-content-1"
										aria-selected={location.pathname === AppRoutes.getAccountMovementsRoute(activeCompany.companyId)}
									>
										{dict.appTabsNavigator.accountMovements[lang]}
									</Link>
								</li>

								<li className="nav-item">
									<Dropdown>
										<Dropdown.Toggle
											style={{fontSize: '0.9em'}}
											className={`nav-link ${isVatRoute ? 'active' : ''}`}
											id="vat-dropdown"
											role="tab"
											aria-controls="vat-tab-content"
											aria-selected={isVatRoute}
										>
											{dict.appTabsNavigator.vat.title[lang]}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getVaTPayedRoute(activeCompany.companyId))}>
												{dict.appTabsNavigator.vat.payed[lang]}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getVatForPayRoute(activeCompany.companyId))}>
												{dict.appTabsNavigator.vat.payable[lang]}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</li>

								<li className="nav-item">
									<Dropdown>
										<Dropdown.Toggle
											style={{fontSize: '0.9em'}}
											className={`nav-link ${isBalanceRoute ? 'active' : ''}`}
											id="vat-dropdown"
											role="tab"
											aria-controls="vat-tab-content"
											aria-selected={isBalanceRoute}
										>
											{dict.appTabsNavigator.balance.title[lang]}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getBalanceRoute(activeCompany.companyId))}>
												{dict.appTabsNavigator.balance.assetLiabilityReport[lang]}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getProfitLostReportRoute(activeCompany.companyId))}>
												{dict.appTabsNavigator.balance.profitLostReport[lang]}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getAnnualReportRoute(activeCompany.companyId))}>
												{dict.appTabsNavigator.balance.annualReport[lang]}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</li>

								<li className="nav-item">
									<Dropdown>
										<Dropdown.Toggle
											style={{fontSize: '0.9em'}}
											className={`nav-link ${isAccountsPlanRoute ? 'active' : ''}`}
											id="vat-dropdown"
											role="tab"
											aria-controls="vat-tab-content"
											aria-selected={isAccountsPlanRoute}
										>
											{dict.appTabsNavigator.accountsPlan.title[lang]}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getSectionRoute(activeCompany.companyId))}>
												<AiOutlineAccountBook /> {dict.appTabsNavigator.accountsPlan.accountSections[lang]}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.getAccountGroupRoute(activeCompany.companyId))}>
												<AiOutlineGroup /> {dict.appTabsNavigator.accountsPlan.accountGroups[lang]}
											</Dropdown.Item>
											<Dropdown.Item onClick={() => handleNavItemClick(AppRoutes.geAccountPlanRoute(activeCompany.companyId))}>
												<AiOutlineDollarCircle /> {dict.appTabsNavigator.accountsPlan.accountNumbers[lang]}
											</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item>
												<AccountPlanCSVUploader />
											</Dropdown.Item>
											<Dropdown.Item onClick={() => useAccountNumberStore.getState().export2csv(activeCompany)}>
												<AiOutlineDownload />
												{dict.appTabsNavigator.accountsPlan.downloadAccountsPlan[lang]}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AppTabsNavigation;
