import {create} from 'zustand';
import {immer} from "zustand/middleware/immer";
import {LocalStorageKey} from "../const";
import {toast} from "react-toastify";

import {jwtDecode} from "jwt-decode";
import {Currency, Language, OperationEntrySide} from "../type";
import {AppRoutes} from "../../App";
import {AuthToken, LoginApi, LoginRequest, UserCompany} from "../api/login.api";
import {OperationResult} from "../api/HttpServerResponse";
import {NavigateFunction} from "react-router-dom";
import {AccountPlanApi} from "../api/account-plan.api";


interface UserStore {
	isLoading: boolean;
	isLoggedIn: boolean;
	userCompanies: UserCompany[];
	activeCompany: UserCompany;

	accountingPeriod: number;
	startDate: Date;
	endDate: Date;
	userLanguage: Language,

	init: () => void;
	sendLoginRequest: (loginRequest: LoginRequest, navigate: NavigateFunction) => void;
	logout: () => void;
	setActiveCompany: (companyId: string) => void;
	setCompanyAccountingPeriod: (accountingPeriod: number) => void;
	updateAccountingPeriod: (date: Date) => void;
}

export const useUserStore = create<UserStore>()(immer((set) => ({
	isLoading: false,
	isLoggedIn: false,
	userCompanies: [],
	userLanguage: Language.en,

	accountingPeriod: new Date().getFullYear(),
	startDate: new Date(`${new Date().getFullYear()}-01-01`),
	endDate: new Date(`${new Date().getFullYear()}-12-31`),

	activeCompany: {
		companyId: "",
		companyName: "",
		companyCurrency: Currency.CHF,
		accountingPeriods: [new Date().getFullYear()],
		accountsPlan: [],
		vats: []
	},

	init: () => set((state) => {
		const authToken = localStorage.getItem(LocalStorageKey.AUTH_TOKEN_KEY);
		if (authToken) {
			try {
				const decodedToken: AuthToken = jwtDecode(authToken) as AuthToken;
				state.userCompanies = decodedToken.companies;
				state.isLoggedIn = true;
				state.activeCompany = decodedToken?.companies[0];
				return;
			} catch (e) {
				toast.error("Invalid token");
			}
		}

		state.isLoggedIn = false;
		localStorage.removeItem(LocalStorageKey.AUTH_TOKEN_KEY);
		window.location.href = AppRoutes.LOGIN_ROUTE;
	}),

	/**
	 * Send the login request to the server
	 * @param loginRequest
	 * @param navigate
	 */
	sendLoginRequest: async (loginRequest: LoginRequest, navigate: NavigateFunction) => {
		set({ isLoading: true });
		const loginResponse = await LoginApi.login(loginRequest);
		set({ isLoading: false });

		if (loginResponse.operationResult !== OperationResult.OK) {
			return;
		}

		try {
			// Try to decode the token and get the user data
			const decodedToken: AuthToken = jwtDecode(loginResponse.authToken) as AuthToken;
			localStorage.setItem(LocalStorageKey.AUTH_TOKEN_KEY, loginResponse.authToken);

			const activeCompany = decodedToken?.companies[0];

			// Fetch account plan data for the active company
			const [accountsPlan, vats] = await Promise.all([
				AccountPlanApi.getInstance().getAccountNumbers(activeCompany?.companyId),
				AccountPlanApi.getInstance().getVats(activeCompany?.companyId)
			]);
			activeCompany.accountsPlan = accountsPlan;
			activeCompany.vats = vats;

			set({ userCompanies: decodedToken.companies, isLoggedIn: true });
			set({ activeCompany });

			navigate(AppRoutes.getCounterpartiesRoute(activeCompany!.companyId, OperationEntrySide.CREDITOR));
		} catch (e) {
			toast.error("Invalid token");
		}
	},

	/**
	 * Logout the user
	 */
	logout: () => set((state) => {
		state.isLoading = true;
		state.isLoggedIn = false;
		state.userCompanies = [];
		localStorage.removeItem(LocalStorageKey.AUTH_TOKEN_KEY);
		window.location.href = AppRoutes.LOGIN_ROUTE;
	}),

	/**
	 * Set the active company
	 * @param companyId
	 */
	setActiveCompany: async (companyId: string) => {
		let activeCompany = useUserStore.getState().userCompanies.find(company => company.companyId === companyId);
		if (!activeCompany) {
			toast.error("Ups... Looks like you dont have permission to access this company.");
			return;
		}

		// Fetch account plan data for the active company
		const [accountsPlan, vats] = await Promise.all([
			AccountPlanApi.getInstance().getAccountNumbers(activeCompany?.companyId),
			AccountPlanApi.getInstance().getVats(activeCompany?.companyId)
		]);

		// Create a new object with all properties of activeCompany and the new accountsPlan property
		const newActiveCompany = { ...activeCompany, accountsPlan, vats };
		set({ activeCompany: newActiveCompany });
	},

	/*
	 * Update company accounting period
	 */
	setCompanyAccountingPeriod: (accountingPeriod: number) => set((state) => {
		state.startDate = new Date(`${accountingPeriod}-01-01`);
		state.endDate = new Date(`${accountingPeriod}-12-31`);
	}),

	/**
	 * Update company accounting period if it does not exist
	 * @param date
	 */
	updateAccountingPeriod: (date: Date | string) => set((state) => {
		if (typeof date === "string") {
			date = new Date(date);
		}

		// Update active company accounting period
		if (state.activeCompany.accountingPeriods.includes(date.getFullYear())) {
			return;
		}

		state.activeCompany.accountingPeriods.push(date.getFullYear());
		state.activeCompany.accountingPeriods.sort((a, b) => b - a);

		state.userCompanies = state.userCompanies.map(company => {
			if (company.companyId === state.activeCompany.companyId) {
				company.accountingPeriods = state.activeCompany.accountingPeriods;
			}
			return company;
		})
	})

})));
