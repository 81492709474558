import React from "react";
import {Button, Table} from "react-bootstrap";
import {AccountMovementResponseDto, AccountMovementTotalResponseDto} from "../../../app/api/account-movements.api";
import {displayAmount, displayDate} from "../../../app/utils";
import {useAccountMovementsStore} from "../../../app/store/account-movements.store";
import FileButton from "../../../components/FileButton";
import {UserCompany} from "../../../app/api/login.api";
import LabelTooltip from "../../../components/LabelTooltip";
import {BalanceTotalResponseDto} from "../../../app/api/balance-report.api";
import {useUserStore} from "../../../app/store/user.store";
import {dict} from "../../../app/dictionary";

interface AccountMovementsTableProps {
	startDate: Date,
	endDate: Date,
	company: UserCompany,
	accountMovement: AccountMovementResponseDto
}

const AccountMovementsTable: React.FC<AccountMovementsTableProps> = (
	{ startDate, endDate, company, accountMovement }) => {

	const handleJournalEntryDetails = (id: string) => {
		useAccountMovementsStore.setState({
			selectedJournalEntryId: id
		});
	}

	const lang = useUserStore(state => state.userLanguage);

	const displayTotals = (totals: AccountMovementTotalResponseDto[], item: 'dr' | 'cr' | 'variance') => {
		return totals.map((total: BalanceTotalResponseDto) => (
			<>
				{total.currency === company.companyCurrency ? (
					<span>
						{item === "dr" ? displayAmount(total.drTotal) :
							(item === "cr" ? displayAmount(total.crTotal) :
								displayAmount(total.variance))}
					</span>
				) : (
					<span>
						{item === "dr" ? displayAmount(total.drTotal, total.currency) :
							(item === "cr" ? displayAmount(total.crTotal, total.currency) :
								displayAmount(total.variance, total.currency))}
					</span>
				)}
				<br/>
			</>
		));
	}

	return (
		<>
			<Table striped bordered hover className={"mt-5"}>
				<thead>
				<tr>
					<th colSpan={10} style={{fontSize: '1.2rem'}}>
						{accountMovement.accountNumber.accountNumber + ' - ' + accountMovement.accountNumber.title.en}
					</th>
				</tr>
				<tr>
					<th>{dict.common.date[lang]}</th>
					<th>{dict.accountMovementsPage.table.accountNumber[lang]}</th>
					<th>{dict.accountMovementsPage.table.invoiceAndDescription[lang]}</th>
					<th style={{ textAlign: 'right' }}>{dict.accountMovementsPage.table.drAmount[lang]}</th>
					<th style={{ textAlign: 'right' }}>{dict.accountMovementsPage.table.crAmount[lang]}</th>
					<th style={{ textAlign: 'right' }}>{dict.common.balance[lang]}</th>
					<th style={{ textAlign: 'right' }}>{dict.common.document[lang]}</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td colSpan={3}>
						<b>Balance on {displayDate(startDate)}</b>
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.prevPeriodTotals, 'dr')}
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.prevPeriodTotals, 'cr')}
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.prevPeriodTotals, 'variance')}
					</td>
				</tr>

				{accountMovement?.journalEntries?.map((je) => {
					return (
						<tr key={je.id}>
							<td>{displayDate(je.entryDate)}</td>
							<td>
								<LabelTooltip id={je.counterAccount.id} title={je.counterAccount.title.en}>
									#{je.counterAccount.accountNumber}
								</LabelTooltip>
							</td>
							<td>
								<Button variant="link" size={'sm'} onClick={() => handleJournalEntryDetails(je.id)}>
									{je.invoiceNumber ? je.invoiceNumber + '/' + je.entryDescription : je.entryDescription}
								</Button>
							</td>
							<td style={{ textAlign: 'right' }}>
								<>
									{je.entryCurrency !== company.companyCurrency && <span style={{ fontSize: 14 }}>{displayAmount(je.drAmount, je.entryCurrency)} <br/></span>}
									{displayAmount(je.drCompanyAmount)}
								</>
							</td>
							<td style={{ textAlign: 'right' }}>
								<>
									{je.entryCurrency !== company.companyCurrency && <span style={{ fontSize: 14 }}>{displayAmount(je.crAmount, je.entryCurrency)} <br/></span>}
									{displayAmount(je.crCompanyAmount)}
								</>
							</td>
							<td style={{ textAlign: 'right' }}>
								{je.entryCurrency !== company.companyCurrency && <span style={{ fontSize: 14 }}>{displayAmount(je.drAmount - je.crAmount, je.entryCurrency)} <br/></span>}
								{displayAmount(je.drCompanyAmount - je.crCompanyAmount)}
							</td>
							<td style={{ textAlign: 'right' }}>
								<FileButton fileUrl={je.attachedFileUrl}/>
							</td>
						</tr>
					)
				})}
				<tr className={'last-row'}>
					<td colSpan={3}>
						<b>Balance on {displayDate(endDate)}</b>
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.currentPeriodTotals, 'dr')}
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.currentPeriodTotals, 'cr')}
					</td>
					<td style={{ textAlign: 'right' }}>
						{displayTotals(accountMovement.currentPeriodTotals, 'variance')}
					</td>
				</tr>
				</tbody>
			</Table>
		</>
	)
}

export default AccountMovementsTable;
