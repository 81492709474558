import {Container, Table} from "react-bootstrap";
import {useUserStore} from "../../app/store/user.store";
import {useBalanceReportStore} from "../../app/store/balance-report.store";
import React, {useEffect} from "react";
import {AppLoader} from "../../components/AppLoader";
import {displayAmount, displayDate, displayYear, subtractOneYear} from "../../app/utils";

const AnnualReportPage = () => {
	const activeCompany = useUserStore(state => state.activeCompany);
	const startDate = useUserStore(state => state.startDate);
	const endDate = useUserStore(state => state.endDate);
	const lang = useUserStore(state => state.userLanguage);

	const isFetching = useBalanceReportStore(state => state.isFetching);
	const annualReportItems = useBalanceReportStore(state => state.annualReportItems);

	useEffect(() => {
		if (activeCompany.companyId) {
			useBalanceReportStore.getState().fetchAnnualReport(activeCompany.companyId, startDate, endDate, lang);
		}
	}, [activeCompany.companyId, startDate, endDate, lang]);

	return (
		<>
			<Container>
				<AppLoader showLoader={isFetching} />

				<section>
					<div className={"text-center"} style={{ marginTop: '30px', marginBottom: '20px' }}>
						<h4>Jahresabschluss</h4>
						<h5>Bilanz per {displayDate(endDate)}</h5>
						<h5>Erfolgsrechnung {displayDate(startDate)} bis {displayDate(endDate)}</h5>
					</div>

					<hr style={{width: '100%'}}/>

					<h5 className={"text-center mt-10"}>Balance {activeCompany.companyName} на {displayDate(endDate)}</h5>

					<Table className={"mt-3"}>
						<thead>
						<tr>
							<th></th>
							<th>Account#</th>
							<th>{displayYear(startDate)}</th>
							<th>{displayYear(subtractOneYear(startDate))}</th>
						</tr>
						</thead>
						<tbody>
						{annualReportItems.map((classItem) => (
							<>
								<tr>
									<td></td>
									<td>{classItem.accountClass}</td>
									<td></td>
									<td></td>
								</tr>
								{classItem.groups.map((group) => (
									<tr>
										<td>{group.groupNumber}</td>
										<td>{group.groupTitle}</td>
										<td>{displayAmount(group.currentPeriodVariance)}</td>
										<td>{displayAmount(group.previousPeriodVariance)}</td>
									</tr>
								))}
								<tr>
									<td><b></b></td>
									<td><b>Total: {classItem.accountClass}</b></td>
									<td><b>{displayAmount(classItem.currentPeriodVariance)}</b></td>
									<td><b>{displayAmount(classItem.previousPeriodVariance)}</b></td>
								</tr>
							</>
						))}
						</tbody>
					</Table>

				</section>

			</Container>
		</>
	);
}

export default AnnualReportPage;
