import {JournalEntryResponseDto} from "../api/journal-entry.api";
import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {VatReportApi} from "../api/vat-report.api";

export interface VatReportStore {
	isFetching: boolean;
	journalEntries: JournalEntryResponseDto[];

	fetchVATPayed: (companyId: string, startDate: Date, endDate: Date) => void;
	fetchVATForPayed: (companyId: string, startDate: Date, endDate: Date) => void;
}


export const useVatReportStore = create<VatReportStore>()(immer((set) => ({
	isFetching: false,
	journalEntries: [],

	fetchVATPayed: async (companyId: string, startDate: Date, endDate: Date) => {
		set({ isFetching: true });
		const journalEntries = await VatReportApi.getInstance().getVATPayed(companyId, startDate, endDate);
		set({ journalEntries, isFetching: false });
	},

	fetchVATForPayed: async (companyId: string, startDate: Date, endDate: Date) => {
		set({ isFetching: true });
		const journalEntries = await VatReportApi.getInstance().getVATForPay(companyId, startDate, endDate);
		set({ journalEntries, isFetching: false });
	},
})));

