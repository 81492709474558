import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./page/LoginPage";
import ProfitLostReportPage from "./page/balance-report/ProfitLostReportPage";
import AnnualReportPage from "./page/balance-report/AnnualReportPage";
import JournalEntriesPage from "./page/journal-entries/JournalEntriesPage";
import AccountMovementsPage from "./page/account-movements/AccountMovementsPage";
import AssetLiabilityReportPage from "./page/balance-report/AssetLiabilityReportPage";
import VATPayedPage from "./page/vat-report/VATPayedPage";
import AccountNumberPage from "./page/accounts-plan/AccountNumberPage";
import AccountGroupPage from "./page/accounts-plan/AccountGroupPage";
import AccountSectionPage from "./page/accounts-plan/AccountSectionPage";
import VATForPayPage from "./page/vat-report/VATForPayPage";
import BasePage from "./page/BasePage";
import OperationsPage from "./page/operations/OperationsPage";
import {OperationEntrySide} from "./app/type";
import CounterpartyPage from "./page/counterparty/CounterpartyPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path={AppRoutes.LOGIN_ROUTE} element={<LoginPage />} />

        <Route path={AppRoutes.COUNTERPARTY_PAGE} element={
          <BasePage>
            <CounterpartyPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.OPERATIONS_PAGE} element={
          <BasePage>
            <OperationsPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.SINGLE_OPERATION_PAGE} element={
          <BasePage>
            <OperationsPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.BALANCE_PAGE} element={
          <BasePage>
            <AssetLiabilityReportPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.PROFIT_LOST_REPORT_PAGE} element={
          <BasePage>
            <ProfitLostReportPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.ANNUAL_REPORT_PAGE} element={
          <BasePage>
            <AnnualReportPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.JOURNAL_ENTRIES_PAGE} element={
          <BasePage>
            <JournalEntriesPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.SINGLE_JOURNAL_ENTRY_PAGE} element={
          <BasePage>
            <JournalEntriesPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.ACCOUNT_MOVEMENTS_PAGE} element={
          <BasePage>
            <AccountMovementsPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.VAT_PAYED_PAGE} element={
          <BasePage>
            <VATPayedPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.VAT_FOR_PAY_PAGE} element={
          <BasePage>
            <VATForPayPage />
          </BasePage>
        }/>

        <Route path={AppRoutes.ACCOUNT_SECTION_PAGE} element={
          <BasePage>
            <AccountSectionPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.ACCOUNT_GROUP_PAGE} element={
          <BasePage>
            <AccountGroupPage />
          </BasePage>
        }/>
        <Route path={AppRoutes.ACCOUNT_PLAN_PAGE} element={
          <BasePage>
            <AccountNumberPage />
          </BasePage>
        }/>
      </Routes>
    </BrowserRouter>
  );
}


export const AppRoutes = {
  LOGIN_ROUTE: '/login',

  COUNTERPARTY_PAGE: '/:companyId/:operationEntrySide',
  getCounterpartiesRoute: (companyId: string, operationEntrySide: OperationEntrySide) => `/${companyId}/${operationEntrySide.toLowerCase()}`,

  SINGLE_OPERATION_PAGE: `/:companyId/operations/:operationEntrySide/:counterpartyId/:operationId`,
  getSingleOperationRoute: (companyId: string, operationEntrySide: OperationEntrySide, counterpartyId: string, operationId: string) =>
    `/${companyId}/operations/${operationEntrySide.toLowerCase()}/${counterpartyId}/${operationId}`,

  OPERATIONS_PAGE: '/:companyId/operations/:operationEntrySide/:counterpartyId',
  getOperationsRoute: (companyId: string, operationEntrySide: OperationEntrySide, counterpartyId: string) =>
    `/${companyId}/operations/${operationEntrySide.toLowerCase()}/${counterpartyId}`,

  BALANCE_PAGE: '/:companyId/balance',
  getBalanceRoute: (companyId: string) => `/${companyId}/balance`,

  PROFIT_LOST_REPORT_PAGE: '/:companyId/profit-lost-report',
  getProfitLostReportRoute: (companyId: string) => `/${companyId}/profit-lost-report`,

  ANNUAL_REPORT_PAGE: '/:companyId/annual-report',
  getAnnualReportRoute: (companyId: string) => `/${companyId}/annual-report`,

  SINGLE_JOURNAL_ENTRY_PAGE: '/:companyId/journal-entries/:jeId',
  getSingleJournalEntryRoute: (companyId: string, jeId: string) => `/${companyId}/journal-entries/${jeId}`,

  JOURNAL_ENTRIES_PAGE: '/:companyId/journal-entries',
  getJournalEntriesRoute: (companyId: string) => `/${companyId}/journal-entries`,

  ACCOUNT_MOVEMENTS_PAGE: '/:companyId/account-movements',
  getAccountMovementsRoute: (companyId: string) => `/${companyId}/account-movements`,

  VAT_PAYED_PAGE: '/:companyId/vat-payed',
  getVaTPayedRoute: (companyId: string) => `/${companyId}/vat-payed`,

  VAT_FOR_PAY_PAGE: '/:companyId/vat-for-pay',
  getVatForPayRoute: (companyId: string) => `/${companyId}/vat-for-pay`,

  ACCOUNT_SECTION_PAGE: '/:companyId/account-section',
  getSectionRoute: (companyId: string) => `/${companyId}/account-section`,

  ACCOUNT_GROUP_PAGE: '/:companyId/account-group',
  getAccountGroupRoute: (companyId: string) => `/${companyId}/account-group`,

  ACCOUNT_PLAN_PAGE: '/:companyId/account-plan',
  geAccountPlanRoute: (companyId: string) => `/${companyId}/account-plan`,
}

export default App;
