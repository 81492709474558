
export const LocalStorageKey = {
	AUTH_TOKEN_KEY: "v1_auth"
}


export enum AccountPlanTitle {
	SECTION = 'Section',
	GROUP = 'Group',
	ACCOUNT_NUMBER = 'Account Number',
	ACCOUNT_CLASS = 'Account Class',
	TITLE_EN = 'Title (en)',
	TITLE_DE = 'Title (de)',
	TITLE_IT = 'Title (it)',
	TITLE_FR = 'Title (fr)',
	TITLE_RU = 'Title (ru)',
	TITLE_UA = 'Title (ua)',
	CURRENCY = 'Currency',
	IS_VAT = 'Is VAT'
}