import axios, {AxiosError, AxiosResponse} from "axios";
import {BaseHttpServerResponse} from "./HttpServerResponse";
import { toast } from "react-toastify";
import {Currency} from "../type";
import {AccountNumberResponseDto, VatResponseDto} from "./account-plan.api";

export interface UserCompany {
	companyId: string,
	companyName: string
	companyCurrency: Currency,
	accountingPeriods: number[],

	accountsPlan: AccountNumberResponseDto[],
	vats: VatResponseDto[],
}

export interface AuthToken {
	userId: string;
	role: string,
	isEnable: boolean,
	userEmail: string,
	fullName: string,
	companies: UserCompany[]
}

export interface InternalUserResponseDto {
	id: string;
	email: string;
	fullName: string;
}

export interface LoginResponse extends BaseHttpServerResponse {
	authToken: string;
}

export interface LoginRequest {
	email: string;
	password: string;
}


export class LoginApi {
	public static async login(loginRequest: LoginRequest): Promise<LoginResponse> {
		try {
			const url: string = `${process.env.REACT_APP_API_HOST}/user/v1/login`;
			const axiosResponse: AxiosResponse<LoginResponse> = await axios.post<LoginResponse>(url, loginRequest);

			return axiosResponse.data;
		} catch (e) {
			const error = e as AxiosError<BaseHttpServerResponse>;
			const errorResponse = error?.response?.data;

			toast.error(errorResponse!.operationResult);

			return {
				authToken: "",
				operationResult: errorResponse!.operationResult,
				message: errorResponse!.message
			};
		}
	}
}
